export default {
  de: {
    name: 'Name',
    active: 'Aktiv',
    hex: 'HEX-Wert',
    cmyk: 'CMYK-Wert',
    editor: ' zuletzt bearbeitet von',
    back: 'Zurück',
    preview: 'Farbvorschau',
    destroy: ' Farbe löschen',
    alerts: {
      'colors.patch': {
        200: () => 'Änderungen gespeichert!',
      },
      'colors.destroy': {
        200: () => 'Farbe gelöscht!',
      },
    },
  },
};
