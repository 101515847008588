import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { uuid } from 'uuidv4';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';

import { LocalizationContext, SocketContext } from '../../../../../AppContext';
import Snackbar from '../../../../../components/Snackbar';
import emit from '../../../../../util/emit';
import CitiesContext from '../../CitiesContext';
import localization from './DocumentsCreate.local';

export default function DocumentsCreate({
  documentcategories,
  documents,
  documenttypes,
  onSetDocuments,
  onBack,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const city = useContext(CitiesContext);
  const [name, setName] = useState(local.title);
  const [documentcategory, setDocumentcategory] = useState(null);
  const [documenttype, setDocumenttype] = useState(null);
  const [alert, setAlert] = useState();

  const onSubmit = (e) => {
    e.preventDefault();
    if (documenttype) {
      const pages = [];
      for (let i = 1; i <= documenttype.minPages; i += 1) {
        pages.push({ id: uuid(), name: `${local.createPage} ${i}` });
      }
      const document = {
        name,
        pages,
        city: city.id,
        documenttype: documenttype.id,
      };
      const onSuccess = (newDocument) => {
        onSetDocuments(documents ? [...documents, newDocument] : [newDocument]);
        onBack();
      };
      emit(socket, setAlert, 'documents.post', document, onSuccess);
    }
  };

  return (
    <Container sx={{ pt: 5 }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Paper sx={{ p: 5, mb: 5 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>{local.title}</Typography>
        <Box component="form" onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                variant="outlined"
                color="secondary"
                margin="dense"
                label={local.name}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                required
                disableClearable
                value={documentcategory}
                options={documentcategories
                  ? documentcategories
                    .filter((item) => item.active)
                    .sort((a, b) => moment(a.createdAt).diff(b.createdAt))
                  : []}
                onChange={(e, selected) => setDocumentcategory(selected)}
                getOptionLabel={((option) => option.name)}
                renderOption={(props, option, { selected }) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <Grid container {...props} component="li" sx={{ width: 1, background: selected ? 'action.selected' : undefined }}>
                    <Grid item xs={12}>
                      <Typography component="p">{option.name}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption">{option.description}</Typography>
                    </Grid>
                  </Grid>
                )}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    variant="outlined"
                    color="secondary"
                    margin="dense"
                    label={local.documentcategory}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                required
                disableClearable
                value={documenttype}
                options={documenttypes
                  ? documenttypes
                    .filter((item) => item.active && documentcategory && documentcategory.id === item.documentcategory)
                    .sort((a, b) => moment(a.createdAt).diff(b.createdAt))
                  : []}
                onChange={(e, selected) => setDocumenttype(selected)}
                getOptionLabel={((option) => option.name)}
                renderOption={(props, option, { selected }) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <Grid container {...props} component="li" sx={{ width: 1, background: selected ? 'action.selected' : undefined }}>
                    <Grid item xs={12}>
                      <Typography component="p">{option.name}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption">{option.description}</Typography>
                    </Grid>
                  </Grid>
                )}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    variant="outlined"
                    color="secondary"
                    margin="dense"
                    label={local.documenttype}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button color="primary" variant="outlined" onClick={onBack}>{local.back}</Button>
                <Button color="primary" variant="contained" type="submit" sx={{ ml: 2 }} disabled={!documenttype}>{local.create}</Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
}

DocumentsCreate.propTypes = {
  documentcategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ),
  documents: PropTypes.arrayOf(PropTypes.shape({})),
  documenttypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ),
  onSetDocuments: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

DocumentsCreate.defaultProps = {
  documentcategories: undefined,
  documents: undefined,
  documenttypes: undefined,
};
