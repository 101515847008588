import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import InfoMessages from './InfoMessages';

export default function Info({
  cityDocuments,
  cityMessages,
  cityPostings,
  messages,
  onSetMessages,
}) {
  return (
    <Container sx={{ pt: 3 }}>
      {console.log({ page: 'info', cityDocuments, cityPostings })}
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Paper sx={{ padding: 10, textAlign: 'center' }}>
            Hier entsteht der Info Bereich
          </Paper>
        </Grid>
        <Grid item xs={5}>
          <InfoMessages
            cityMessages={cityMessages}
            messages={messages}
            onSetMessages={onSetMessages}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

Info.propTypes = {
  cityDocuments: PropTypes.arrayOf(PropTypes.shape({})),
  cityMessages: PropTypes.arrayOf(PropTypes.shape({})),
  cityPostings: PropTypes.arrayOf(PropTypes.shape({})),
  messages: PropTypes.arrayOf(PropTypes.shape({})),
  onSetMessages: PropTypes.func.isRequired,
};

Info.defaultProps = {
  cityDocuments: undefined,
  cityMessages: undefined,
  cityPostings: undefined,
  messages: undefined,
};
