export default {
  de: {
    title: 'Farbpalette',
    create: 'Farbe hinzufügen',
    active: 'aktiv',
    newColor: 'Neue Farbe',
    inactive: 'inaktiv',
    alerts: {
      'colors.post': {
        200: () => 'Farbe erstellt!',
      },
    },
  },
};
