import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { LocalizationContext } from '../../../../../AppContext';
import DocumentEditorPageElement from './DocumentEditorPageElement';
import localization from './DocumentEditorPage.local';

export default function DocumentEditorPage({
  documentImages,
  elements,
  page,
  pagedefinitions,
  pagetypes,
  onPatch,
  onPatchImage,
}) {
  const local = localization[useContext(LocalizationContext)];
  const [name, setName] = useState('');
  const [pagedefinition, setPagedefinition] = useState(page.type ? pagedefinitions.find((item) => item.id === page.type) : null);
  const [pagetype, setPagetype] = useState(pagedefinition ? pagetypes.find((item) => item.id === pagedefinition.pagetype) : pagetypes[0]);
  const [typingTimeout, setTypingTimeout] = useState();

  const onChangeText = (setter, field, value) => {
    setter(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => onPatch(page.id, field, value), 2000));
  };

  const onPatchElement = (elementid, value) => {
    onPatch(page.id, 'values', { ...page.values, [elementid]: value });
  };

  const onPatchElementImage = (elementid, value, image) => {
    onPatchImage(page.id, 'values', { ...page.values, [elementid]: value }, value, image);
  };

  useEffect(() => setName(page.name), [page]);
  useEffect(() => setPagedefinition(page.type ? pagedefinitions.find((item) => item.id === page.type) : null), [pagedefinitions, page]);
  useEffect(() => setPagetype(pagedefinition ? pagetypes.find((item) => item.id === pagedefinition.pagetype) : pagetypes[0]), [pagedefinition]);

  return (
    <Paper sx={{ p: 1, mt: 1 }}>
      <TextField
        fullWidth
        variant="outlined"
        color="secondary"
        margin="dense"
        label={local.name}
        value={name}
        onChange={(e) => onChangeText(setName, 'name', e.target.value)}
      />
      <Autocomplete
        disableClearable
        value={pagetype}
        options={pagetypes.sort((a, b) => moment(a.createdAt).diff(b.createdAt))}
        onChange={(e, newValue) => setPagetype(newValue)}
        getOptionLabel={((option) => option.name)}
        renderOption={(props, option, { selected }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Grid container {...props} component="li" sx={{ width: 1, background: selected ? 'action.selected' : undefined }}>
            <Grid item xs={12}>
              <Typography component="p">{option.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">{option.description}</Typography>
            </Grid>
          </Grid>
        )}
        renderInput={(params) => (
          <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            fullWidth
            variant="outlined"
            color="secondary"
            margin="dense"
            label={local.pagetypes}
          />
        )}
      />
      <Autocomplete
        disableClearable
        value={pagedefinition}
        options={pagedefinitions
          .filter((item) => item.pagetype === pagetype.id && item.active)
          .sort((a, b) => moment(a.createdAt).diff(b.createdAt))}
        onChange={(e, newValue) => onPatch(page.id, 'type', newValue.id)}
        getOptionLabel={((option) => option.name)}
        renderOption={(props, option, { selected }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Grid container {...props} component="li" sx={{ width: 1, background: selected ? 'action.selected' : undefined }}>
            <Grid item xs={12}>
              <Typography component="p">{option.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">{option.description}</Typography>
            </Grid>
          </Grid>
        )}
        renderInput={(params) => (
          <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            fullWidth
            variant="outlined"
            color="secondary"
            margin="dense"
            label={local.pages}
          />
        )}
      />
      <Divider sx={{ mt: 1, mb: 1 }} />
      { pagedefinition && elements
        .filter((item) => item.parent === pagedefinition.id && ((['VIEW', 'FLEXVIEW', 'ARTICLE'].includes(item.type)) || item.editable))
        .sort((a, b) => moment(a.createdAt).diff(b.createdAt))
        .map((item) => (
          <DocumentEditorPageElement
            key={item.id}
            documentImages={documentImages}
            elements={elements}
            element={item}
            page={page}
            onPatch={onPatchElement}
            onPatchImage={onPatchElementImage}
          />
        ))}
    </Paper>
  );
}

DocumentEditorPage.propTypes = {
  documentImages: PropTypes.shape({}),
  elements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  page: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    values: PropTypes.shape({}),
  }).isRequired,
  pagedefinitions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pagetypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onPatch: PropTypes.func.isRequired,
  onPatchImage: PropTypes.func.isRequired,
};

DocumentEditorPage.defaultProps = {
  documentImages: undefined,
};
