import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Document as PdfDocument,
  Font,
  Page,
} from '@react-pdf/renderer';

import registerFonts from './registerFonts';
import DocumentElement from './DocumentElement';
import DocumentCropmark from './DocumentCropmark';

registerFonts(Font);

const convert = {
  mm: (value) => Math.round((parseFloat(value) * 2.83465) * 100) / 100,
  px: (value) => parseFloat(value),
};

const IMPRESSUM = 'IMPRESSUM';

const overlayType = {
  0: 'OVERLAY1',
  1: 'OVERLAY2',
};

export default function Document({
  title,
  format,
  overlay,
  elements,
  pagedefinitions,
  pages,
  orientation,
  indexCurrent,
  indexMax,
  designerImages,
  documentImages,
  variables,
  offset,
  cropmarks,
  cityId,
  cityVariables,
  colors,
}) {
  const [colorsDict, setColorsDict] = useState();

  useEffect(() => {
    if (colors) {
      const newColors = {};
      colors.forEach(({ id, hex }) => {
        newColors[id] = hex;
      });
      setColorsDict(newColors);
    }
  }, [colors]);

  return (
    <PdfDocument
      author="KOMMKO"
      creator="KOMMKO"
      producer="KOMMKO"
      title={title}
    >
      { elements && pages && pages.map((page, index) => {
        const pagedefinition = pagedefinitions ? pagedefinitions.find((item) => item.id === page.type) : undefined;
        const hasOverlay = pagedefinition ? pagedefinition.overlay : undefined;
        return (
          <Page
            key={page.id}
            wrap={false}
            orientation={orientation}
            size={{
              height: convert[format.unit](format.height) + convert.mm(offset * 2),
              width: convert[format.unit](format.width) + convert.mm(offset * 2),
            }}
          >
            { cropmarks && offset && <DocumentCropmark top left offset={offset} /> }
            { cropmarks && offset && <DocumentCropmark top right offset={offset} /> }
            { cropmarks && offset && <DocumentCropmark bottom left offset={offset} /> }
            { cropmarks && offset && <DocumentCropmark bottom right offset={offset} /> }
            { overlay && hasOverlay && elements
              .filter((element) => element.parent === overlay && element.type === overlayType[((indexCurrent || index) + 1) % 2])
              .sort((a, b) => moment(a.createdAt).diff(b.createdAt))
              .map((element) => (
                <DocumentElement
                  fixed
                  key={`${page.id}${element.id}`}
                  colors={colorsDict}
                  elements={elements}
                  element={element}
                  page={(indexCurrent || index) + 1}
                  designerImages={designerImages}
                  documentImages={documentImages}
                  values={page.values}
                  variables={variables}
                  cityId={cityId}
                  cityVariables={cityVariables}
                  offset={offset}
                />
              ))}
            { overlay && hasOverlay && elements
              .filter((element) => (element.parent === overlay)
                && (element.type === IMPRESSUM)
                && ((indexCurrent || index) === ((indexMax || pages.length) - 1)))
              .sort((a, b) => moment(a.createdAt).diff(b.createdAt))
              .map((element) => (
                <DocumentElement
                  fixed
                  key={`${page.id}${element.id}`}
                  colors={colorsDict}
                  elements={elements}
                  element={element}
                  page={(indexCurrent || index) + 1}
                  designerImages={designerImages}
                  documentImages={documentImages}
                  values={page.values}
                  variables={variables}
                  cityId={cityId}
                  cityVariables={cityVariables}
                  offset={offset}
                />
              ))}
            { elements
              .filter((element) => element.parent === (page.type || page.id))
              .sort((a, b) => moment(a.createdAt).diff(b.createdAt))
              .map((element) => (
                <DocumentElement
                  key={`${page.id}${element.id}`}
                  colors={colorsDict}
                  elements={elements}
                  element={element}
                  page={index + 1}
                  designerImages={designerImages}
                  documentImages={documentImages}
                  values={page.values}
                  variables={variables}
                  cityId={cityId}
                  cityVariables={cityVariables}
                  offset={offset}
                />
              ))}
          </Page>
        );
      })}
    </PdfDocument>
  );
}

Document.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      overlay: PropTypes.bool,
    }),
  ).isRequired,
  pagedefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  colors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  elements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  variables: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  designerImages: PropTypes.shape({}),
  documentImages: PropTypes.shape({}),
  cityId: PropTypes.string,
  cityVariables: PropTypes.shape({}).isRequired,
  overlay: PropTypes.string,
  indexCurrent: PropTypes.number,
  indexMax: PropTypes.number,
  format: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
    unit: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  orientation: PropTypes.oneOf(['portrait', 'landscape']),
  offset: PropTypes.number,
  cropmarks: PropTypes.bool,
};

Document.defaultProps = {
  cityId: undefined,
  pagedefinitions: undefined,
  overlay: undefined,
  format: undefined,
  orientation: 'portrait',
  offset: 0,
  cropmarks: undefined,
  designerImages: undefined,
  documentImages: undefined,
  indexCurrent: undefined,
  indexMax: undefined,
};
