export default {
  de: {
    title: 'Layout',
    description: '',
    display: 'Display',
    overflow: 'Overflow',
    zIndex: 'Ebene',
    position: 'Position',
    top: 'Oben in mm',
    right: 'Rechts in mm',
    bottom: 'Unten in mm',
    left: 'Links in mm',
    absolute: 'Absolut',
    relative: 'Relativ',
    offset: 'Bei Anschnitt ausgleichen',
  },
};
