export default {
  de: {
    title: 'Einstellungen',
    variables: 'Variablen',
    alerts: {
      'cities.patch': {
        200: () => 'Änderung gespeichert!',
      },
    },
  },
};
