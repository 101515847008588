import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { LocalizationContext } from '../../../../../AppContext';
import localization from './DocumentEditorPager.local';

export default function DocumentEditorPager({
  maxPages,
  minPages,
  newPages,
  pages,
  page,
  onChange,
  onAddPages,
  onRemovePages,
}) {
  const local = localization[useContext(LocalizationContext)];

  return (
    <Grid container spacing={1} sx={{ mt: 1 }}>
      <Grid item xs={12}>
        <Paper>
          <Tabs
            allowScrollButtonsMobile
            scrollButtons
            variant="scrollable"
            value={page}
            onChange={(event, value) => onChange(value)}
          >
            {pages && pages.map((tab) => (
              <Tab key={tab.id} value={tab} label={tab.name} />
            ))}
          </Tabs>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Button
          fullWidth
          variant="contained"
          disabled={maxPages !== 0 && pages.length >= maxPages}
          onClick={onAddPages}
          sx={{ mt: 0.5 }}
        >
          <AddIcon sx={{ mr: 1 }} />
          <Typography>{`${newPages} ${local.add}`}</Typography>
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          fullWidth
          variant="contained"
          disabled={pages.length === minPages}
          onClick={onRemovePages}
          sx={{ mt: 0.5 }}
        >
          <RemoveIcon sx={{ mr: 1 }} />
          <Typography>{`${newPages} ${local.remove}`}</Typography>
        </Button>
      </Grid>
    </Grid>
  );
}

DocumentEditorPager.propTypes = {
  maxPages: PropTypes.number.isRequired,
  minPages: PropTypes.number.isRequired,
  newPages: PropTypes.number.isRequired,
  pages: PropTypes.arrayOf(PropTypes.shape({})),
  page: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  onAddPages: PropTypes.func.isRequired,
  onRemovePages: PropTypes.func.isRequired,

};

DocumentEditorPager.defaultProps = {
  pages: undefined,
  page: undefined,
};
