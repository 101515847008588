import React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

export default function Postingtypes() {
  return (
    <Container sx={{ pt: 3 }}>
      <Paper sx={{ padding: 10, textAlign: 'center' }}>
        Hier entsteht der Designer
      </Paper>
    </Container>
  );
}
