import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import emit from '../../../../util/emit';
import localization from './UsersCreate.local';

export default function UsersCreate({
  city,
  onCreate,
  onCreateRole,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [alert, setAlert] = useState();

  const onSuccess = (user) => {
    if (user && user.id) {
      emit(socket, setAlert, 'users.postRole', { user: user.id, city: city.id }, (role) => onCreateRole(role));
      onCreate(user);
      setFirstname('');
      setLastname('');
      setEmail('');
      setExpanded(false);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    emit(socket, setAlert, 'users.post', { firstname, lastname, email }, onSuccess, onSuccess);
  };

  return (
    <Accordion sx={{ mb: 2 }} expanded={expanded} onChange={(e, state) => setExpanded(state)}>
      <AccordionSummary sx={{ backgroundColor: 'primary.main', color: 'white' }} expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
        {local.create}
      </AccordionSummary>
      <AccordionDetails>
        <Box component="form" onSubmit={onSubmit}>
          <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                variant="outlined"
                color="secondary"
                margin="dense"
                label={local.firstname}
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                variant="outlined"
                color="secondary"
                margin="dense"
                label={local.lastname}
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="email"
                variant="outlined"
                color="secondary"
                margin="dense"
                label={local.email}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={0} md={6} lg={8} />
            <Grid item xs={12} md={6} lg={4}>
              <Button fullWidth sx={{ marginTop: 2 }} color="primary" variant="contained" type="submit">{local.create}</Button>
            </Grid>
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

UsersCreate.propTypes = {
  city: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  onCreate: PropTypes.func.isRequired,
  onCreateRole: PropTypes.func.isRequired,
};
