export default {
  de: {
    title: 'Höhe und Breite',
    height: 'Höhe in mm',
    minHeight: 'min. Höhe in mm',
    maxHeight: 'max. Höhe in mm',
    width: 'Breite in mm',
    minWidth: 'min. Breite in mm',
    maxWidth: 'max. Breite in mm',
    fullWidth: 'Gesamte Breite',
    fullHeight: 'Gesamte Höhe',
  },
};
