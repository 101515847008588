import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DescriptionIcon from '@mui/icons-material/Description';

import { LocalizationContext } from '../../../../../../AppContext';
import localization from './Pagetype.local';

export default function Pagetype({
  pagetype,
  onUpdate,
  onDestroy,
  pages,
  onCreatePage,
  onPageClick,
}) {
  const local = localization[useContext(LocalizationContext)];
  const [pagetypeState, setPagetypeState] = useState(pagetype);
  const [typingTimeout, setTypingTimeout] = useState();

  useEffect(() => setPagetypeState(pagetype), [pagetype]);

  const onTextChange = (field, value) => {
    setPagetypeState({ ...pagetypeState, [field]: value });
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => onUpdate(pagetypeState.id, field, value), 1000),
    );
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: 'background.default' }}>
        <DescriptionIcon sx={{ mr: 2 }} />
        <Typography>{pagetypeState.name}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: 'background.default' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              color="secondary"
              label={local.name}
              value={pagetypeState.name}
              onChange={(e) => onTextChange('name', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              color="secondary"
              label={local.description}
              value={pagetypeState.description}
              onChange={(e) => onTextChange('description', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ mt: 2 }}>{(pages && pages.length > 0) ? local.pages : local.noPages }</Typography>
          </Grid>
          { pages && pages.length > 0 && (
            <Grid item xs={12} container spacing={5}>
              { pages.map((page) => (
                <Grid key={page.id} item xs={6} md={4} lg={3}>
                  <Card sx={{ width: 1, minHeight: 100 }} onClick={() => onPageClick(page.id)}>
                    <CardActionArea sx={{ width: 1, minHeight: 100 }}>
                      <CardContent>
                        <Chip
                          size="small"
                          color={page.active ? 'success' : 'secondary'}
                          label={page.active ? local.active : local.inactive}
                          sx={{ position: 'absolute', top: 5, right: 5 }}
                        />
                        <Typography variant="h6" sx={{ pb: 2 }}>{page.name}</Typography>
                        <Typography variant="p">{page.description}</Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
        <Box display="flex" justifyContent="flex-end" sx={{ mt: 2, mb: 2 }}>
          { (!pages || pages.length === 0) && (
            <Button color="primary" variant="outlined" sx={{ mr: 2 }} onClick={() => onDestroy(pagetype.id)}>{local.destroy}</Button>
          )}
          <Button color="primary" variant="contained" onClick={() => onCreatePage(pagetype.id)}>{local.createPage}</Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

Pagetype.propTypes = {
  pagetype: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
  pages: PropTypes.arrayOf(PropTypes.shape({})),
  onCreatePage: PropTypes.func.isRequired,
  onPageClick: PropTypes.func.isRequired,
};

Pagetype.defaultProps = {
  pages: undefined,
};
