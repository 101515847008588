import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';
import MailIcon from '@mui/icons-material/Mail';

import { LocalizationContext } from '../../../../../AppContext';

import localization from './DocumentPrinterHeader.local';

export default function DocumentPrinterHeader({
  isReady,
  name,
  onBack,
  onSend,
}) {
  const local = localization[useContext(LocalizationContext)];

  return (
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <Typography variant="h4">{name}</Typography>
      </Grid>
      <Grid container item spacing={1} xs={4}>
        <Grid item xs={4}>
          <Button fullWidth variant="contained" color="secondary" onClick={() => onBack()}>
            <TurnLeftIcon sx={{ mr: 1 }} />
            <Typography>{local.back}</Typography>
          </Button>
        </Grid>
        <Grid item xs={8}>
          <Button fullWidth variant="contained" onClick={onSend} disabled={!isReady}>
            <MailIcon sx={{ mr: 1 }} />
            <Typography>{local.send}</Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

DocumentPrinterHeader.propTypes = {
  isReady: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
};
