export default {
  de: {
    name: 'Name',
    description: 'Beschreibung',
    createdAt: 'erstellt am',
    updatedAt: 'zuletzt bearbeitet am',
    editor: ' zuletzt bearbeitet von',
    destroy: ' Variable löschen',
    alerts: {
      'variables.patch': {
        200: () => 'Änderungen gespeichert!',
      },
      'variables.destroy': {
        200: () => 'Variable gelöscht!',
      },
    },
  },
};
