import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { LocalizationContext } from '../../AppContext';
import ColorPicker from '../ColorPicker';
import localization from './DesignerColor.local';

export default function DesignerColor({
  values, colors, onUpdate, onTextUpdate,
}) {
  const local = localization[useContext(LocalizationContext)];

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{local.title}</Typography>
        <Typography>{local.description}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ColorPicker
          label={local.backgroundColor}
          value={values.backgroundColor}
          options={colors}
          onChange={(selected) => onUpdate('backgroundColor', selected)}
        />
        <ColorPicker
          label={local.color}
          value={values.color}
          options={colors}
          onChange={(selected) => onUpdate('color', selected)}
        />
        <TextField
          fullWidth
          variant="outlined"
          color="secondary"
          margin="dense"
          type="number"
          label={local.opacity}
          value={values.opacity}
          onChange={(e) => onTextUpdate('opacity', e.target.value)}
        />
      </AccordionDetails>
    </Accordion>
  );
}
DesignerColor.propTypes = {
  values: PropTypes.shape({
    backgroundColor: PropTypes.number,
    color: PropTypes.number,
    opacity: PropTypes.string,
  }).isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onTextUpdate: PropTypes.func.isRequired,
};
