import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

export default function DocumentEditorPageElementText({
  element,
  flexElementId,
  page,
  onPatch,
}) {
  const [text, setText] = useState('');
  const [typingTimeout, setTypingTimeout] = useState();

  useEffect(() => {
    if (page.values) {
      if (flexElementId) {
        setText(page.values[`${flexElementId}--${element.id}`] || '');
      } else {
        setText(page.values[element.id] || '');
      }
    }
  }, [element, page]);

  const onChangeText = (newValue) => {
    if (newValue.length <= element.value || newValue.length <= text.length) {
      setText(newValue);
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      const id = flexElementId ? `${flexElementId}--${element.id}` : element.id;
      setTypingTimeout(setTimeout(() => onPatch(id, newValue), 2000));
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h2">{element.name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption">{element.description}</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          variant="outlined"
          color="secondary"
          margin="dense"
          label={`${text.length} / ${element.value} Zeichen `}
          value={text}
          onChange={(e) => onChangeText(e.target.value)}
        />

      </Grid>
    </Grid>
  );
}

DocumentEditorPageElementText.propTypes = {
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    value: PropTypes.string.isRequired,
  }).isRequired,
  flexElementId: PropTypes.string,
  page: PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  onPatch: PropTypes.func.isRequired,
};

DocumentEditorPageElementText.defaultProps = {
  flexElementId: undefined,
};
