export default (socket, setAlert, namespace, list, setList, room) => {
  socket.on(`${namespace}.post`, (response) => {
    if ((room && room === response.room) || !room) {
      setList(list ? [...list, response.payload] : [response.payload]);
      setAlert({ ...response, event: `${namespace}.post` });
    }
  });
  socket.on(`${namespace}.patch`, (response) => {
    if ((room && room === response.room) || !room) {
      setList(list ? list.map((item) => (item.id === response.payload.id ? response.payload : item)) : [response.payload]);
      setAlert({ ...response, event: `${namespace}.patch` });
    }
  });
  socket.on(`${namespace}.destroy`, (response) => {
    if ((room && room === response.room) || !room) {
      setList(list ? list.filter((item) => (item.id !== response.payload.id)) : undefined);
      setAlert({ ...response, event: `${namespace}.destroy` });
    }
  });
  return () => {
    socket.off(`${namespace}.post`);
    socket.off(`${namespace}.patch`);
    socket.off(`${namespace}.destroy`);
  };
};
