import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function ColorPicker({
  label, value, options, onChange,
}) {
  const selectedColor = options ? options.find(({ id }) => id === value) : undefined;

  return (
    <Autocomplete
      fullWidth
      value={selectedColor}
      options={options}
      onChange={(event, newValue) => onChange(newValue ? newValue.id : undefined)}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <Box
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          component="li"
          display="flex"
          justify="center"
          alignItems="center"
          sx={{ width: 1, background: selected ? 'action.selected' : undefined }}
        >
          <Avatar
            sx={{
              width: 30,
              height: 30,
              color: option.hex,
              background: option.hex,
              mr: 1,
            }}
          />
          {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <Box sx={{ width: 1 }} display="flex" justify="center" alignItems="center">
          <Avatar
            sx={{
              width: 30,
              height: 30,
              color: selectedColor ? selectedColor.hex : undefined,
              background: selectedColor ? selectedColor.hex : undefined,
              mr: 1,
            }}
          >
            {selectedColor ? '' : '-'}
          </Avatar>
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            label={label}
            variant="outlined"
            color="secondary"
            margin="dense"
          />
        </Box>
      )}
    />
  );
}

ColorPicker.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.shape({})),
};

ColorPicker.defaultProps = {
  value: null,
  options: [],
};
