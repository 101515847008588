import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import List from '@mui/material/List';

import { UserContext } from '../../AppContext';
import Copyright from '../../components/Copyright';
import DashboardMenu from './dashboard/DashboardMenu';
import ExamplesMenu from './examples/ExamplesMenu';
import DesignerMenu from './designer/DesignerMenu';
import OrganisationMenu from './organisation/OrganisationMenu';
import CitiesMenu from './cities/CitiesMenu';

const logo = `${process.env.PUBLIC_URL}/${process.env.REACT_APP_LOGO}`;
const appname = process.env.REACT_APP_NAME;

export default function AuthenticatedMenu({ userCities, width }) {
  const user = useContext(UserContext);

  return (
    <Drawer
      hideBackdrop
      anchor="left"
      variant="permanent"
      sx={{
        width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width,
          backgroundColor: 'primary.main',
          color: 'primary.contrastText',
        },
        '& .MuiDrawer-divider': {
          background: 'rgb(255,255,255,0.5)',
        },
      }}
    >
      <Tooltip title={appname}>
        <Button fullWidth variant="text" component={Link} to="/">
          <Avatar variant="square" sx={{ width: 80, height: 80 }} src={logo} />
        </Button>
      </Tooltip>
      <List component="nav">
        <DashboardMenu />
        <ExamplesMenu />
        { user.admin && <Divider sx={{ backgroundColor: 'primary.contrastText', m: 1 }} /> }
        { user.admin && <DesignerMenu /> }
        { user.admin && <OrganisationMenu /> }
        { user.admin && <Divider sx={{ backgroundColor: 'primary.contrastText', m: 1 }} /> }
        <CitiesMenu userCities={userCities} />
      </List>
      <Box sx={{
        position: 'absolute',
        bottom: 5,
        left: 0,
        right: 0,
      }}
      >
        <Copyright color="white" />
      </Box>
    </Drawer>
  );
}

AuthenticatedMenu.propTypes = {
  userCities: PropTypes.arrayOf(PropTypes.shape({})),
  width: PropTypes.number,
};

AuthenticatedMenu.defaultProps = {
  userCities: undefined,
  width: 200,
};
