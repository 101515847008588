import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import emit from '../../../../util/emit';
import localization from './VariablesChange.local';

export default function VariablesChange({
  variable,
  onChange,
  onDestroy,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [name, setName] = useState(variable.name);
  const [description, setDescription] = useState(variable.description);
  const [typingTimeout, setTypingTimeout] = useState();
  const [editor, setEditor] = useState();
  const [alert, setAlert] = useState();

  useEffect(() => {
    if (socket) {
      emit(socket, setAlert, 'users.getOneById', { id: variable.editor }, (foundEditor) => setEditor(foundEditor));
    }
  }, [socket]);

  const onTextChange = (setter, field, value) => {
    setter(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => emit(socket, setAlert, 'variables.patch', { id: variable.id, field, value }, onChange), 1000));
  };

  const onDelete = () => {
    emit(socket, setAlert, 'variables.destroy', { id: variable.id }, onDestroy);
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {name}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.name}
              value={name}
              onChange={(e) => onTextChange(setName, 'name', e.target.value.toUpperCase())}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.description}
              value={description}
              onChange={(e) => onTextChange(setDescription, 'description', e.target.value)}
            />
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="center" sx={{ mt: 1 }}>
                <Typography>{local.createdAt}</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="center" sx={{ mt: 1 }}>
                <Typography>{moment(variable.createdAt).format('DD.MM.YYYY')}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="center" sx={{ mt: 1 }}>
                <Typography>{local.updatedAt}</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="center" sx={{ mt: 1 }}>
                <Typography>{moment(variable.updatedAt).format('DD.MM.YYYY')}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="center" sx={{ mt: 1 }}>
                <Typography>{local.editor}</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              { editor ? (
                <Box display="flex" justifyContent="center" sx={{ mt: 1 }}>
                  <Avatar src={editor.avatar} alt={`${editor.firstname} ${editor.lastname}`} sx={{ width: 30, height: 30, mr: 1 }} />
                  <Typography>{`${editor.firstname} ${editor.lastname}`}</Typography>
                </Box>
              ) : (
                <Box display="flex" justifyContent="center" sx={{ mt: 1 }}>
                  <CircularProgress />
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid container item xs={12} sx={{ marginTop: 5 }}>
            <Grid item xs={0} md={6} lg={8} />
            <Grid item xs={12} md={6} lg={4}>
              <Button fullWidth color="primary" variant="contained" onClick={onDelete}>{local.destroy}</Button>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

VariablesChange.propTypes = {
  variable: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    editor: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
};
