export default {
  de: {
    kommko: 'KOMMKO',
    designer: 'Designer',
    colors: 'Farbpalette',
    profile: 'Profil',
    logout: 'Logout',
    save: 'Ihre Eingaben werden automatisch gespeichert!',
    alerts: {
      401: () => 'Sie haben ein falsches Passwort eingegeben. Bitte prüfen Sie Ihre Eingabe!',
      404: ({ email }) => `Der Benutzer '${email}' ist nicht registriert. Bitte prüfen Sie Ihre Eingabe!`,
      500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
    },
  },
};
