export default {
  de: {
    kommko: 'KOMMKO',
    designer: 'Designer',
    colors: 'Farbpalette',
    profile: 'Profil',
    logout: 'Logout',
    save: 'Ihre Eingaben werden automatisch gespeichert!',
  },
};
