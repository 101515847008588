export default {
  de: {
    alerts: {
      verifySessionToken: {
        200: () => 'Sitzung gefunden, sie werden eingeloggt...',
      },
      401: () => 'Der Link ist abgelaufen. Bitte fordern Sie einen neuen Passwort-Vergessen-Link an!',
      403: () => 'Der Link ungültig. Bitte fordern Sie einen neuen Passwort-Vergessen-Link an!',
      500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
    },
  },
};
