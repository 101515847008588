import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Autocomplete from '@mui/material/Autocomplete';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import emit from '../../../../util/emit';
import localization from './FormatsEdit.local';

export default function FormatsEdit({
  formats,
  onSetFormats,
  onBack,
}) {
  const { formatid } = useParams();
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const format = formats ? formats.find(({ id }) => id === parseInt(formatid, 10)) : undefined;
  const [editor, setEditor] = useState();
  const [name, setName] = useState(format ? format.name : '');
  const [height, setHeight] = useState(format ? format.height : 0);
  const [width, setWidth] = useState(format ? format.width : 0);
  const [typingTimeout, setTypingTimeout] = useState();
  const [alert, setAlert] = useState();

  useEffect(() => {
    if (format) {
      setName(format.name);
      setHeight(format.height);
      setWidth(format.width);
    }
  }, [format]);

  useEffect(() => {
    if (socket && format) {
      emit(socket, setAlert, 'users.getOneById', { id: format.editor }, (formatEditor) => setEditor(formatEditor));
    }
  }, [socket, format]);

  const onDestroy = () => {
    emit(socket, setAlert, 'formats.destroy', { id: format.id }, (destroyedFormat) => {
      onSetFormats(formats.filter(({ id }) => id !== destroyedFormat.id));
      onBack();
    });
  };

  const onChange = (field, value) => {
    emit(socket, setAlert, 'formats.patch', { id: format.id, field, value }, (updatedFormat) => {
      onSetFormats(formats.map((item) => (item.id === updatedFormat.id ? updatedFormat : item)));
    });
  };

  const onTextChange = (setter, field, value) => {
    setter(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => onChange(field, value), 1000));
  };

  return (
    <Container sx={{ pt: 5 }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Paper sx={{ p: 5 }}>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={8}>
            <TextField
              required
              fullWidth
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.name}
              value={name}
              onChange={(e) => onTextChange(setName, 'name', e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              disableClearable
              options={['mm', 'px']}
              value={format ? format.unit : null}
              onChange={(event, newValue) => onChange('unit', newValue)}
              renderInput={(params) => (
                <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.unit}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.height}
              value={height}
              onChange={(e) => onTextChange(setHeight, 'height', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.width}
              value={width}
              onChange={(e) => onTextChange(setWidth, 'width', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            {local.active}
          </Grid>
          <Grid item xs={6}>
            <Switch checked={format ? format.active : true} onChange={(e) => onChange('active', e.target.checked)} />
          </Grid>
          <Grid item xs={6}>
            <Typography>{local.editor}</Typography>
          </Grid>
          <Grid item xs={6}>
            {editor && (
              <Box display="flex" sx={{ mt: 1 }}>
                <Avatar src={editor.avatar} alt={`${editor.firstname} ${editor.lastname}`} sx={{ width: 30, height: 30, mr: 1 }} />
                <Typography>{`${editor.firstname} ${editor.lastname}`}</Typography>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={6}>
            <Button fullWidth sx={{ marginTop: 2 }} color="primary" variant="outlined" onClick={onBack}>{local.back}</Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth sx={{ marginTop: 2 }} color="primary" variant="contained" onClick={onDestroy}>{local.destroy}</Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

FormatsEdit.propTypes = {
  formats: PropTypes.arrayOf(PropTypes.shape({})),
  onSetFormats: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

FormatsEdit.defaultProps = {
  formats: undefined,
};
