import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import DescriptionIcon from '@mui/icons-material/Description';
import TwitterIcon from '@mui/icons-material/Twitter';
import PaletteIcon from '@mui/icons-material/Palette';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';

import { LocalizationContext } from '../../../AppContext';
import localization from './DesignerMenu.local';

export default function DesignerMenu() {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(pathname.includes('/designer'));
  const local = localization[useContext(LocalizationContext)];

  const getBackgroundColor = (path) => (pathname.includes(path) ? 'action.selected' : 'primary.main');

  return (
    <List disablePadding>
      <ListItem button onClick={() => setOpen(!open)}>
        <ListItemIcon sx={{ color: 'primary.contrastText', minWidth: 35 }}><DesignServicesIcon /></ListItemIcon>
        <ListItemText>{local.designer}</ListItemText>
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      { open && (
        <List disablePadding>
          <ListItem button to="/designer/documentcategories" component={Link} sx={{ pl: 4, backgroundColor: getBackgroundColor('/designer/documentcategories') }}>
            <ListItemIcon sx={{ color: 'primary.contrastText', minWidth: 35 }}><DescriptionIcon /></ListItemIcon>
            <ListItemText>{local.documenttypes}</ListItemText>
          </ListItem>
          <ListItem button to="/designer/postingtypes" component={Link} sx={{ pl: 4, backgroundColor: getBackgroundColor('/designer/postingtypes') }}>
            <ListItemIcon sx={{ color: 'primary.contrastText', minWidth: 35 }}><TwitterIcon /></ListItemIcon>
            <ListItemText>{local.postingtypes}</ListItemText>
          </ListItem>
          <ListItem button to="/designer/colors" component={Link} sx={{ pl: 4, backgroundColor: getBackgroundColor('/designer/colors') }}>
            <ListItemIcon sx={{ color: 'primary.contrastText', minWidth: 35 }}><PaletteIcon /></ListItemIcon>
            <ListItemText>{local.colors}</ListItemText>
          </ListItem>
          <ListItem button to="/designer/formats" component={Link} sx={{ pl: 4, backgroundColor: getBackgroundColor('/designer/formats') }}>
            <ListItemIcon sx={{ color: 'primary.contrastText', minWidth: 35 }}><AspectRatioIcon /></ListItemIcon>
            <ListItemText>{local.formats}</ListItemText>
          </ListItem>
        </List>
      )}
    </List>
  );
}
