import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import packageJson from '../../package.json';

export default function Copyright({ color }) {
  return (
    <Box marginTop={1} sx={{ color }}>
      <Box>
        <Typography component="p" variant="caption" align="center">
          {`${packageJson.name} ${packageJson.version}`}
        </Typography>
      </Box>
      <Box>
        <Typography component="p" variant="caption" align="center">
          {'Copyright © '}
          <Link color="inherit" href={process.env.REACT_APP_COPYRIGHT_URL} target="_blank">
            {process.env.REACT_APP_COPYRIGHT}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}

Copyright.propTypes = {
  color: PropTypes.string,
};

Copyright.defaultProps = {
  color: 'black',
};
