import React, { useContext } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import CitiesContext from '../CitiesContext';

import DocumentsCreate from './documentscreate/DocumentsCreate';
import Document from './document/Document';
import DocumentsBoard from './documentsboard/DocumentsBoard';

export default function Documents({
  cityDocuments,
  colors,
  documentcategories,
  documents,
  documenttypes,
  formats,
  printers,
  variables,
  onSetDocuments,
}) {
  const city = useContext(CitiesContext);
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        exact
        path="create"
        element={(
          <DocumentsCreate
            documents={documents}
            documentcategories={documentcategories}
            documenttypes={documenttypes}
            onSetDocuments={onSetDocuments}
            onBack={() => navigate(`/cities/${city.id}/documents`)}
          />
        )}
      />
      <Route
        exact
        path=":documentid/*"
        element={(
          <Document
            cityDocuments={cityDocuments}
            colors={colors}
            documents={documents}
            documenttypes={documenttypes}
            formats={formats}
            printers={printers}
            variables={variables}
            onSetDocuments={onSetDocuments}
            onBack={() => navigate(`/cities/${city.id}/documents`)}
          />
        )}
      />
      <Route
        exact
        path="/"
        element={(
          <DocumentsBoard
            cityDocuments={cityDocuments}
            documentcategories={documentcategories}
            documenttypes={documenttypes}
            onClickDocument={(documentid) => navigate(`/cities/${city.id}/documents/${documentid}`)}
            onClickCreate={() => navigate(`/cities/${city.id}/documents/create`)}
          />
        )}
      />
    </Routes>
  );
}

Documents.propTypes = {
  cityDocuments: PropTypes.arrayOf(PropTypes.shape({})),
  colors: PropTypes.arrayOf(PropTypes.shape({})),
  documentcategories: PropTypes.arrayOf(PropTypes.shape({})),
  documents: PropTypes.arrayOf(PropTypes.shape({})),
  documenttypes: PropTypes.arrayOf(PropTypes.shape({})),
  formats: PropTypes.arrayOf(PropTypes.shape({})),
  printers: PropTypes.arrayOf(PropTypes.shape({})),
  variables: PropTypes.arrayOf(PropTypes.shape({})),
  onSetDocuments: PropTypes.func.isRequired,
};

Documents.defaultProps = {
  cityDocuments: undefined,
  colors: undefined,
  documentcategories: undefined,
  documents: undefined,
  documenttypes: undefined,
  formats: undefined,
  printers: undefined,
  variables: undefined,
};
