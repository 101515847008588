import React from 'react';
import PropTypes from 'prop-types';
import { PDFViewer } from '@react-pdf/renderer';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

import PDFDocument from '../../../../../components/Document/Document';

import DocumentEditorDetails from './DocumentEditorDetails';
import DocumentEditorHeader from './DocumentEditorHeader';
import DocumentEditorPage from './DocumentEditorPage';
import DocumentEditorPager from './DocumentEditorPager';

export default function DocumentEditor({
  colors,
  city,
  designerImages,
  document,
  documentImages,
  documenttype,
  elements,
  formats,
  page,
  pagedefinitions,
  pagetypes,
  onAddPages,
  onBack,
  onPatch,
  onPatchPage,
  onPatchPageImage,
  onPrint,
  onRemovePages,
  onSetPage,
  variables,
}) {
  return (
    <div>
      <Grid container spacing={1} sx={{ p: 1 }}>
        <Grid item xs={12}>
          { document ? (
            <DocumentEditorHeader
              name={document.name}
              onBack={onBack}
              onPatch={onPatch}
              onPrint={onPrint}
            />
          ) : <Skeleton variant="rect" /> }
        </Grid>
        <Grid item xs={8}>
          { document && documenttype && elements ? (
            <PDFViewer width="100%" height="850px" style={{ border: 'none' }}>
              <PDFDocument
                colors={colors}
                title={documenttype.name}
                orientation={documenttype.orientation}
                overlay={documenttype.id}
                elements={elements}
                variables={variables}
                designerImages={designerImages}
                documentImages={documentImages}
                cityId={city ? city.id : undefined}
                cityVariables={city ? city.variables : undefined}
                format={formats.find((item) => item.id === documenttype.format)}
                pagedefinitions={pagedefinitions}
                pages={[page]}
                indexCurrent={document.pages.indexOf(page)}
                indexMax={document.pages.length}
              />
            </PDFViewer>
          ) : <Skeleton variant="rect" /> }
        </Grid>
        <Grid item xs={4}>
          { document ? (
            <DocumentEditorDetails document={document} onPatch={onPatch} />
          ) : <Skeleton variant="rect" /> }
          { document && documenttype ? (
            <DocumentEditorPager
              maxPages={documenttype.maxPages}
              minPages={documenttype.minPages}
              newPages={documenttype.newPages}
              pages={document ? document.pages : undefined}
              page={page}
              onChange={(newPage) => onSetPage(newPage)}
              onAddPages={onAddPages}
              onRemovePages={onRemovePages}
            />
          ) : <Skeleton variant="rect" /> }
          { elements && page && pagedefinitions && pagetypes ? (
            <DocumentEditorPage
              documentImages={documentImages}
              elements={elements}
              page={page}
              pagedefinitions={pagedefinitions}
              pagetypes={pagetypes}
              onPatch={onPatchPage}
              onPatchImage={onPatchPageImage}
            />
          ) : (
            <Skeleton variant="rect" />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

DocumentEditor.propTypes = {
  city: PropTypes.shape({
    id: PropTypes.string,
    variables: PropTypes.shape({}),
  }),
  colors: PropTypes.arrayOf(PropTypes.shape({})),
  designerImages: PropTypes.shape({}),
  document: PropTypes.shape({
    name: PropTypes.string,
    pages: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  documentImages: PropTypes.shape({}),
  documenttype: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    orientation: PropTypes.string,
    format: PropTypes.number,
    maxPages: PropTypes.number,
    minPages: PropTypes.number,
    newPages: PropTypes.number,
  }),
  elements: PropTypes.arrayOf(PropTypes.shape({})),
  formats: PropTypes.arrayOf(PropTypes.shape({})),
  page: PropTypes.shape({}),
  pagedefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  pagetypes: PropTypes.arrayOf(PropTypes.shape({})),
  onAddPages: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onPatch: PropTypes.func.isRequired,
  onPatchPage: PropTypes.func.isRequired,
  onPatchPageImage: PropTypes.func.isRequired,
  onPrint: PropTypes.func.isRequired,
  onRemovePages: PropTypes.func.isRequired,
  onSetPage: PropTypes.func.isRequired,
  variables: PropTypes.arrayOf(PropTypes.shape({})),
};

DocumentEditor.defaultProps = {
  city: undefined,
  colors: undefined,
  designerImages: undefined,
  document: undefined,
  documentImages: undefined,
  documenttype: undefined,
  elements: undefined,
  formats: undefined,
  page: undefined,
  pagedefinitions: undefined,
  pagetypes: undefined,
  variables: undefined,
};
