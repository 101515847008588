import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { UserContext, LocalizationContext } from '../../../AppContext';
import Announcements from './DashboardAnnouncements';
import Support from './Support/Support';
import localization from './Dashboard.local';

export default function Dashboard({
  announcements,
  // documents,
  // postings,
  supports,
  onSetAnnouncements,
  onSetSupports,
}) {
  const local = localization[useContext(LocalizationContext)];
  const user = useContext(UserContext);

  return (
    <Container sx={{ pt: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1" sx={{ mb: 2 }}>{local.title(user.firstname)}</Typography>
          <Typography variant="h2" sx={{ mb: 2 }}>{local.welcome}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Announcements
            announcements={announcements}
            onSetAnnouncements={onSetAnnouncements}
          />
        </Grid>
        <Grid item xs={6}>
          <Support
            supports={supports}
            onSetSupports={onSetSupports}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

Dashboard.propTypes = {
  announcements: PropTypes.arrayOf(PropTypes.shape({})),
  // documents: PropTypes.arrayOf(PropTypes.shape({})),
  // postings: PropTypes.arrayOf(PropTypes.shape({})),
  supports: PropTypes.arrayOf(PropTypes.shape({})),
  onSetAnnouncements: PropTypes.func.isRequired,
  onSetSupports: PropTypes.func.isRequired,
};

Dashboard.defaultProps = {
  announcements: undefined,
  // documents: undefined,
  // postings: undefined,
  supports: undefined,
};
