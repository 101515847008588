import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import emit from '../../../../util/emit';
import CitiesChange from './CitiesChange';
import CitiesCreate from './CitiesCreate';
import localization from './Cities.local';

export default function Cities({ variables }) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [cities, setCities] = useState();
  const [users, setUsers] = useState();
  const [roles, setRoles] = useState();
  const [alert, setAlert] = useState();

  useEffect(() => {
    if (socket) {
      emit(socket, setAlert, 'cities.getAll', undefined, (foundCities) => setCities(foundCities));
      emit(socket, setAlert, 'users.getAll', undefined, (data) => {
        setUsers(data.users);
        setRoles(data.roles);
      });
    }
  }, [socket]);

  const onCreate = (city) => setCities([...cities, city]);
  const onChange = (city) => setCities(cities.map((item) => (item.id === city.id ? city : item)));
  const onDestroy = (city) => setCities(cities.filter((item) => (item.id !== city.id)));

  return (
    <Container sx={{ pt: 5, mb: 5 }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Typography variant="h1" sx={{ mb: 2 }}>{local.title}</Typography>
      <CitiesCreate onCreate={onCreate} />
      {cities && users && roles ? cities.map((city) => (
        <CitiesChange
          key={city.id}
          city={city}
          users={users}
          roles={roles}
          variables={variables}
          onChange={onChange}
          onDestroy={onDestroy}
        />
      )) : (
        <Skeleton variant="rectangular" height={100} />
      )}
    </Container>
  );
}

Cities.propTypes = {
  variables: PropTypes.arrayOf(PropTypes.shape({})),
};

Cities.defaultProps = {
  variables: undefined,
};
