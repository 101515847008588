export default {
  de: {
    name: 'Name',
    description: 'Beschreibung',
    create: 'Variable hinzufügen',
    alerts: {
      'variables.post': {
        200: () => 'Änderungen gespeichert!',
      },
    },
  },
};
