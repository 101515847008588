import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import { ThemeProvider } from '@mui/material/styles';

import Unauthenticated from './pages/unauthenticated/Unauthenticated';
import Authenticated from './pages/authenticated/Authenticated';
import Snackbar from './components/Snackbar';
import emit from './util/emit';
import {
  SocketContext,
  LocalizationContext,
  UserContext,
} from './AppContext';
import AppTheme from './AppTheme';
import localization from './App.local';

const unauthenticatedSocket = io(process.env.REACT_APP_SOCKET_URL);

export default function AppContainer() {
  const [language] = useState(localStorage.getItem('language') || 'de');
  const local = localization[language];
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [user, setUser] = useState(sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : undefined);
  const [socket, setSocket] = useState();
  const [alert, setAlert] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const onSuccess = (userData) => {
      sessionStorage.setItem('user', JSON.stringify(userData));
      setUser(userData);
    };
    const onError = () => {
      localStorage.removeItem('user');
    };
    if (token && !user) {
      emit(unauthenticatedSocket, setAlert, 'verifySessionToken', token, onSuccess, onError);
    } else if (user) {
      unauthenticatedSocket.disconnect();
    }
  }, [token]);

  useEffect(() => {
    if (!token || !user) {
      unauthenticatedSocket.connect();
      setSocket();
      return () => {
        unauthenticatedSocket.disconnect();
      };
    }
    const newAuthenticatedSocket = io(`${process.env.REACT_APP_SOCKET_URL}/authenticated`, { auth: { token } });
    setSocket(newAuthenticatedSocket);
    return () => {
      newAuthenticatedSocket.disconnect();
    };
  }, [user, token]);

  const onLogin = (newToken, newUser) => {
    localStorage.setItem('token', newToken);
    sessionStorage.setItem('user', JSON.stringify(newUser));
    setUser(newUser);
    setToken(newToken);
  };

  const onLogout = () => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('user');
    setToken();
    setUser();
    navigate('/');
  };

  const onUpdateUser = (patch) => {
    setUser(patch);
    sessionStorage.setItem('user', JSON.stringify(patch));
  };

  return (
    <ThemeProvider theme={AppTheme}>
      <LocalizationContext.Provider value={language}>
        <UserContext.Provider value={user}>
          <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />

          { socket && user ? (
            <SocketContext.Provider value={socket}>
              <Authenticated onUpdateUser={onUpdateUser} onLogout={onLogout} />
            </SocketContext.Provider>
          ) : (
            <SocketContext.Provider value={unauthenticatedSocket}>
              <Unauthenticated onLogin={onLogin} />
            </SocketContext.Provider>
          )}
        </UserContext.Provider>
      </LocalizationContext.Provider>
    </ThemeProvider>
  );
}
