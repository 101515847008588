import React, { useContext, useState, useEffect } from 'react';
import {
  useParams, useNavigate, Routes, Route,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { LocalizationContext, SocketContext } from '../../../../../../AppContext';
import Snackbar from '../../../../../../components/Snackbar';
import emit from '../../../../../../util/emit';
import join from '../../../../../../util/join';
import syn from '../../../../../../util/syn';
import DocumenttypeEditor from './DocumenttypeEditor';
import PageDesigner from './PageDesigner';
import Pagetype from './Pagetype';
import localization from './Documenttype.local';

export default function Documenttype({
  colors,
  documentcategory,
  documenttypes,
  formats,
  variables,
  onBack,
  onSetDocumenttypes,
}) {
  const navigate = useNavigate();
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const { documenttypeid } = useParams();
  const [documenttype, setDocumenttype] = useState();
  const [pagetypes, setPagetypes] = useState();
  const [pages, setPages] = useState();
  const [elements, setElements] = useState();
  const [images, setImages] = useState();
  const [alert, setAlert] = useState();
  const [cities, setCities] = useState();

  useEffect(() => {
    if (socket) {
      emit(socket, setAlert, 'cities.getAll', undefined, (foundCities) => setCities(foundCities));
    }
  }, [socket]);

  useEffect(() => {
    if (documenttypes && documenttypeid) {
      const foundDocumenttype = documenttypes.find(({ id }) => id === documenttypeid);
      if (foundDocumenttype) {
        setDocumenttype(foundDocumenttype);
      } else {
        onBack();
      }
    }
  }, [documenttypes, documenttypeid]);

  useEffect(() => {
    const loadImages = async () => {
      const newImages = {};
      const designerImages = await Promise.all(elements.filter((item) => !item.editable).map(async (element) => ({
        id: element.id,
        image: await new Promise((resolve) => {
          emit(socket, setAlert, 'images.download', { id: element.id }, (data) => resolve(data), (data) => resolve(data));
        }),
      })));
      designerImages.filter((item) => !!item.image).forEach((item) => {
        newImages[item.id] = item.image;
      });
      setImages(newImages);
    };
    if (elements && !images) {
      loadImages();
    }
  }, [elements, images]);

  useEffect(() => {
    if (socket && documenttype) {
      const cleanupPagetypes = join(socket, setAlert, 'pagetypes', setPagetypes, { documenttype: documenttype.id });
      const cleanupPages = join(socket, setAlert, 'pages', setPages, { documenttype: documenttype.id });
      const cleanupElements = join(socket, setAlert, 'elements', setElements, { room: documenttype.id });
      return () => {
        cleanupPagetypes();
        cleanupPages();
        cleanupElements();
      };
    }
    return () => {};
  }, [socket, documenttype]);

  useEffect(
    socket && documenttype ? () => syn(socket, setAlert, 'pagetypes', pagetypes, setPagetypes, documenttype.id) : () => { },
    [socket, documenttype, pagetypes],
  );
  useEffect(
    socket && documenttype ? () => syn(socket, setAlert, 'pages', pages, setPages, documenttype.id) : () => { },
    [socket, documenttype, pages],
  );
  useEffect(
    socket && documenttype ? () => syn(socket, setAlert, 'elements', elements, setElements, documenttype.id) : () => { },
    [socket, documenttype, elements],
  );

  const onUpdateSuccess = (updatedDocument) => {
    onSetDocumenttypes(documenttypes.map((item) => (item.id === updatedDocument.id ? updatedDocument : item)));
  };
  const onUpdate = (id, field, value) => {
    emit(socket, setAlert, 'documenttypes.patch', { id, field, value }, onUpdateSuccess);
  };

  const onImagesUpload = (id, image) => {
    emit(socket, setAlert, 'images.upload', { id, image }, () => setImages({ ...images, [id]: image }));
  };

  const onCreatePagetypeSuccess = (newPagetype) => {
    setPagetypes(pagetypes ? [...pagetypes, newPagetype] : [newPagetype]);
  };
  const onCreatePagetype = () => {
    emit(socket, setAlert, 'pagetypes.post', { name: local.createPagetype, documenttype: documenttypeid }, onCreatePagetypeSuccess);
  };

  const onUpdatePagetypeSuccess = (updatedPagetype) => {
    setPagetypes(pagetypes.map((item) => (item.id === updatedPagetype.id ? updatedPagetype : item)));
  };
  const onUpdatePagetype = (id, field, value) => {
    emit(socket, setAlert, 'pagetypes.patch', { id, field, value }, onUpdatePagetypeSuccess);
  };

  const onDestroyPagetypeSuccess = (destroyPagetype) => {
    setPagetypes(pagetypes.filter((item) => (item.id !== destroyPagetype.id)));
  };
  const onDestroyPagetype = (id) => {
    emit(socket, setAlert, 'pagetypes.destroy', { id }, onDestroyPagetypeSuccess);
  };

  const onCreatePageSuccess = (newPage) => {
    setPages(pages ? [...pages, newPage] : [newPage]);
  };
  const onCreatePage = (pagetype) => {
    emit(socket, setAlert, 'pages.post', { name: local.createPage, documenttype: documenttype.id, pagetype }, onCreatePageSuccess);
  };

  const onUpdatePageSuccess = (updatedPage) => {
    setPages(pages.map((item) => (item.id === updatedPage.id ? updatedPage : item)));
  };
  const onUpdatePage = (id, field, value) => {
    emit(socket, setAlert, 'pages.patch', { id, field, value }, onUpdatePageSuccess);
  };

  const onDestroyPageSuccess = (destroyedPage) => {
    setPages(pages.filter((item) => item.id !== destroyedPage));
    navigate(`designer/documenttypes/${documenttype.id}`);
  };
  const onDestroyPage = (id) => {
    emit(socket, setAlert, 'pages.destroy', { id }, onDestroyPageSuccess);
  };

  const onCreateElementSuccess = (newElement) => {
    setElements(elements ? [...elements, newElement] : [newElement]);
  };
  const onCreateElement = (parent = documenttype.id, name = local.createElement) => {
    emit(socket, setAlert, 'elements.post', { room: documenttype.id, parent, name }, onCreateElementSuccess);
  };

  const onUpdateElementSuccess = (updatedElement) => {
    setElements(elements.map((item) => (item.id === updatedElement.id ? updatedElement : item)));
  };
  const onUpdateElement = (id, field, value) => {
    emit(socket, setAlert, 'elements.patch', { id, field, value }, onUpdateElementSuccess);
  };

  const onDestroyElementSuccess = (destroyedElement) => {
    setElements(elements.filter((item) => (item.id !== destroyedElement.id)));
  };
  const onDestroyElement = (id) => {
    emit(socket, setAlert, 'elements.destroy', { id }, onDestroyElementSuccess);
  };

  return (
    <Box>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      {documenttype && (
      <Routes>
        <Route
          path=":pageid"
          element={(
            <PageDesigner
              cities={cities}
              colors={colors}
              documenttype={documenttype}
              elements={elements}
              formats={formats}
              pages={pages}
              images={images}
              onUpdate={onUpdatePage}
              onDestroy={onDestroyPage}
              onCreateElement={onCreateElement}
              onUpdateElement={onUpdateElement}
              onDestroyElement={onDestroyElement}
              onImagesUpload={onImagesUpload}
              onBack={onBack}
              variables={variables}
            />
        )}
        />
        <Route
          exact
          path="/"
          element={(
            <Container sx={{ pt: 5 }}>
              <DocumenttypeEditor
                cities={cities}
                colors={colors}
                documenttype={documenttype}
                formats={formats}
                elements={elements}
                images={images}
                onUpdate={onUpdate}
                onCreateElement={onCreateElement}
                onUpdateElement={onUpdateElement}
                onDestroyElement={onDestroyElement}
                onImagesUpload={onImagesUpload}
                variables={variables}
              />
              { pagetypes && (
              <Box sx={{ mt: 2 }}>
                { pagetypes.map((pagetype) => (
                  <Pagetype
                    key={pagetype.id}
                    pagetype={pagetype}
                    onUpdate={onUpdatePagetype}
                    onDestroy={onDestroyPagetype}
                    pages={pages ? pages.filter((item) => item.pagetype === pagetype.id) : undefined}
                    onCreatePage={onCreatePage}
                    onPageClick={(item) => navigate(`/designer/documentcategories/${documentcategory.id}/${documenttypeid}/${item}`)}
                  />
                ))}
              </Box>
              )}
              <Box display="flex" justifyContent="flex-end" sx={{ mt: 2, mb: 2 }}>
                <Button sx={{ mr: 2 }} color="primary" variant="outlined" onClick={onBack}>{local.back}</Button>
                <Button color="primary" variant="contained" onClick={onCreatePagetype}>{local.createPagetype}</Button>
              </Box>
            </Container>
          )}
        />
      </Routes>
      )}
    </Box>
  );
}

Documenttype.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.shape({})),
  documentcategory: PropTypes.shape({
    id: PropTypes.number,
  }),
  documenttypes: PropTypes.arrayOf(PropTypes.shape({})),
  formats: PropTypes.arrayOf(PropTypes.shape({})),
  variables: PropTypes.arrayOf(PropTypes.shape({})),
  onBack: PropTypes.func.isRequired,
  onSetDocumenttypes: PropTypes.func.isRequired,
};

Documenttype.defaultProps = {
  colors: undefined,
  documentcategory: undefined,
  documenttypes: undefined,
  formats: undefined,
  variables: undefined,
};
