import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import emit from '../../../../util/emit';
import localization from './PrintersCreate.local';

export default function PrintersCreate({
  printers,
  onBack,
  onSetPrinters,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [template, setTemplate] = useState('');
  const [postcode, setPostcode] = useState('');
  const [city, setCity] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [offset, setOffset] = useState('');
  const [cropmarks, setCropmarks] = useState('');
  const [active, setActive] = useState(false);
  const [alert, setAlert] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    const printer = {
      name, email, template, postcode, city, street, number, active,
    };
    emit(socket, setAlert, 'printers.post', printer, (newPrinter) => {
      onSetPrinters([...printers, newPrinter]);
      onBack();
    });
  };

  return (
    <Container sx={{ pt: 5 }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Paper sx={{ p: 5 }}>
        <Box component="form" onSubmit={onSubmit}>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                variant="outlined"
                color="secondary"
                label={local.name}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                variant="outlined"
                color="secondary"
                label={local.email}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                variant="outlined"
                color="secondary"
                label={local.city}
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                variant="outlined"
                color="secondary"
                label={local.postcode}
                value={postcode}
                onChange={(e) => setPostcode(e.target.value)}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                variant="outlined"
                color="secondary"
                label={local.street}
                value={street}
                onChange={(e) => setStreet(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                variant="outlined"
                color="secondary"
                label={local.number}
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                variant="outlined"
                color="secondary"
                label={local.offset}
                value={offset}
                onChange={(e) => setOffset(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                multiline
                variant="outlined"
                color="secondary"
                label={local.template}
                value={template}
                onChange={(e) => setTemplate(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>{local.cropmarks}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Switch checked={cropmarks} onChange={(e) => setCropmarks(e.target.checked)} />
            </Grid>
            <Grid item xs={6}>
              <Typography>{local.active}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Switch checked={active} onChange={(e) => setActive(e.target.checked)} />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <Button fullWidth sx={{ marginTop: 2 }} color="primary" variant="outlined" onClick={onBack}>{local.back}</Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth sx={{ marginTop: 2 }} color="primary" variant="contained" type="submit">{local.create}</Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
}

PrintersCreate.propTypes = {
  printers: PropTypes.arrayOf(PropTypes.shape({})),
  onSetPrinters: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

PrintersCreate.defaultProps = {
  printers: undefined,
};
