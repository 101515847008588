export default {
  de: {
    name: 'Name',
    city: 'Gemeinde',
    description: 'Beschreibung',
    active: 'Aktiv',
    overlay: 'Overlay',
    destroy: 'Seitenvorlage löschen',
    newElement: 'Neues Element',
    back: 'Zurück',
    test: 'Testwerkzeug',
    offset: 'Anschnitt',
    cropmarks: 'Anschnittmarken',
    variables: 'Variablen',
  },
};
