import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
// import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

import DocumentEditorPageElementText from './DocumentEditorPageElementText';
import DocumentEditorPageElementImage from './DocumentEditorPageElementImage';

export default function DocumentEditorPageElement({
  documentImages,
  flexElementId,
  elements,
  element,
  page,
  onPatch,
  onPatchImage,
}) {
  const [open, setOpen] = useState(false);

  const onAddFlexElement = (clickedElement) => {
    const newFlexEntry = { flexElementId: uuidv4(), id: clickedElement.id };
    const patch = page.values && page.values[element.id] ? [...page.values[element.id], newFlexEntry] : [newFlexEntry];
    onPatch(element.id, patch);
  };

  const onFlexElementDelte = () => {
    const patch = page.values[element.parent].filter((flexElement) => flexElement.flexElementId !== element.flexElementId);
    onPatch(element.parent, patch);
  };

  const renderView = () => {
    const children = elements.filter((item) => item.parent === element.id && (['VIEW', 'ARTICLE', 'FLEXVIEW'].includes(item.type) || item.editable));
    return children.length > 0 ? (
      <Accordion sx={{}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{element.name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{element.description}</Typography>
          { children
            .sort((a, b) => moment(a.createdAt).diff(b.createdAt))
            .map((item) => (
              <DocumentEditorPageElement
                key={item.id}
                documentImages={documentImages}
                flexElementId={flexElementId}
                elements={elements}
                element={item}
                page={page}
                onPatch={onPatch}
                onPatchImage={onPatchImage}
              />
            ))}
          { ['FLEXELEMENT'].includes(element.type) && (
            <Button
              fullWidth
              variant="outlined"
              key={element.id}
              sx={{ mt: 1 }}
              onClick={onFlexElementDelte}
            >
              {`${element.name} löschen`}
            </Button>
          )}
        </AccordionDetails>
      </Accordion>
    ) : (
      <div />
    );
  };

  const renderFlex = () => {
    const flexElements = elements.filter((item) => item.parent === element.id && (['FLEXELEMENT'].includes(item.type)));
    let heightAvailable = parseFloat(element.dimension.height);
    let pageFlexElements = [];
    if (page.values && page.values[element.id]) {
      pageFlexElements = page.values[element.id].map((flexElement) => {
        const elementTemplate = flexElements.find((item) => item.id === flexElement.id);
        heightAvailable -= parseFloat(elementTemplate.dimension.height);
        return { ...elementTemplate, flexElementId: flexElement.flexElementId };
      });
    }
    return flexElements.length > 0 ? (
      <Accordion sx={{}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{element.name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{element.description}</Typography>
          { pageFlexElements && pageFlexElements.map((flexElement) => (
            <DocumentEditorPageElement
              key={flexElement.flexElementId}
              documentImages={documentImages}
              flexElementId={flexElement.flexElementId}
              elements={elements}
              element={flexElement}
              page={page}
              onPatch={onPatch}
              onPatchImage={onPatchImage}
            />
          ))}
          {flexElements.filter((item) => parseFloat(item.dimension.height) <= heightAvailable).map((item) => (
            <Button
              fullWidth
              variant="outlined"
              key={item.id}
              sx={{ mt: 1 }}
              onClick={() => onAddFlexElement(item)}
            >
              {`${item.name} hinzufügen`}
            </Button>
          ))}
        </AccordionDetails>
      </Accordion>
    ) : (
      <div />
    );
  };

  const renderElement = () => (
    <div>
      <Button fullWidth variant="contained" sx={{ mt: 1 }} onClick={() => setOpen(true)}>
        {/* { element.type === 'IMAGE' && documentImages && !documentImages[`${page.id}__${element.id}`] && (
          <CircularProgress color="warning" size={20} sx={{ mr: 1 }} />
        )} */}
        {element.name}
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Paper
          sx={{
            p: 4,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxHeight: '75%',
            width: '75%',
            overflow: 'scroll',
          }}
        >
          <Button sx={{ position: 'absolute', top: 5, right: 5 }} onClick={() => setOpen(false)}>
            <CloseIcon />
          </Button>
          { element.type === 'TEXT' && (
            <DocumentEditorPageElementText
              element={element}
              flexElementId={flexElementId}
              page={page}
              onPatch={onPatch}
            />
          )}
          { element.type === 'IMAGE' && (
            <DocumentEditorPageElementImage
              documentImages={documentImages}
              element={element}
              flexElementId={flexElementId}
              page={page}
              onPatch={onPatchImage}
            />
          )}
        </Paper>
      </Modal>
    </div>
  );

  const render = () => {
    if (['VIEW', 'ARTICLE', 'FLEXELEMENT'].includes(element.type)) {
      return renderView();
    } if (['FLEXVIEW'].includes(element.type)) {
      return renderFlex();
    }
    return renderElement();
  };

  return render();
}

DocumentEditorPageElement.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
    flexElementId: PropTypes.string,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
  flexElementId: PropTypes.string,
  page: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
  onPatch: PropTypes.func.isRequired,
};

DocumentEditorPageElement.defaultProps = {
  flexElementId: undefined,
};
