import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import emit from '../../../../util/emit';
import localization from './CitiesChange.local';

export default function CitiesChange({
  city,
  users,
  variables,
  onChange,
  onDestroy,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [name, setName] = useState(city.name);
  // const [avatar, setAvatar] = useState(city.avatar);
  const [cityVariables, setCityVariables] = useState(city.variables);
  const [editor] = useState(users.find((user) => user.id === city.editor));
  const [typingTimeout, setTypingTimeout] = useState();
  const [expanded, setExpanded] = useState(false);
  const [alert, setAlert] = useState(false);

  const onUpdate = (field, value) => {
    emit(socket, setAlert, 'cities.patch', { id: city.id, field, value }, (patchedCity) => onChange(patchedCity));
  };

  const onTextChange = (setter, field, value) => {
    setter(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => onUpdate(field, value), 1000));
  };

  return (
    <Accordion expanded={expanded} onChange={(e, state) => setExpanded(state)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {name}
      </AccordionSummary>
      <AccordionDetails>
        { expanded && (
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                color="secondary"
                margin="dense"
                label={local.name}
                value={name}
                onChange={(e) => onTextChange(setName, 'name', e.target.value.toUpperCase())}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={users}
                value={users.find((user) => user.id === city.manager)}
                onChange={(event, newValue) => onUpdate('manager', newValue ? newValue.id : null)}
                getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
                renderInput={(params) => (
                  <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    margin="dense"
                    label={local.manager}
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <Box
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                    component="li"
                    display="flex"
                    justify="center"
                    alignItems="center"
                    sx={{ width: 1, background: selected ? 'action.selected' : undefined }}
                  >
                    <Avatar src={option.avatar} alt={`${option.firstname} ${option.lastname}`} sx={{ width: 30, height: 30, mr: 1 }} />
                    <Typography>{`${option.firstname} ${option.lastname}`}</Typography>
                  </Box>
                )}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 1 }}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {local.variables}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    {variables && variables.map((variable) => (
                      <TextField
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        margin="dense"
                        key={variable.id}
                        label={variable.name}
                        value={cityVariables[variable.name]}
                        onChange={(e) => onTextChange(setCityVariables, 'variables', { ...cityVariables, [variable.name]: e.target.value })}
                      />
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid container item xs={12} sx={{ mt: 1, ml: 1 }}>
              <Grid item xs={6}>
                <Typography sx={{ mt: 1 }}>{local.createdAt}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ mt: 1 }}>{moment(city.createdAt).format('DD.MM.YYYY')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ mt: 1 }}>{local.updatedAt}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ mt: 1 }}>{moment(city.updatedAt).format('DD.MM.YYYY')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ mt: 1 }}>{local.editor}</Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                { editor && (
                  <Box display="flex" sx={{ mt: 1 }}>
                    <Avatar src={editor.avatar} alt={`${editor.firstname} ${editor.lastname}`} sx={{ width: 30, height: 30, mr: 1 }} />
                    <Typography>{`${editor.firstname} ${editor.lastname}`}</Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12} sx={{ marginTop: 5 }}>
              <Grid item xs={0} md={6} lg={8} />
              <Grid item xs={12} md={6} lg={4}>
                <Button fullWidth color="primary" variant="contained" onClick={onDestroy}>{local.destroy}</Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

CitiesChange.propTypes = {
  city: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    variables: PropTypes.shape({}),
    manager: PropTypes.number,
    editor: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  }).isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  variables: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
};
