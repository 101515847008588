import React from 'react';
import PropTypes from 'prop-types';
import { Routes, Route } from 'react-router-dom';

import Cities from './cities/Cities';
import Printers from './printers/Printers';
import Users from './users/Users';
import Variables from './variables/Variables';

export default function Organisation({
  printers,
  variables,
  onSetPrinters,
  onSetVariables,
}) {
  return (
    <Routes>
      <Route
        exact
        path="cities"
        element={(
          <Cities
            variables={variables}
          />
        )}
      />
      <Route exact path="users" element={<Users />} />
      <Route
        exact
        path="variables"
        element={(
          <Variables
            variables={variables}
            onSetVariables={onSetVariables}
          />
        )}
      />
      <Route
        exact
        path="printers/*"
        element={(
          <Printers
            printers={printers}
            onSetPrinters={onSetPrinters}
          />
        )}
      />
    </Routes>
  );
}

Organisation.propTypes = {
  printers: PropTypes.arrayOf(PropTypes.shape({})),
  variables: PropTypes.arrayOf(PropTypes.shape({})),
  onSetPrinters: PropTypes.func.isRequired,
  onSetVariables: PropTypes.func.isRequired,
};

Organisation.defaultProps = {
  printers: undefined,
  variables: undefined,
};
