import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';

import { LocalizationContext, SocketContext } from '../../../../../AppContext';
import Snackbar from '../../../../../components/Snackbar';
import emit from '../../../../../util/emit';
import localization from './DocumentcategoryCards.local';

export default function DocumentcategoryCards({
  documentcategories,
  onSetDocumentcategories,
  onClick,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [alert, setAlert] = useState();

  const onCreateDocumentcategorySuccess = (newDocumentcategory) => {
    onSetDocumentcategories([...documentcategories, newDocumentcategory]);
  };
  const onCreateDocumentcategory = () => {
    emit(socket, setAlert, 'documentcategories.post', { name: local.newName }, onCreateDocumentcategorySuccess);
  };

  return (
    <Container sx={{ pt: 5 }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Typography variant="h1" sx={{ mb: 2 }}>{local.title}</Typography>
      <Grid container spacing={2}>
        {documentcategories && documentcategories.map((item) => (
          <Grid key={item.id} item xs={6} md={4} lg={3}>
            <Card sx={{ width: 1, minHeight: 100 }} onClick={() => onClick(item.id)}>
              <CardActionArea sx={{ width: 1, minHeight: 100 }}>
                <CardContent>
                  <Chip
                    size="small"
                    sx={{ position: 'absolute', top: 5, right: 5 }}
                    label={item.active ? local.active : local.inactive}
                    color={item.active ? 'success' : 'secondary'}
                  />
                  <Typography variant="h6" sx={{ pt: 2 }}>{item.name}</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
        <Grid item xs={6} md={4} lg={3}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={onCreateDocumentcategory}
            sx={{ height: 100 }}
          >
            {local.create}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

DocumentcategoryCards.propTypes = {
  documentcategories: PropTypes.arrayOf(PropTypes.shape({})),
  onClick: PropTypes.func.isRequired,
  onSetDocumentcategories: PropTypes.func.isRequired,
};

DocumentcategoryCards.defaultProps = {
  documentcategories: undefined,
};
