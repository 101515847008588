export default {
  de: {
    name: 'Name',
    email: 'Email',
    city: 'Gemeinde',
    postcode: 'Postleitzahl',
    street: 'Straße',
    number: 'Hausnummer',
    offset: 'Schnitt in mm',
    template: 'Email Template',
    cropmarks: 'Schnittmarken',
    active: 'Aktiv',
    editor: ' zuletzt bearbeitet von',
    back: 'Zurück',
    destroy: ' Druckanbieter löschen',
  },
};
