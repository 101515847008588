export default (socket, setAlert, namespace, setList, params) => {
  socket.emit(`${namespace}.join`, params, (response) => {
    setAlert({ ...response, event: `${namespace}.join` });
    if (response.code < 300) {
      setList(response.payload);
    }
  });
  return () => {
    socket.emit(`${namespace}.leave`, params);
  };
};
