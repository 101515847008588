import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { PDFViewer } from '@react-pdf/renderer';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Autocomplete from '@mui/material/Autocomplete';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { LocalizationContext } from '../../../../../../AppContext';
import PDFDocument from '../../../../../../components/Document/Document';
import Designer from '../../../../../../components/Designer/Designer';
import localization from './OverlayDesigner.local';

export default function OverlayDesigner({
  documenttype,
  colors,
  cities,
  elements,
  formats,
  variables,
  onCreate,
  onUpdate,
  onImagesUpload,
  onDestroy,
  images,
}) {
  const local = localization[useContext(LocalizationContext)];
  const [offset, setOffset] = useState();
  const [cropmarks, setCropmarks] = useState();
  const [city, setCity] = useState();
  const [cityVariables, setCityVariables] = useState({});

  useEffect(() => {
    if (variables) {
      const newCityVariables = {};
      variables.forEach((item) => {
        newCityVariables[item.name] = cityVariables[item.name] || `%${item.name}%`;
      });
      setCityVariables(newCityVariables);
    }
  }, [variables]);

  const onCityChange = (value) => {
    setCity(value);
    setCityVariables(value.variables);
  };

  const generatePages = () => {
    const pages = [];
    for (let i = 0; i < documenttype.minPages; i += 1) {
      pages.push({ id: '1', type: 1 });
    }
    return pages;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Typography variant="h6">{local.overlay}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Accordion sx={{ mt: 2, mb: 2, backgroundColor: 'background.default' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{local.test}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  options={cities || null}
                  value={city || null}
                  onChange={(event, newValue) => onCityChange(newValue)}
                  getOptionLabel={(value) => value.name}
                  renderInput={(params) => (
                    <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      margin="dense"
                      label={local.city}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="number"
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.offset}
                  value={offset}
                  onChange={(e) => setOffset(e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography>{local.cropmarks}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Switch
                  checked={cropmarks}
                  onChange={(e) => setCropmarks(e.target.checked)}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>{local.variables}</Typography>
              </Grid>
              { variables && variables.map((variable) => (
                <Grid item key={variable.id} xs={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    margin="dense"
                    label={variable.name}
                    value={cityVariables[variable.name]}
                    onChange={(e) => setCityVariables({ ...cityVariables, [variable.name]: e.target.value })}
                  />
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={6}>
        { elements && elements
          .filter((element) => element.parent === documenttype.id)
          .sort((a, b) => moment(a.createdAt).diff(b.createdAt))
          .map((element) => (
            <Designer
              isOverlay
              key={element.id}
              cityId={city ? city.id : undefined}
              element={element}
              elements={elements}
              colors={colors}
              formats={formats}
              onCreate={onCreate}
              onUpdate={onUpdate}
              onImagesUpload={onImagesUpload}
              onDestroy={onDestroy}
              variables={variables}
            />
          ))}
        <Button fullWidth sx={{ mt: elements ? 2 : 0, mb: 2 }} color="primary" variant="outlined" onClick={() => onCreate()}>{local.newElement}</Button>
      </Grid>
      <Grid item xs={6}>
        <Box
          sx={{
            position: 'sticky', top: 60, height: '90vh', pr: 5,
          }}
        >
          { elements && (
            <PDFViewer width="100%" height="100%" style={{ border: 'none' }}>
              <PDFDocument
                colors={colors}
                title={document.name}
                orientation={documenttype.orientation}
                overlay={documenttype.id}
                elements={elements}
                variables={variables}
                designerImages={images}
                city={city}
                cityVariables={cityVariables}
                format={formats.find((item) => item.id === documenttype.format)}
                offset={offset}
                cropmarks={cropmarks}
                pages={generatePages()}
                pagedefinitions={[{ id: 1, overlay: true }]}
              />
            </PDFViewer>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

OverlayDesigner.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  documenttype: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    orientation: PropTypes.string.isRequired,
    minPages: PropTypes.number.isRequired,
  }).isRequired,
  elements: PropTypes.arrayOf(PropTypes.shape({})),
  formats: PropTypes.arrayOf(PropTypes.shape({})),
  colors: PropTypes.arrayOf(PropTypes.shape({})),
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onImagesUpload: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
  images: PropTypes.shape({}),
  variables: PropTypes.arrayOf(PropTypes.shape({})),
};

OverlayDesigner.defaultProps = {
  cities: undefined,
  elements: undefined,
  formats: undefined,
  colors: undefined,
  variables: undefined,
  images: undefined,
};
