import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { SocketContext, LocalizationContext, UserContext } from '../../../AppContext';
import Snackbar from '../../../components/Snackbar';
import emit from '../../../util/emit';
import localization from './DashboardAnnouncements.local';

export default function DashboardAnnouncements({ announcements, onSetAnnouncements }) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const user = useContext(UserContext);
  const [message, setMessage] = useState('');
  const [notify, setNotify] = useState(false);
  const [editors, setEditors] = useState();
  const [alert, setAlert] = useState();

  useEffect(() => {
    if (socket && announcements) {
      const ids = announcements.map(({ editor }) => editor);
      emit(socket, setAlert, 'users.getAllInIds', { ids }, (newEditors) => setEditors(newEditors));
    }
  }, [socket, announcements]);

  const onSubmit = (e) => {
    e.preventDefault();
    emit(socket, setAlert, 'announcements.post', { message, notify }, (newAnnouncement) => {
      onSetAnnouncements([...announcements, newAnnouncement]);
      setMessage('');
    });
  };

  return (
    <Paper sx={{ padding: 2, textAlign: 'center' }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Typography variant="h5" sx={{ mb: 2 }}>{local.announcements}</Typography>
      {announcements && editors ? (
        <List>
          {announcements.map((announcement) => {
            const editor = editors.find((item) => item.id === announcement.editor);
            return (
              <ListItem key={announcement.id}>
                <ListItemIcon><Avatar src={editor ? editor.avatar : undefined} /></ListItemIcon>
                <ListItemText secondary={`${editor ? `${editor.firstname} ${editor.lastname},` : ''} ${moment(announcement.createdAt).format('DD.MM.YYYY')}`}>
                  {announcement.message}
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
      ) : (
        <Box sx={{ height: 50 }}>
          <Typography>{local.noAnnouncements}</Typography>
        </Box>
      )}
      { user.admin && (
        <Box component="form" onSubmit={onSubmit} sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label={local.newAnnouncement}
            variant="outlined"
            color="secondary"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            multiline
            required
          />
          <FormControlLabel control={<Checkbox checked={notify} onChange={(e) => setNotify(e.target.checked)} />} label={local.notify} />
          <Button fullWidth sx={{ marginTop: 1 }} color="primary" variant="contained" type="submit">{local.submit}</Button>
        </Box>
      )}
    </Paper>
  );
}

DashboardAnnouncements.propTypes = {
  announcements: PropTypes.arrayOf(PropTypes.shape({})),
  onSetAnnouncements: PropTypes.func.isRequired,
};

DashboardAnnouncements.defaultProps = {
  announcements: undefined,
};
