export default function emit(socket, setAlert, event, params, onSuccess, onError) {
  socket.emit(event, params, (response) => {
    if (setAlert) {
      setAlert({ ...response, event });
    }
    if (response.code < 300 && onSuccess) {
      onSuccess(response.payload);
    } else if (onError) {
      onError(response.payload);
    }
  });
}
