export default {
  de: {
    messages: 'Support-Chat',
    noMessages: 'keine Nachricht vorhanden',
    newMessage: 'Neue Nachricht',
    submit: 'Senden',
    officetime: 'Bei Fragen, Hilfestellung oder Problemen bekommt ihr hier Unterstützung. (MO-FR: 08:00-16:00)',
    alerts: {
      'supports.post': {
        200: () => 'Nachricht gespeichert!',
      },
    },
  },
};
