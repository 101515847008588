import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import emit from '../../../../util/emit';
import CitiesContext from '../CitiesContext';
import localization from './InfoMessages.local';

export default function InfoMessages({
  cityMessages,
  messages,
  onSetMessages,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const city = useContext(CitiesContext);
  const [message, setMessage] = useState('');
  const [editors, setEditors] = useState();
  const [alert, setAlert] = useState();

  useEffect(() => {
    if (socket && cityMessages) {
      const ids = cityMessages.map(({ editor }) => editor);
      emit(socket, undefined, 'users.getAllInIds', { ids }, (newEditors) => setEditors(newEditors));
    }
  }, [socket, cityMessages]);

  const onSuccess = (newMessage) => {
    onSetMessages([...messages, newMessage]);
    setMessage('');
  };

  const onSubmit = (e) => {
    e.preventDefault();
    emit(socket, setAlert, 'messages.post', { message, city: city.id }, onSuccess);
  };

  return (
    <Paper sx={{ padding: 2, textAlign: 'center' }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Typography variant="h5" sx={{ mb: 2 }}>{local.messages}</Typography>
      {cityMessages && cityMessages.length > 0 && editors && editors.length > 0 ? (
        <List>
          {cityMessages.map((entry) => {
            const editor = editors.find((item) => item.id === entry.editor);
            return (
              <ListItem key={entry.id}>
                <ListItemIcon><Avatar src={editor.avatar} /></ListItemIcon>
                <ListItemText secondary={`${editor.firstname} ${editor.lastname}, ${moment(entry.createdAt).format('DD.MM.YYYY')}`}>
                  {entry.message}
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
      ) : (
        <Box sx={{ height: 50 }}>
          <Typography>{local.noMessages}</Typography>
        </Box>
      )}
      <Box component="form" onSubmit={onSubmit} sx={{ mt: 2 }}>
        <TextField
          fullWidth
          label={local.newMessage}
          variant="outlined"
          color="secondary"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          multiline
          required
        />
        <Button fullWidth sx={{ marginTop: 1 }} color="primary" variant="contained" type="submit">{local.submit}</Button>
      </Box>
    </Paper>
  );
}

InfoMessages.propTypes = {
  cityMessages: PropTypes.arrayOf(PropTypes.shape({})),
  messages: PropTypes.arrayOf(PropTypes.shape({})),
  onSetMessages: PropTypes.func.isRequired,
};

InfoMessages.defaultProps = {
  cityMessages: undefined,
  messages: undefined,
};
