export default {
  de: {
    firstname: 'Vorname',
    lastname: 'Nachname',
    email: 'Email',
    publisher: 'Redakteur',
    author: 'Autor',
    proofreader: ' Korrekturleser',
    createdAt: 'erstellt am',
    updatedAt: 'zuletzt bearbeitet am',
    editor: ' zuletzt bearbeitet von',
    destroy: ' Benutzer löschen',
    destroyRole: 'Benutzer entfernen',
    manager: 'Beauftragter',
    alerts: {
      'users.patchRole': {
        200: () => 'Änderung gespeichert!',
      },
      'users.destroyRole': {
        200: () => 'Änderung gespeichert!',
      },
      'users.patch': {
        200: () => 'Änderung gespeichert!',
      },
    },
  },
};
