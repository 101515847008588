import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import emit from '../../../../util/emit';
import localization from './ColorsParlette.local';

export default function ColorsParlette({
  colors,
  onClickColor,
  onSetColors,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [alert, setAlert] = useState();

  const onClickCreate = () => {
    const color = {
      name: local.newColor,
      hex: '#FFFFFF',
      cmyk: 'cmyk(0%, 0%, 0%, 0%)',
    };
    emit(socket, setAlert, 'colors.post', color, (newColor) => onSetColors([...colors, newColor]));
  };

  return (
    <Container sx={{ pt: 5 }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Typography variant="h1" sx={{ mb: 2 }}>{local.title}</Typography>
      <Grid container spacing={2}>
        {colors && colors.map((color) => (
          <Grid key={color.id} item xs={6} md={3} lg={2}>
            <Card sx={{ width: 1, minHeight: 80 }} onClick={() => onClickColor(color.id)}>
              <CardActionArea sx={{ width: 1, minHeight: 80, backgroundColor: color.hex }}>
                <CardContent>
                  <Chip
                    size="small"
                    sx={{ position: 'absolute', top: 5, right: 5 }}
                    label={color.active ? local.active : local.inactive}
                    color={color.active ? 'success' : 'secondary'}
                  />
                  <Typography variant="h6" sx={{ pt: 2 }}>{color.name}</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
        <Grid item xs={6} md={4} lg={2}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={onClickCreate}
            sx={{ height: 80 }}
          >
            {local.create}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

ColorsParlette.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.shape({})),
  onClickColor: PropTypes.func.isRequired,
  onSetColors: PropTypes.func.isRequired,
};

ColorsParlette.defaultProps = {
  colors: undefined,
};
