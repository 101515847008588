import React from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import PrintersParlette from './PrintersParlette';
import PrintersCreate from './PrintersCreate';
import PrintersEdit from './PrintersEdit';

export default function Printers({
  printers,
  onSetPrinters,
}) {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        exact
        path="create"
        element={(
          <PrintersCreate
            printers={printers}
            onBack={() => navigate('/organisation/printers')}
            onSetPrinters={onSetPrinters}
          />
        )}
      />
      <Route
        exact
        path=":printerid"
        element={(
          <PrintersEdit
            printers={printers}
            onSetPrinters={onSetPrinters}
            onBack={() => navigate('/organisation/printers')}
          />
        )}
      />
      <Route
        path="/"
        element={(
          <PrintersParlette
            printers={printers}
            onClickCreate={() => navigate('/organisation/printers/create')}
            onClick={(printerid) => navigate(`/organisation/printers/${printerid}`)}
          />
        )}
      />
    </Routes>
  );
}

Printers.propTypes = {
  printers: PropTypes.arrayOf(PropTypes.shape({})),
  onSetPrinters: PropTypes.func.isRequired,
};

Printers.defaultProps = {
  printers: undefined,
};
