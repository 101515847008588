export default {
  de: {
    title: 'Transformation',
    description: '',
    transformRotate: 'Rotation in Grad',
    transformScale: '',
    transformScaleX: '',
    transformScaleY: '',
    transformTranslate: '',
    transformTranslateX: '',
    transformTranslateY: '',
    transformMatrix: '',
    transformOrigin: '',
  },
};
