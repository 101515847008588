import React, {
  useContext, useState, useEffect, useRef,
} from 'react';
import {
  Routes, Route, useNavigate, useParams,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import { LocalizationContext, SocketContext, UserContext } from '../../../../../AppContext';
import Snackbar from '../../../../../components/Snackbar';
import emit from '../../../../../util/emit';
import emitSync from '../../../../../util/emitSync';
import join from '../../../../../util/join';
import CityContext from '../../CitiesContext';

import DocumentEditor from './DocumentEditor';
import DocumentPrinter from './DocumentPrinter';
import localization from './Document.local';

export default function Document({
  cityDocuments,
  colors,
  documents,
  documenttypes,
  formats,
  printers,
  variables,
  onSetDocuments,
  onBack,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const city = useContext(CityContext);
  const user = useContext(UserContext);
  const designerImages = useRef({});
  const documentImages = useRef({});
  const imageIds = useRef({});
  const navigate = useNavigate();
  const { documentid } = useParams();
  const [alert, setAlert] = useState();
  const [document, setDocument] = useState();
  const [documenttype, setDocumenttype] = useState();
  const [page, setPage] = useState();
  const [pagetypes, setPagetypes] = useState();
  const [pages, setPages] = useState();
  const [elements, setElements] = useState();
  // const [designerImages, setDesignerImages] = useState();
  const [imageLoading, setImageLoading] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (cityDocuments) {
      const result = cityDocuments.find(({ id }) => id === parseInt(documentid, 10));
      if (!result) {
        onBack();
      } else if (!_.isEqual(result, document)) {
        setDocument(result);
        const samePage = page ? result.pages.find((item) => item.id === page.id) : undefined;
        setPage(samePage || result.pages[0]);
      }
    }
  }, [cityDocuments, documentid]);

  useEffect(() => {
    if (documenttypes && document) {
      const result = documenttypes.find((item) => item.id === document.documenttype);
      if (!_.isEqual(result, documenttype)) {
        setDocumenttype(result);
      }
    }
  }, [documenttypes, document]);

  useEffect(() => {
    if (socket && documenttype) {
      const cleanupPagetypes = join(socket, setAlert, 'pagetypes', setPagetypes, { documenttype: documenttype.id });
      const cleanupPages = join(socket, setAlert, 'pages', setPages, { documenttype: documenttype.id });
      const cleanupElements = join(socket, setAlert, 'elements', setElements, { room: documenttype.id });
      return () => {
        cleanupPagetypes();
        cleanupPages();
        cleanupElements();
      };
    }
    return () => {};
  }, [socket, documenttype]);

  useEffect(() => {
    if (document && elements) {
      const loadImages = async () => {
        const images = [];
        images.push(...elements
          .filter((item) => !item.editable && item.type === 'IMAGE' && !documentImages.current[item.id])
          .map(({ id }) => ({ id, designer: true })));
        images.push(...elements
          .filter((item) => item.type === 'FLEXIMAGE' && !documentImages.current[item.id])
          .map(({ id }) => ({ id, flexid: `${id}--${city.id}`, designer: true })));
        document.pages.filter((item) => item.values).forEach((item) => {
          images.push(...Object.keys(item.values)
            .filter((key) => item.values[key] === `${item.id}__${key}` && !documentImages.current[`${item.id}__${key}`])
            .map((key) => ({ id: `${item.id}__${key}` })));
        });
        console.debug(images);
        for (let index = 0; index < images.length; index += 1) {
          setImageLoading({ index: index + 1, length: images.length });
          imageIds.current[images[index].id] = imageIds.current[images[index].id] || 'loading';
          console.debug(`loading image ${index + 1} / ${images.length} ${images[index].id}`);
          let image;
          if (images[index].flexid) {
            // eslint-disable-next-line no-await-in-loop
            image = await emitSync(socket, setAlert, 'images.download', { id: images[index].flexid });
          }
          if (!image) {
            // eslint-disable-next-line no-await-in-loop
            image = await emitSync(socket, setAlert, 'images.download', { id: images[index].id });
          }
          if (image) {
            if (images[index].designer) {
              designerImages.current[images[index].id] = image;
              imageIds.current[images[index].id] = 'found designer';
            } else {
              documentImages.current[images[index].id] = image;
              imageIds.current[images[index].id] = 'found document';
            }
          } else {
            imageIds.current[images[index].id] = 'not found';
          }
        }
        setImageLoading();
        console.debug('loading image done');
        console.debug(imageIds);
        console.debug(documentImages);
      };
      loadImages();
    }
  }, [elements, document]);
  // console.debug(`${item.id}__${key} emiting`);
  // documentImageIds.current[`${item.id}__${key}`] = documentImageIds.current[`${item.id}__${key}`] || 'loading';
  // const image = await emitSync(socket, setAlert, 'images.download', { id: `${item.id}__${key}` });
  // console.debug({ id: `${item.id}__${key}`, val: image });
  // if (image) {
  //   documentImages.current[`${item.id}__${key}`] = image;
  //   documentImageIds.current[`${item.id}__${key}`] = 'found';
  // } else {
  //   documentImageIds.current[`${item.id}__${key}`] = 'not found';
  // }
  // console.debug(`${item.id}__${key} emiting done`);

  const onPatch = (field, value) => {
    const onSuccess = (updated) => onSetDocuments(documents.map((item) => (item.id === updated.id ? updated : item)));
    emit(socket, setAlert, 'documents.patch', { id: document.id, field, value }, onSuccess);
  };

  const onPatchPage = (pageid, field, value) => {
    onPatch('pages', document.pages.map((item) => (item.id === pageid ? { ...item, [field]: value } : item)));
  };

  const onPatchPageImage = (pageid, field, value, imageid, image) => {
    emit(socket, setAlert, 'images.upload', { id: imageid, image }, () => {
      designerImages.current[imageid] = image;
      onPatch('pages', document.pages.map((item) => (item.id === pageid ? { ...item, [field]: value } : item)));
    });
  };

  const onAddPages = () => {
    const newPages = document.pages;
    for (let i = 0; i < documenttype.newPages; i += 1) {
      newPages.push({ id: uuid(), name: `${local.addPage} ${newPages.length + 1}` });
    }
    onPatch('pages', newPages);
  };
  const onRemovePages = () => {
    const newPages = document.pages;
    for (let i = 0; i < documenttype.newPages; i += 1) {
      newPages.pop();
    }
    onPatch('pages', newPages);
  };

  const onSend = async (blob, printer, printouts) => {
    const buffer = await blob.arrayBuffer();
    const html = printer.template
      .replace('%VORNAME%', user.firstname)
      .replace('%NACHNAME%', user.lastname)
      .replace('%GEMEINDE%', city.name)
      .replace('%ANZAHL%', printouts)
      .replace('%EMAIL%', user.email);
    const order = {
      subject: `KOMMKO - ${city.name} - ${document.name}`,
      to: printer.email ? [printer.email, user.email] : [user.email],
      html,
      filename: `${document.name}.pdf`,
      content: buffer,
      cmyk: printer.id === 0 ? printer.cmyk : true,
      printouts,
      customPrint: printer.id === 0,
    };
    setLoading(true);
    emit(socket, setAlert, 'printers.sendOrder', order, () => setLoading(false), () => setLoading(false));
  };

  return (
    <div>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      { loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      { imageLoading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={!!imageLoading}
        >
          <Box sx={{ width: '200px' }}>
            <LinearProgress color="primary" variant="determinate" value={Math.round((imageLoading.index / imageLoading.length) * 100)} />
            <Typography component="div" variant="caption" fontWeight={900} sx={{ width: '100%', textAlign: 'center' }}>
              {`Lade Bild ${imageLoading.index} von ${imageLoading.length}`}
            </Typography>
          </Box>
        </Backdrop>
      )}
      <Routes>
        <Route
          path="print"
          element={(
            <DocumentPrinter
              colors={colors}
              cityVariables={city ? city.variables : undefined}
              designerImages={designerImages.current}
              document={document}
              documentImages={documentImages.current}
              documenttype={documenttype}
              elements={elements}
              formats={formats}
              pagedefinitions={pages}
              printers={printers}
              onBack={() => navigate('./')}
              onSend={onSend}
              variables={variables}
            />
          )}
        />
        <Route
          path="/"
          element={(
            <DocumentEditor
              colors={colors}
              city={city}
              designerImages={designerImages.current}
              document={document}
              documentImages={documentImages.current}
              documenttype={documenttype}
              elements={elements}
              formats={formats}
              page={page}
              pagedefinitions={pages}
              pagetypes={pagetypes}
              onAddPages={onAddPages}
              onBack={onBack}
              onPatch={onPatch}
              onPatchPage={onPatchPage}
              onPatchPageImage={onPatchPageImage}
              onPrint={() => navigate('print')}
              onRemovePages={onRemovePages}
              onSetPage={setPage}
              variables={variables}
            />
          )}
        />
      </Routes>
    </div>
  );
}

Document.propTypes = {
  cityDocuments: PropTypes.arrayOf(PropTypes.shape({})),
  colors: PropTypes.arrayOf(PropTypes.shape({})),
  documents: PropTypes.arrayOf(PropTypes.shape({})),
  documenttypes: PropTypes.arrayOf(PropTypes.shape({})),
  formats: PropTypes.arrayOf(PropTypes.shape({})),
  printers: PropTypes.arrayOf(PropTypes.shape({})),
  variables: PropTypes.arrayOf(PropTypes.shape({})),
  onSetDocuments: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

Document.defaultProps = {
  cityDocuments: undefined,
  colors: undefined,
  documents: undefined,
  documenttypes: undefined,
  formats: undefined,
  printers: undefined,
  variables: undefined,
};
