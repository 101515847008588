import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import Switch from '@mui/material/Switch';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { LocalizationContext } from '../../AppContext';
import localization from './DesignerLayout.local';

export default function DesignerLayout({ values, onUpdate, onTextUpdate }) {
  const local = localization[useContext(LocalizationContext)];

  const positionOptions = [
    { label: local.absolute, value: 'absolute' },
    { label: local.relative, value: 'relative' },
  ];

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{local.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              options={positionOptions}
              value={positionOptions.find((option) => option.value === values.position)}
              onChange={(event, newValue) => onUpdate('position', newValue ? newValue.value : undefined)}
              renderInput={(params) => (
                <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.position}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.zIndex}
              value={values.zIndex}
              onChange={(e) => onTextUpdate('zIndex', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.left}
              value={values.left}
              onChange={(e) => onTextUpdate('left', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12}>
              {local.offset}
            </Grid>
            <Grid item xs={12}>
              <Switch
                checked={values.leftoffset}
                onChange={(e) => onUpdate('leftoffset', e.target.checked)}
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.top}
              value={values.top}
              onChange={(e) => onTextUpdate('top', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12}>
              {local.offset}
            </Grid>
            <Grid item xs={12}>
              <Switch
                checked={values.topoffset}
                onChange={(e) => onUpdate('topoffset', e.target.checked)}
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.right}
              value={values.right}
              onChange={(e) => onTextUpdate('right', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12}>
              {local.offset}
            </Grid>
            <Grid item xs={12}>
              <Switch
                checked={values.rightoffset}
                onChange={(e) => onUpdate('rightoffset', e.target.checked)}
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.bottom}
              value={values.bottom}
              onChange={(e) => onTextUpdate('bottom', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12}>
              {local.offset}
            </Grid>
            <Grid item xs={12}>
              <Switch
                checked={values.bottomoffset}
                onChange={(e) => onUpdate('bottomoffset', e.target.checked)}
              />
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
DesignerLayout.propTypes = {
  values: PropTypes.shape({
    display: PropTypes.string,
    overflow: PropTypes.string,
    zIndex: PropTypes.string,
    position: PropTypes.string,
    top: PropTypes.string,
    right: PropTypes.string,
    bottom: PropTypes.string,
    left: PropTypes.string,
    topoffset: PropTypes.bool,
    rightoffset: PropTypes.bool,
    bottomoffset: PropTypes.bool,
    leftoffset: PropTypes.bool,
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onTextUpdate: PropTypes.func.isRequired,
};
