export default {
  de: {
    addPage: 'Seite',
    alerts: {
      'printers.sendOrder': {
        200: (payload) => (payload.customPrint
          ? 'Das Dokument wird ihnen für den Eigendruck per Email zugesendet!'
          : `Eine Bestellanfrage über ${payload.printouts} Stück wurde an den Druckanbieter gesendet. Sie erhalten eine Kopie der Email!`),
      },
      'documents.patch': {
        200: () => 'Änderungen gespeichert!',
      },
    },
  },
};
