import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import { LocalizationContext } from '../../../../../AppContext';

import localization from './DocumentPrinterEditor.local';

export default function DocumentPrinterEditor({
  printers,
  printer,
  printouts,
  onSetPrinter,
  onSetPrintouts,
}) {
  const local = localization[useContext(LocalizationContext)];
  const [cmyk, setCmyk] = useState();
  const [cropmarks, setCropmarks] = useState();
  const [offset, setOffset] = useState();
  const customprinter = {
    id: 0,
    name: local.customprinter,
    template: local.customprinter,
  };

  const onChangeCmyk = (value) => {
    setCmyk(value);
    onSetPrinter({
      ...customprinter, cmyk: value, cropmarks, offset,
    });
  };

  const onChangeCropmarks = (value) => {
    setCropmarks(value);
    onSetPrinter({
      ...customprinter, cmyk, cropmarks: value, offset,
    });
  };

  const onChangeOffset = (value) => {
    setOffset(value);
    onSetPrinter({
      ...customprinter, cmyk, cropmarks, offset: value,
    });
  };

  return (
    <Paper sx={{ p: 1, mt: 1 }}>
      <Grid container spacing={1} sx={{ p: 1 }}>
        <Grid item xs={12}>
          <Autocomplete
            options={printers ? [...printers, {
              ...customprinter, cmyk, cropmarks, offset,
            }] : [{
              ...customprinter, cmyk, cropmarks, offset,
            }]}
            value={printer}
            onChange={(event, newValue) => onSetPrinter(newValue)}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                fullWidth
                variant="outlined"
                color="secondary"
                margin="dense"
                label={local.printer}
              />
            )}
          />
        </Grid>
        {printer && printer.id !== customprinter.id && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.printouts}
              value={printouts}
              onChange={(e) => onSetPrintouts(e.target.value)}
            />
          </Grid>
        )}
        {printer && printer.id === customprinter.id && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.offset}
              value={offset}
              onChange={(e) => onChangeOffset(e.target.value)}
            />
          </Grid>
        )}
        {printer && printer.id === customprinter.id && (
          <Grid container item xs={12} sx={{ p: 2 }}>
            <Grid item xs={8}>
              <Typography sx={{ mt: 1, ml: 1 }}>{local.cropmarks}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Switch checked={cropmarks} onChange={(e) => onChangeCropmarks(e.target.checked)} />
            </Grid>
          </Grid>
        )}
        {printer && printer.id === customprinter.id && (
          <Grid container item xs={12} sx={{ p: 2 }}>
            <Grid item xs={8}>
              <Typography sx={{ mt: 1, ml: 1 }}>{local.cmyk}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Switch checked={cmyk} onChange={(e) => onChangeCmyk(e.target.checked)} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}

DocumentPrinterEditor.propTypes = {
  printers: PropTypes.arrayOf(PropTypes.shape({})),
  printer: PropTypes.shape({
    id: PropTypes.number,
  }),
  printouts: PropTypes.number.isRequired,
  onSetPrinter: PropTypes.func.isRequired,
  onSetPrintouts: PropTypes.func.isRequired,
};

DocumentPrinterEditor.defaultProps = {
  printers: undefined,
  printer: null,
};
