export default {
  de: {
    documenttype: (name) => `Dokumenttyp "${name}"`,
    back: 'Zurück',
    name: 'Name',
    description: 'Beschreibung',
    format: 'Format',
    width: 'Breite',
    height: 'Höhe',
    orientation: 'Querformat',
    minPages: 'Mindest Seitenanzahl',
    newPages: 'Seitenanzahl Interval',
    maxPages: 'Maximale Seitenanzahl (0 = unbegrenzt)',
    createElement: 'Neues Element',
    createPagetype: 'Neue Seitenkategorie',
    createPage: 'Neue Seitenvorlage',
    active: 'aktiv',
    inactive: 'inaktiv',
  },
};
