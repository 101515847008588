import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
// import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import emit from '../../../../util/emit';
import localization from './UsersChange.local';

export default function UsersChange({
  city,
  role,
  user,
  onUpdate,
  onUpdateRole,
  onDestroyRole,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [firstname, setFirstname] = useState(user.firstname);
  const [lastname, setLastname] = useState(user.lastname);
  const [email, setEmail] = useState(user.email);
  // const [editor] = useState(users.find(({ id }) => id === user.editor));
  const [expanded, setExpanded] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState();
  const [alert, setAlert] = useState();

  const onChangeRole = (field, value) => {
    const params = {
      city: role.city,
      user: role.user,
      field,
      value,
    };
    emit(socket, setAlert, 'users.patchRole', params, (updatedRole) => onUpdateRole(updatedRole));
  };

  const onDeleteRole = () => emit(socket, setAlert, 'users.destroyRole', { user: role.user, city: role.city }, (destroyedRole) => onDestroyRole(destroyedRole));
  const onChange = (field, value) => emit(socket, setAlert, 'users.patch', { id: user.id, field, value }, (updatedUser) => onUpdate(updatedUser));

  const onTextChange = (setter, field, value) => {
    setter(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => onChange(field, value), 1000));
  };

  return (
    <Accordion expanded={expanded} onChange={(e, state) => setExpanded(state)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {`${firstname} ${lastname}`}
      </AccordionSummary>
      <AccordionDetails>
        {expanded && (
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                color="secondary"
                margin="dense"
                label={local.firstname}
                value={firstname}
                onChange={(e) => onTextChange(setFirstname, 'firstname', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                color="secondary"
                margin="dense"
                label={local.lastname}
                value={lastname}
                onChange={(e) => onTextChange(setLastname, 'lastname', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                variant="outlined"
                color="secondary"
                margin="dense"
                label={local.email}
                value={email}
                onChange={(e) => onTextChange(setEmail, 'email', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>{local.publisher}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Switch
                disabled={city.manager === user.id}
                checked={city.manager === user.id || role.publisher}
                onChange={(e) => onChangeRole('publisher', e.target.checked)}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>{local.author}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Switch
                disabled={city.manager === user.id}
                checked={city.manager === user.id || role.author}
                onChange={(e) => onChangeRole('author', e.target.checked)}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>{local.proofreader}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Switch
                disabled={city.manager === user.id}
                checked={city.manager === user.id || role.proofreader}
                onChange={(e) => onChangeRole('proofreader', e.target.checked)}
              />
            </Grid>
            { city.manager === user.id && (
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography>{local.manager}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{city.name}</Typography>
                </Grid>
              </Grid>
            )}
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography>{local.createdAt}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{moment(user.createdAt).format('DD.MM.YYYY')}</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography>{local.updatedAt}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{moment(user.updatedAt).format('DD.MM.YYYY')}</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography>{local.editor}</Typography>
              </Grid>
              <Grid item xs={6}>
                {/* { editor ? (
                  <Box display="flex" sx={{ mt: 1 }}>
                    <Avatar src={editor.avatar} alt={`${editor.firstname} ${editor.lastname}`} sx={{ width: 30, height: 30, mr: 1 }} />
                    <Typography>{`${editor.firstname} ${editor.lastname}`}</Typography>
                  </Box>
                ) : (
                  <Box display="flex" sx={{ mt: 1 }}>
                    <CircularProgress />
                  </Box>
                )} */}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  onClick={() => onDeleteRole(role)}
                  disabled={city.manager === user.id}
                >
                  {local.destroyRole}

                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

UsersChange.propTypes = {
  city: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    manager: PropTypes.number,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    editor: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  }).isRequired,
  role: PropTypes.shape({
    city: PropTypes.number,
    user: PropTypes.number,
    publisher: PropTypes.bool,
    author: PropTypes.bool,
    proofreader: PropTypes.bool,
  }),
  onUpdate: PropTypes.func.isRequired,
  onUpdateRole: PropTypes.func.isRequired,
  onDestroyRole: PropTypes.func.isRequired,
};

UsersChange.defaultProps = {
  role: { },
};
