import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { PDFViewer } from '@react-pdf/renderer';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { LocalizationContext } from '../../../../../../AppContext';
import PDFDocument from '../../../../../../components/Document/Document';
import Designer from '../../../../../../components/Designer/Designer';
import localization from './PageDesigner.local';

export default function PageDesigner({
  cities,
  documenttype,
  elements,
  colors,
  formats,
  pages,
  variables,
  onUpdate,
  onDestroy,
  onCreateElement,
  onUpdateElement,
  onImagesUpload,
  onDestroyElement,
  onBack,
  images,
}) {
  const local = localization[useContext(LocalizationContext)];
  const { pageid } = useParams();
  const [page, setPage] = useState();
  const [cropmarks, setCropmarks] = useState();
  const [offset, setOffset] = useState();
  const [city, setCity] = useState();
  const [cityVariables, setCityVariables] = useState({});
  const [typingTimeout, setTypingTimeout] = useState();

  useEffect(() => {
    if (pages) {
      const foundPage = pages.find((item) => item.id === pageid);
      if (foundPage) {
        setPage(foundPage);
      } else {
        onBack();
      }
    }
  }, [pages, pageid]);

  useEffect(() => {
    if (variables) {
      const newCityVariables = {};
      variables.forEach((item) => {
        newCityVariables[item.name] = cityVariables[item.name] || `%${item.name}%`;
      });
      setCityVariables(newCityVariables);
    }
  }, [variables]);

  const onTextChange = (field, value) => {
    setPage({ ...page, [field]: value });
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => onUpdate(page.id, field, value), 1000),
    );
  };

  const onCityChange = (value) => {
    setCity(value);
    setCityVariables(value.variables);
  };

  return page ? (
    <Container maxWidth={false}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, backgroundColor: 'background.default' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Typography variant="h6">{page.name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.name}
                  value={page.name}
                  onChange={(e) => onTextChange('name', e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.description}
                  value={page.description}
                  onChange={(e) => onTextChange('description', e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  label={local.active}
                  sx={{ m: 2 }}
                  control={<Switch checked={page.active} onChange={(e) => onUpdate(page.id, 'active', e.target.checked)} />}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  label={local.overlay}
                  sx={{ m: 2 }}
                  control={<Switch checked={page.overlay} onChange={(e) => onUpdate(page.id, 'overlay', e.target.checked)} />}
                />
              </Grid>
              <Grid item xs={12}>
                <Accordion sx={{ mt: 2, mb: 2, backgroundColor: 'background.default' }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{local.test}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item xs={12}>
                      <Autocomplete
                        options={cities || null}
                        value={city || null}
                        onChange={(event, newValue) => onCityChange(newValue)}
                        getOptionLabel={(value) => value.name}
                        renderInput={(params) => (
                          <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            fullWidth
                            variant="outlined"
                            color="secondary"
                            margin="dense"
                            label={local.city}
                          />
                        )}
                      />
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          type="number"
                          variant="outlined"
                          color="secondary"
                          margin="dense"
                          label={local.offset}
                          value={offset}
                          onChange={(e) => setOffset(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>{local.cropmarks}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Switch
                          checked={cropmarks}
                          onChange={(e) => setCropmarks(e.target.checked)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>{local.variables}</Typography>
                      </Grid>
                      { variables && variables.map((variable) => (
                        <Grid item key={variable.id} xs={6}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            color="secondary"
                            margin="dense"
                            label={variable.name}
                            value={cityVariables[variable.name]}
                            onChange={(e) => setCityVariables({ ...cityVariables, [variable.name]: e.target.value })}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                { elements && elements
                  .filter((element) => element.parent === page.id)
                  .sort((a, b) => moment(a.createdAt).diff(b.createdAt))
                  .map((element) => (
                    <Designer
                      key={element.id}
                      city={city}
                      element={element}
                      elements={elements}
                      colors={colors}
                      variables={variables}
                      onCreate={onCreateElement}
                      onUpdate={onUpdateElement}
                      onImagesUpload={onImagesUpload}
                      onDestroy={onDestroyElement}
                    />
                  ))}
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  sx={{ mt: elements ? 2 : 0, mb: 2 }}
                  color="primary"
                  variant="contained"
                  onClick={() => onCreateElement(page.id)}
                >
                  {local.newElement}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  color="primary"
                  variant="outlined"
                  disabled={elements && elements.filter((element) => element.parent === page.id).length > 0}
                  onClick={() => onDestroy(page.id)}
                >
                  {local.destroy}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  sx={{ mr: 2 }}
                  color="primary"
                  variant="outlined"
                  onClick={onBack}
                >
                  {local.back}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{
            position: 'sticky', top: 60, height: '90vh', pr: 5,
          }}
          >
            { elements && (
              <PDFViewer width="100%" height="100%" style={{ border: 'none' }}>
                <PDFDocument
                  colors={colors}
                  title={documenttype.name}
                  orientation={documenttype.orientation}
                  overlay={documenttype.id}
                  elements={elements}
                  variables={variables}
                  designerImages={images}
                  cityId={city ? city.id : undefined}
                  cityVariables={cityVariables}
                  format={formats.find((item) => item.id === documenttype.format)}
                  offset={offset}
                  cropmarks={cropmarks}
                  pages={[{ id: 0, type: page.id }]}
                  pagedefinitions={[page]}
                />
              </PDFViewer>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  ) : (<div />);
}

PageDesigner.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  documenttype: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    orientation: PropTypes.string.isRequired,
  }).isRequired,
  pages: PropTypes.arrayOf(PropTypes.shape({})),
  elements: PropTypes.arrayOf(PropTypes.shape({})),
  colors: PropTypes.arrayOf(PropTypes.shape({})),
  formats: PropTypes.arrayOf(PropTypes.shape({})),
  onUpdate: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
  onCreateElement: PropTypes.func.isRequired,
  onUpdateElement: PropTypes.func.isRequired,
  onImagesUpload: PropTypes.func.isRequired,
  onDestroyElement: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  images: PropTypes.shape({}),
  variables: PropTypes.arrayOf(PropTypes.shape({})),
};

PageDesigner.defaultProps = {
  cities: undefined,
  pages: undefined,
  elements: undefined,
  formats: undefined,
  variables: undefined,
  colors: undefined,
  images: undefined,
};
