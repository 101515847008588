export default {
  de: {
    title: 'Text',
    description: '',
    fontSize: 'Schriftgröße in pt',
    fontFamily: 'Schriftfamilie',
    fontStyle: 'Stil',
    fontWeight: 'Schriftstärke',
    letterSpacing: 'Zeichenabstand in pt',
    lineHeight: 'Zeilenhöhe in pt',
    maxLines: 'max. Zeilen',
    textAlign: 'Ausrichtung',
    textDecoration: 'Dekorationen',
    underline: 'Unterstrichen',
    lineThrough: 'Durchgestrichen',
    textDecorationColor: 'Dekorationen Farbe',
    textDecorationStyle: 'Dekorationen Stil',
    uppercase: 'Großbuchstaben',
    lowercase: 'Kleinbuchstaben',
    textIndent: 'Einrückung der ersten Zeile in mm',
    textOverflow: 'Überfluss',
    textTransform: 'Groß- / Kleinbuchstaben',
    left: 'Linksbündig',
    right: 'Rechtsbündig',
    center: 'Zentriert',
    justify: 'Blocksatz',
    roboto: 'Roboto',
    robotocondensed: 'Roboto Condensed',
    robotoslab: 'Roboto Slab',
    thin: 'Thin',
    ultralight: 'Ultrathin',
    light: 'Light',
    normal: 'Normal',
    medium: 'Medium',
    semibold: 'Semibold',
    bold: 'Bold',
    ultrabold: 'Ultrabold',
    heavy: 'Heavy',
    italic: 'Kursiv',
  },
};
