import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';

import { LocalizationContext, SocketContext, UserContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import emit from '../../../../util/emit';
import localization from './Support.local';

export default function Support({ supports, onSetSupports }) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const user = useContext(UserContext);
  const [message, setMessage] = useState('');
  const [editors, setEditors] = useState();
  const [customer, setCustomer] = useState(null);
  const [alert, setAlert] = useState();

  useEffect(() => {
    if (socket && supports) {
      const ids = supports.map(({ editor }) => editor);
      emit(socket, setAlert, 'users.getAllInIds', { ids }, (newEditors) => setEditors(newEditors));
    }
  }, [socket, supports]);

  const onSubmit = (e) => {
    e.preventDefault();
    emit(socket, setAlert, 'supports.post', { message, user: customer ? customer.id : user.id }, (newSupport) => {
      onSetSupports([...supports, newSupport]);
      setMessage('');
    });
  };

  return (
    <Paper sx={{ padding: 2, textAlign: 'center' }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Typography variant="h5" sx={{ mb: 2 }}>{local.messages}</Typography>
      <Typography sx={{ mb: 2 }}>{local.officetime}</Typography>
      { user.admin && editors && (
        <Autocomplete
          options={editors}
          value={customer}
          onChange={(event, newValue) => setCustomer(newValue || null)}
          getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
          renderInput={(params) => (
            <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              fullWidth
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.customer}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <Box
            // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              component="li"
              display="flex"
              justify="center"
              alignItems="center"
              sx={{ width: 1, background: selected ? 'action.selected' : undefined }}
            >
              <Avatar src={option.avatar} alt={`${option.firstname} ${option.lastname}`} sx={{ width: 30, height: 30, mr: 1 }} />
              <Typography>{`${option.firstname} ${option.lastname}`}</Typography>
            </Box>
          )}
        />
      )}
      {supports && editors ? (
        <List>
          {supports
            .filter((item) => (customer ? customer.id === item.user : true))
            .map((support) => {
              const editor = editors.find((item) => item.id === support.editor);
              return (
                <ListItem key={support.id}>
                  <ListItemIcon><Avatar src={editor ? editor.avatar : undefined} /></ListItemIcon>
                  <ListItemText secondary={`${editor ? `${editor.firstname} ${editor.lastname},` : ''} ${moment(support.createdAt).format('DD.MM.YYYY')}`}>
                    {support.message}
                  </ListItemText>
                </ListItem>
              );
            })}
        </List>
      ) : (
        <Box sx={{ height: 50 }}>
          <Typography>{local.noMessages}</Typography>
        </Box>
      )}
      <Box component="form" onSubmit={onSubmit} sx={{ mt: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          color="secondary"
          label={local.newMessage}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          multiline
          required
        />
        <Button fullWidth sx={{ marginTop: 1 }} color="primary" variant="contained" type="submit">{local.submit}</Button>
      </Box>
    </Paper>
  );
}

Support.propTypes = {
  supports: PropTypes.arrayOf(PropTypes.shape({})),
  onSetSupports: PropTypes.func.isRequired,
};

Support.defaultProps = {
  supports: undefined,
};
