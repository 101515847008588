import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import ImageUploader from '../../../../../components/ImageUploader';

const MM_IN_PX = 3.7795275591;

export default function DocumentEditorPageElementImage({
  documentImages,
  element,
  flexElementId,
  page,
  onPatch,
}) {
  const getSrc = () => {
    const id = flexElementId ? `${page.id}__${flexElementId}--${element.id}` : `${page.id}__${element.id}`;
    if (documentImages[id]) {
      const arrayBufferView = new Uint8Array(documentImages[id]);
      const blob = new Blob([arrayBufferView], { type: 'image/jpeg' });
      const urlCreator = window.URL || window.webkitURL;
      return urlCreator.createObjectURL(blob);
    }
    return `${process.env.PUBLIC_URL}/placeholder.jpg`;
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h2">{element.name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption">{element.description}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Box
          sx={{
            width: element.dimension && element.dimension.width ? `${element.dimension.width * MM_IN_PX}` : '400px',
            height: element.dimension && element.dimension.height ? `${element.dimension.height * MM_IN_PX}` : '300px',
            maxWidth: '600px',
            maxHeight: '450px',
          }}
        >
          <img
            alt=""
            src={getSrc()}
            width="100%"
            height="100%"
          />
        </Box>
      </Grid>

      <Grid item xs={6}>
        <ImageUploader
          label="Neues Bild hochladen"
          id={flexElementId ? `${page.id}__${flexElementId}--${element.id}` : `${page.id}__${element.id}`}
          // onError={(error) => setAlert(error)}
          onReturn={(image) => onPatch(
            flexElementId ? `${flexElementId}--${element.id}` : element.id,
            flexElementId ? `${page.id}__${flexElementId}--${element.id}` : `${page.id}__${element.id}`,
            image,
          )}
          aspect={
            element.dimension && element.dimension.height && element.dimension.width
              ? (parseFloat(element.dimension.width) / parseFloat(element.dimension.height))
              : undefined
          }
        />
      </Grid>
    </Grid>
  );
}

DocumentEditorPageElementImage.propTypes = {
  documentImages: PropTypes.shape({}),
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    dimension: PropTypes.shape({
      height: PropTypes.string,
      width: PropTypes.string,
    }),
  }).isRequired,
  flexElementId: PropTypes.string,
  page: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  onPatch: PropTypes.func.isRequired,
};

DocumentEditorPageElementImage.defaultProps = {
  documentImages: {},
  flexElementId: undefined,
};
