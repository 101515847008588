import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import DataObjectIcon from '@mui/icons-material/DataObject';
import PrintIcon from '@mui/icons-material/Print';

import { LocalizationContext } from '../../../AppContext';
import localization from './OrganisationMenu.local';

export default function OrganisationMenu() {
  const { pathname } = useLocation();
  const local = localization[useContext(LocalizationContext)];
  const [open, setOpen] = useState(pathname.includes('/organisation'));

  const getBackgroundColor = (path) => (pathname.includes(path) ? 'action.selected' : 'primary.main');

  return (
    <List disablePadding>
      <ListItem button onClick={() => setOpen(!open)}>
        <ListItemIcon sx={{ color: 'primary.contrastText', minWidth: 35 }}><AccountBalanceIcon /></ListItemIcon>
        <ListItemText>{local.organisation}</ListItemText>
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      { open && (
        <List disablePadding>
          <ListItem button to="/organisation/cities" component={Link} sx={{ pl: 4, backgroundColor: getBackgroundColor('/organisation/cities') }}>
            <ListItemIcon sx={{ color: 'primary.contrastText', minWidth: 35 }}><HomeIcon /></ListItemIcon>
            <ListItemText>{local.cities}</ListItemText>
          </ListItem>
          <ListItem button to="/organisation/users" component={Link} sx={{ pl: 4, backgroundColor: getBackgroundColor('/organisation/users') }}>
            <ListItemIcon sx={{ color: 'primary.contrastText', minWidth: 35 }}><PeopleIcon /></ListItemIcon>
            <ListItemText>{local.users}</ListItemText>
          </ListItem>
          <ListItem button to="/organisation/variables" component={Link} sx={{ pl: 4, backgroundColor: getBackgroundColor('/organisation/variables') }}>
            <ListItemIcon sx={{ color: 'primary.contrastText', minWidth: 35 }}><DataObjectIcon /></ListItemIcon>
            <ListItemText>{local.variables}</ListItemText>
          </ListItem>
          <ListItem button to="/organisation/printers" component={Link} sx={{ pl: 4, backgroundColor: getBackgroundColor('/organisation/printers') }}>
            <ListItemIcon sx={{ color: 'primary.contrastText', minWidth: 35 }}><PrintIcon /></ListItemIcon>
            <ListItemText>{local.printers}</ListItemText>
          </ListItem>
        </List>
      )}
    </List>
  );
}
