import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';

import { LocalizationContext } from '../../../AppContext';
import localization from './DashboardMenu.local';

export default function DashboardMenu() {
  const local = localization[useContext(LocalizationContext)];
  const { pathname } = useLocation();

  return (
    <ListItem button component={Link} to="/" sx={{ backgroundColor: pathname === '/' ? 'action.selected' : 'primary.main' }}>
      <ListItemIcon sx={{ color: 'primary.contrastText', minWidth: 35 }}><DashboardIcon /></ListItemIcon>
      <ListItemText>{local.dashboard}</ListItemText>
    </ListItem>
  );
}
