import React, { useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import HelpIcon from '@mui/icons-material/Help';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { SocketContext, LocalizationContext } from '../../AppContext';
import Copyright from '../../components/Copyright';
import Snackbar from '../../components/Snackbar';
import emit from '../../util/emit';

import localization from './PasswordReset.local';

const PASSWORDS_MUST_MATCH = 400;

export default function PasswordReset({ onLogin }) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [alert, setAlert] = useState();
  const { token } = useParams();
  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    if (password === confirm) {
      emit(socket, setAlert, 'passwordReset', { token, password }, (data) => onLogin(data.token, data.user));
    } else {
      setAlert({ code: PASSWORDS_MUST_MATCH, event: 'passwordReset' });
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Paper sx={{ marginTop: 16, padding: 4 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Avatar sx={{ margin: 1, backgroundColor: 'primary.main' }}><HelpIcon /></Avatar>
          <Typography component="h1" variant="h5" sx={{ mb: 2 }}>{local.passwordReset}</Typography>
          <Box component="form" onSubmit={onSubmit}>
            <TextField
              required
              fullWidth
              autoFocus
              id="password"
              name="password"
              type="password"
              color="secondary"
              margin="dense"
              label={local.password}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              required
              fullWidth
              id="confirm"
              name="confirm"
              type="password"
              color="secondary"
              margin="dense"
              label={local.confirm}
              value={confirm}
              error={password !== confirm}
              onChange={(e) => setConfirm(e.target.value)}
            />
            <Button fullWidth sx={{ mt: 2 }} color="primary" variant="contained" type="submit">{local.send}</Button>
            <Button fullWidth sx={{ mt: 1 }} color="primary" onClick={() => navigate('/')}>{local.back}</Button>
          </Box>
        </Box>
      </Paper>
      <Copyright />
    </Container>
  );
}

PasswordReset.propTypes = {
  onLogin: PropTypes.func.isRequired,
};
