import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import DescriptionIcon from '@mui/icons-material/Description';
import TwitterIcon from '@mui/icons-material/Twitter';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';

import { LocalizationContext, UserContext } from '../../../AppContext';
import localization from './CitiesMenu.local';

export default function CitiesMenu({ userCities }) {
  const local = localization[useContext(LocalizationContext)];
  const user = useContext(UserContext);
  const { pathname } = useLocation();
  const [open, setOpen] = useState(pathname.includes('/cities') ? { [parseInt(pathname.split('/')[2], 10)]: true } : {});

  const getBackgroundColor = (path) => (pathname.includes(path) ? 'action.selected' : 'primary.main');

  return userCities ? (
    userCities.map((city) => (
      <List disablePadding key={city.id}>
        <ListItem button onClick={() => setOpen({ ...open, [city.id]: !open[city.id] })}>
          <ListItemText>{city.name}</ListItemText>
          <ListItemIcon sx={{ color: 'primary.contrastText', minWidth: 35 }}>{open[city.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}</ListItemIcon>
        </ListItem>
        { open[city.id] && (
          <List disablePadding>
            <ListItem button to={`/cities/${city.id}/info`} component={Link} sx={{ pl: 4, backgroundColor: getBackgroundColor(`/cities/${city.id}/info`) }}>
              <ListItemIcon sx={{ color: 'primary.contrastText', minWidth: 35 }}><HomeIcon /></ListItemIcon>
              <ListItemText>{local.info}</ListItemText>
            </ListItem>
            <ListItem button to={`/cities/${city.id}/documents`} component={Link} sx={{ pl: 4, backgroundColor: getBackgroundColor(`/cities/${city.id}/documents`) }}>
              <ListItemIcon sx={{ color: 'primary.contrastText', minWidth: 35 }}><DescriptionIcon /></ListItemIcon>
              <ListItemText>{local.documents}</ListItemText>
            </ListItem>
            <ListItem button to={`/cities/${city.id}/postings`} component={Link} sx={{ pl: 4, backgroundColor: getBackgroundColor(`/cities/${city.id}/postings`) }}>
              <ListItemIcon sx={{ color: 'primary.contrastText', minWidth: 35 }}><TwitterIcon /></ListItemIcon>
              <ListItemText>{local.postings}</ListItemText>
            </ListItem>
            {city.manager === user.id && (
              <ListItem button to={`/cities/${city.id}/users`} component={Link} sx={{ pl: 4, backgroundColor: getBackgroundColor(`/cities/${city.id}/users`) }}>
                <ListItemIcon sx={{ color: 'primary.contrastText', minWidth: 35 }}><PeopleIcon /></ListItemIcon>
                <ListItemText>{local.users}</ListItemText>
              </ListItem>
            )}
            {city.manager === user.id && (
              <ListItem button to={`/cities/${city.id}/settings`} component={Link} sx={{ pl: 4, backgroundColor: getBackgroundColor(`/cities/${city.id}/settings`) }}>
                <ListItemIcon sx={{ color: 'primary.contrastText', minWidth: 35 }}><SettingsIcon /></ListItemIcon>
                <ListItemText>{local.settings}</ListItemText>
              </ListItem>
            )}
          </List>
        )}
      </List>
    ))
  ) : <div />;
}

CitiesMenu.propTypes = {
  userCities: PropTypes.arrayOf(PropTypes.shape({})),
};

CitiesMenu.defaultProps = {
  userCities: undefined,
};
