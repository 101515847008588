export default {
  de: {
    name: 'Name',
    description: 'Beschreibung',
    destroy: 'Seitenkategorie löschen',
    createPage: 'Neue Seitenvorlage',
    pages: 'Seitenvorlagen',
    noPages: 'Keine Seitenvorlagen vorhanden',
    active: 'aktiv',
    inactive: 'inaktiv',
  },
};
