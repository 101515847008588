import React, { useContext, useState, useEffect } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { UserContext } from '../../../AppContext';
import CitiesContext from './CitiesContext';
import Info from './info/Info';
import Documents from './documents/Documents';
import Postings from './postings/Postings';
import Users from './users/Users';
import Settings from './settings/Settings';

export default function Cities({
  colors,
  documentcategories,
  documents,
  documenttypes,
  formats,
  messages,
  postings,
  postingtypes,
  printers,
  userCities,
  variables,
  onSetDocuments,
  onSetMessages,
  onSetPostings,
  onSetUserCities,
  onCityNotFound,
}) {
  const user = useContext(UserContext);
  const { cityid } = useParams();
  const [city, setCity] = useState();
  const [cityDocuments, setCityDocuments] = useState();
  const [cityMessages, setCityMessages] = useState();
  const [cityPostings, setCityPostings] = useState();

  useEffect(() => {
    if (userCities) {
      const foundCity = userCities.find(({ id }) => parseInt(cityid, 10) === id);
      if (foundCity) {
        setCity(foundCity);
      } else {
        onCityNotFound();
      }
    }
  }, [cityid, userCities]);

  useEffect(() => {
    if (city && documents) {
      const docs = documents.filter((item) => item.city === city.id);
      setCityDocuments(docs);
    }
  }, [city, documents]);

  useEffect(() => {
    if (city && messages) {
      setCityMessages(messages.filter((item) => item.city === city.id));
    }
  }, [city, messages]);

  useEffect(() => {
    if (city && postings) {
      setCityPostings(postings.filter((item) => item.city === city.id));
    }
  }, [city, postings]);

  return (
    <CitiesContext.Provider value={city}>
      <Routes>
        <Route
          path="info"
          element={(
            <Info
              cityDocuments={cityDocuments}
              cityMessages={cityMessages}
              cityPostings={cityPostings}
              messages={messages}
              onSetMessages={onSetMessages}
            />
          )}
        />
        <Route
          path="documents/*"
          element={(
            <Documents
              cityDocuments={cityDocuments}
              colors={colors}
              documentcategories={documentcategories}
              documents={documents}
              documenttypes={documenttypes}
              formats={formats}
              printers={printers}
              variables={variables}
              onSetDocuments={onSetDocuments}
            />
          )}
        />
        <Route
          path="postings/*"
          element={(
            <Postings
              cityPostings={cityPostings}
              colors={colors}
              postings={postings}
              postingtypes={postingtypes}
              variables={variables}
              onSetPostings={onSetPostings}
            />
          )}
        />
        { city && city.manager === user.id && <Route path="users" element={<Users />} />}
        { city && city.manager === user.id && (
          <Route
            path="settings"
            element={(
              <Settings
                variables={variables}
                userCities={userCities}
                onSetUserCities={onSetUserCities}
              />
            )}
          />
        )}
      </Routes>
    </CitiesContext.Provider>
  );
}

Cities.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.shape({})),
  documentcategories: PropTypes.arrayOf(PropTypes.shape({})),
  documents: PropTypes.arrayOf(PropTypes.shape({})),
  documenttypes: PropTypes.arrayOf(PropTypes.shape({})),
  formats: PropTypes.arrayOf(PropTypes.shape({})),
  messages: PropTypes.arrayOf(PropTypes.shape({})),
  postings: PropTypes.arrayOf(PropTypes.shape({})),
  postingtypes: PropTypes.arrayOf(PropTypes.shape({})),
  printers: PropTypes.arrayOf(PropTypes.shape({})),
  userCities: PropTypes.arrayOf(PropTypes.shape({})),
  variables: PropTypes.arrayOf(PropTypes.shape({})),
  onCityNotFound: PropTypes.func.isRequired,
  onSetDocuments: PropTypes.func.isRequired,
  onSetMessages: PropTypes.func.isRequired,
  onSetPostings: PropTypes.func.isRequired,
  onSetUserCities: PropTypes.func.isRequired,
};

Cities.defaultProps = {
  colors: undefined,
  documentcategories: undefined,
  documents: undefined,
  documenttypes: undefined,
  formats: undefined,
  printers: undefined,
  messages: undefined,
  postings: undefined,
  postingtypes: undefined,
  userCities: undefined,
  variables: undefined,
};
