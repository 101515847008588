import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { LocalizationContext } from '../../../../../AppContext';
import localization from './DocumentsBoard.local';

const boards = {
  deleted: [0],
  projects: [1, 2, 3, 4],
  archived: [5],
};
const colors = {
  0: 'error',
  1: 'error',
  2: 'info',
  3: 'info',
  4: 'success',
  5: 'success',
};

export default function DocumentsBoard({
  cityDocuments,
  documentcategories,
  documenttypes,
  onClickDocument,
  onClickCreate,
}) {
  const local = localization[useContext(LocalizationContext)];
  const [board, setBoard] = useState(boards.projects);

  const renderDocument = (document) => {
    const documenttype = documenttypes.find((item) => item.id === document.documenttype);
    const documentcategory = documentcategories.find((item) => item.id === documenttype.documentcategory);
    return (
      <Grid key={document.id} item xs={6} md={4} lg={3}>
        <Card sx={{ width: 1, minHeight: 100 }} onClick={() => onClickDocument(document.id)}>
          <CardActionArea sx={{ width: 1, minHeight: 100 }}>
            <CardContent>
              <Chip
                size="small"
                sx={{ position: 'absolute', top: 5, right: 5 }}
                label={local.status[document.status]}
                color={colors[document.status]}
              />
              <Typography variant="h6" sx={{ pt: 2 }}>{document.name}</Typography>
              <Typography variant="p">{documentcategory.name}</Typography>
              <Typography variant="p">{' - '}</Typography>
              <Typography variant="p">{documenttype.name}</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    );
  };

  return (
    <Container sx={{ mt: 5 }}>
      <Typography variant="h1" sx={{ mb: 2 }}>{local.title}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ButtonGroup fullWidth size="large">
            <Button
              color="primary"
              variant={board === boards.projects ? 'contained' : 'outlined'}
              onClick={() => setBoard(boards.projects)}
            >
              {local.projects}
            </Button>
            <Button
              color="primary"
              variant={board === boards.archived ? 'contained' : 'outlined'}
              onClick={() => setBoard(boards.archived)}
            >
              {local.archived}
            </Button>
            <Button
              color="primary"
              variant={board === boards.deleted ? 'contained' : 'outlined'}
              onClick={() => setBoard(boards.deleted)}
            >
              {local.deleted}
            </Button>
          </ButtonGroup>
        </Grid>
        { board === boards.projects && (
          <Grid key={document.id} item xs={6} md={4} lg={3}>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              sx={{ minHeight: 100 }}
              onClick={onClickCreate}
            >
              {local.createDocument}
            </Button>
          </Grid>
        )}
        { cityDocuments && cityDocuments
          .filter((item) => board.includes(item.status))
          .sort((a, b) => moment(b.createdAt).diff(a.createdAt))
          .map((document) => renderDocument(document))}
      </Grid>
    </Container>
  );
}

DocumentsBoard.propTypes = {
  cityDocuments: PropTypes.arrayOf(PropTypes.shape({})),
  documentcategories: PropTypes.arrayOf(PropTypes.shape({})),
  documenttypes: PropTypes.arrayOf(PropTypes.shape({})),
  onClickDocument: PropTypes.func.isRequired,
  onClickCreate: PropTypes.func.isRequired,
};

DocumentsBoard.defaultProps = {
  cityDocuments: undefined,
  documentcategories: undefined,
  documenttypes: undefined,
};
