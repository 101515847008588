export default function emitSync(socket, setAlert, event, params) {
  return new Promise((resolve) => {
    socket.emit(event, params, (response) => {
      if (setAlert) {
        setAlert({ ...response, event });
      }
      resolve(response.payload);
    });
  });
}
