export default {
  de: {
    name: 'Name',
    active: 'Aktiv',
    height: 'Höhe',
    width: 'Breite',
    unit: 'Maßeinheit',
    editor: ' zuletzt bearbeitet von',
    back: 'Zurück',
    destroy: ' Format löschen',
  },
};
