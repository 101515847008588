import React from 'react';
import PropTypes from 'prop-types';
import MuiSnackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const severity = (code) => {
  if (code < 200) return 'info';
  if (code < 300) return 'success';
  if (code < 400) return 'warning';
  if (code < 500) return 'error';
  return 'warning';
};

const horizontalByCode = (code) => {
  if (code < 200) return 'left';
  if (code < 300) return 'center';
  if (code < 400) return 'center';
  if (code < 500) return 'center';
  return 'center';
};

export default function Snackbar({
  alert,
  autoHideDuration,
  horizontal,
  local,
  vertical,
  onClose,
}) {
  return (
    <MuiSnackbar
      open={(!!alert && !!local && !!local[alert.event] && !!local[alert.event][alert.code])}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{
        vertical,
        horizontal: horizontal || horizontalByCode(alert ? alert.code : undefined),
      }}
    >
      { alert && local && local[alert.event] && local[alert.event][alert.code] && (
        <Alert onClose={onClose} severity={severity(alert.code)}>
          {local[alert.event][alert.code](alert.payload, alert.editor)}
        </Alert>
      )}
    </MuiSnackbar>
  );
}

Snackbar.propTypes = {
  alert: PropTypes.shape({
    code: PropTypes.number,
    event: PropTypes.string,
    payload: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))]),
    editor: PropTypes.shape({}),
  }),
  autoHideDuration: PropTypes.number,
  horizontal: PropTypes.string,
  local: PropTypes.shape({}),
  vertical: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

Snackbar.defaultProps = {
  alert: undefined,
  local: undefined,
  autoHideDuration: 6000,
  vertical: 'top',
  horizontal: undefined,
};
