export default {
  de: {
    passwordForgotten: 'Passwort vergessen?',
    email: 'Email',
    description: 'Geben Sie die E-Mail Adresse ihres Benutzers ein und wir senden Ihnen einen Link um ein neues Passwort festzulegen.',
    send: 'senden',
    back: 'zurück zur Anmeldung',
    alerts: {
      passwordForgotten: {
        200: ({ email }) => `Sie erhalten in kürze eine Email an '${email}' um ein neues Passwort zu wählen. Bitte prüfen Sie ihren SPAM-Ordner!`,
        404: ({ email }) => `Der Benutzer '${email}' ist nicht registriert. Bitte prüfen Sie Ihre Eingabe!`,
        500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
      },
    },
  },
};
