export default {
  de: {
    title: 'Redaktion',
    createDocument: 'Neues Dokument',
    projects: 'Projekte',
    archived: 'Archiv',
    deleted: 'Papierkorb',
    status: {
      0: 'gelöscht',
      1: 'bearbeitung',
      2: 'korrektur',
      3: 'korrigiert',
      4: 'veröffentlicht',
      5: 'archiviert',
    },
  },
};
