import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import emit from '../../../../util/emit';
import localization from './ColorsEdit.local';

export default function ColorsEdit({
  colors,
  onSetColors,
  onBack,
}) {
  const { colorid } = useParams();
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const color = colors ? colors.find(({ id }) => id === parseInt(colorid, 10)) : undefined;
  const [editor, setEditor] = useState();
  const [name, setName] = useState(color ? color.name : '');
  const [hex, setHex] = useState(color ? color.hex : '');
  const [cmyk, setCmyk] = useState(color ? color.cmyk : '');
  const [alert, setAlert] = useState();
  const [typingTimeout, setTypingTimeout] = useState();

  useEffect(() => {
    if (color) {
      setName(color.name);
      setHex(color.hex);
      setCmyk(color.cmyk);
    }
  }, [color]);

  useEffect(() => {
    if (socket && color && (!editor || editor.id !== color.editor)) {
      emit(socket, setAlert, 'users.getOneById', { id: color.editor }, (newEditor) => setEditor(newEditor.user));
    }
  }, [socket, color, editor]);

  const onDestroy = () => {
    emit(socket, setAlert, 'colors.destroy', { id: color.id }, (destroyedColor) => onSetColors(colors.filter(({ id }) => id !== destroyedColor.id)));
  };

  const onChange = (field, value) => {
    emit(socket, setAlert, 'colors.patch', { id: color.id, field, value }, (updatedColor) => {
      onSetColors(colors.map((item) => (item.id === updatedColor.id ? updatedColor : item)));
    });
  };

  const onTextChange = (setter, field, value) => {
    setter(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => onChange(field, value), 1000));
  };

  return (
    <Container sx={{ pt: 5 }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Paper sx={{ p: 5 }}>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={10}>
            <TextField
              fullWidth
              variant="outlined"
              color="secondary"
              label={local.name}
              value={name}
              onChange={(e) => onTextChange(setName, 'name', e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              sx={{ mt: 1, ml: 1 }}
              control={<Switch checked={color ? color.active : true} onChange={(e) => onChange('active', e.target.checked)} />}
              label={local.active}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              fullWidth
              variant="outlined"
              color="secondary"
              label={local.hex}
              value={hex}
              onChange={(e) => onTextChange(setHex, 'hex', e.target.value)}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              fullWidth
              variant="outlined"
              color="secondary"
              label={local.cmyk}
              value={cmyk}
              onChange={(e) => onTextChange(setCmyk, 'cmyk', e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <Box
              display="flex"
              justifyContent="center"
              sx={{
                border: '1px solid black',
                height: '100%',
                width: '100%',
                backgroundColor: color ? color.hex : undefined,
                color: color ? 'white' : undefined,
              }}
            >
              <Typography sx={{ mt: 'auto', mb: 'auto' }}>{local.preview}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {editor && (
              <Box>
                <Typography variant="caption" color="primary">{local.editor}</Typography>
                <Box display="flex" sx={{ mt: 1 }}>
                  <Avatar src={editor.avatar} alt={`${editor.firstname} ${editor.lastname}`} sx={{ width: 30, height: 30, mr: 1 }} />
                  <Typography>{`${editor.firstname} ${editor.lastname}`}</Typography>
                </Box>
              </Box>
            )}
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth sx={{ marginTop: 2 }} color="primary" variant="outlined" onClick={onBack}>{local.back}</Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth sx={{ marginTop: 2 }} color="primary" variant="contained" onClick={onDestroy}>{local.destroy}</Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

ColorsEdit.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.shape({})),
  onSetColors: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

ColorsEdit.defaultProps = {
  colors: undefined,
};
