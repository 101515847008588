export default {
  de: {
    name: 'Name',
    variables: 'Variablen',
    createdAt: 'erstellt am',
    updatedAt: 'zuletzt bearbeitet am',
    editor: ' zuletzt bearbeitet von',
    destroy: ' Gemeinde löschen',
    manager: 'Beauftragter',
  },
};
