import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';

import { UserContext, LocalizationContext } from '../../AppContext';
import localization from './AuthenticatedBar.local';

export default function AuthenticatedBar({ onLogout }) {
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const local = localization[useContext(LocalizationContext)];
  const [userMenuAnchor, setUserMenuAnchor] = useState();

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        backgroundColor: 'background.paper',
        boxShadow: '0 1px 3px rgb(0 0 0 / 10%)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        position: 'fixed',
        top: 0,
        left: 200,
        right: 0,
        zIndex: 100,
      }}
    >
      <Typography sx={{ ml: 2 }}>{local.save}</Typography>
      <div style={{ flexGrow: 1 }} />
      <Button sx={{ textTransform: 'unset' }} onClick={(e) => setUserMenuAnchor(e.currentTarget)}>
        <Avatar src={user.avatar} alt={`${user.firstname} ${user.lastname}`} sx={{ width: 30, height: 30, mr: 1 }} />
        <Typography sx={{ color: 'text.primary' }}>{`${user.firstname} ${user.lastname}`}</Typography>
        <ExpandMoreIcon sx={{ color: 'text.primary' }} />
      </Button>
      <Menu
        getContentAnchorEl={null}
        anchorEl={userMenuAnchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(userMenuAnchor)}
        onClose={() => setUserMenuAnchor()}
      >
        <MenuItem disabled>
          <Typography>{`${user.firstname} ${user.lastname}`}</Typography>
        </MenuItem>
        <MenuItem disabled divider>
          <Typography>{user.email}</Typography>
        </MenuItem>
        <MenuItem divider onClick={() => navigate('/profile')}>
          <PersonIcon />
          <Typography sx={{ marginLeft: 1 }}>{local.profile}</Typography>
        </MenuItem>
        <MenuItem onClick={onLogout}>
          <LogoutIcon />
          <Typography sx={{ marginLeft: 1 }}>{local.logout}</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}

AuthenticatedBar.propTypes = {
  onLogout: PropTypes.func.isRequired,
};
