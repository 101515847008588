import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { LocalizationContext } from '../../AppContext';
import localization from './DesignerPosition.local';

export default function DesignerPosition({ values, onTextUpdate }) {
  const local = localization[useContext(LocalizationContext)];

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{local.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{local.description}</Typography>
        <Typography variant="p">{local.margin}</Typography>
        <Grid container spacing={2} sx={{ mb: 1 }}>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.left}
              value={values.marginLeft}
              onChange={(e) => onTextUpdate('marginLeft', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.top}
              value={values.marginTop}
              onChange={(e) => onTextUpdate('marginTop', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.right}
              value={values.marginRight}
              onChange={(e) => onTextUpdate('marginRight', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.bottom}
              value={values.marginBottom}
              onChange={(e) => onTextUpdate('marginBottom', e.target.value)}
            />
          </Grid>
        </Grid>
        <Typography variant="p">{local.padding}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.left}
              value={values.paddingLeft}
              onChange={(e) => onTextUpdate('paddingLeft', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.top}
              value={values.paddingTop}
              onChange={(e) => onTextUpdate('paddingTop', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.right}
              value={values.paddingRight}
              onChange={(e) => onTextUpdate('paddingRight', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.bottom}
              value={values.paddingBottom}
              onChange={(e) => onTextUpdate('paddingBottom', e.target.value)}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
DesignerPosition.propTypes = {
  values: PropTypes.shape({
    margin: PropTypes.string,
    marginHorizontal: PropTypes.string,
    marginVertical: PropTypes.string,
    marginTop: PropTypes.string,
    marginRight: PropTypes.string,
    marginBottom: PropTypes.string,
    marginLeft: PropTypes.string,
    padding: PropTypes.string,
    paddingHorizontal: PropTypes.string,
    paddingVertical: PropTypes.string,
    paddingTop: PropTypes.string,
    paddingRight: PropTypes.string,
    paddingBottom: PropTypes.string,
    paddingLeft: PropTypes.string,
  }).isRequired,
  onTextUpdate: PropTypes.func.isRequired,
};
