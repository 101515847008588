import React, { useContext, useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import emit from '../../../../util/emit';
import CitiesContext from '../CitiesContext';
import UsersChange from './UsersChange';
import UsersCreate from './UsersCreate';
import localization from './Users.local';

export default function Users() {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const city = useContext(CitiesContext);
  const [users, setUsers] = useState();
  const [roles, setRoles] = useState();
  const [alert, setAlert] = useState();

  useEffect(() => {
    if (socket && city) {
      emit(socket, setAlert, 'users.getAllByCity', { city: city.id }, (data) => {
        setUsers(data.users);
        setRoles(data.roles);
      });
    }
  }, [socket, city]);

  const onCreate = (user) => setUsers([...users, user]);
  const onUpdate = (user) => setUsers(users.map((item) => (item.id === user.id ? user : item)));

  const onCreateRole = (role) => setRoles([...roles, role]);
  const onUpdateRole = (role) => setRoles(roles.map((item) => (item.city === role.city && item.user === role.user ? role : item)));
  const onDestroyRole = (role) => {
    setRoles(roles.filter((item) => (item.city !== role.city || item.user !== role.user)));
    setUsers(users.filter((item) => (item.id !== role.user)));
  };

  return (
    <Container sx={{ pt: 5 }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Typography variant="h1" sx={{ mb: 2 }}>{local.title}</Typography>
      {city && (
        <UsersCreate
          city={city}
          onCreate={onCreate}
          onCreateRole={onCreateRole}
        />
      )}
      {city && roles && users && users.map((user) => (
        <UsersChange
          key={user.id}
          city={city}
          user={user}
          role={roles.find((role) => role.user === user.id)}
          onUpdate={onUpdate}
          onUpdateRole={onUpdateRole}
          onDestroyRole={onDestroyRole}
        />
      ))}
    </Container>
  );
}
