export default {
  de: {
    title: 'Einrückungen',
    margin: 'Außen',
    marginHorizontal: '',
    marginVertical: '',
    top: 'Oben in mm',
    right: 'Rechts in mm',
    bottom: 'Unten in mm',
    left: 'Links in mm',
    padding: 'Innen',
    paddingHorizontal: '',
    paddingVertical: '',
  },
};
