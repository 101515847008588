import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import DocumentCategoryCards from './documentcategorycards/DocumentcategoryCards';
import Documentcategory from './documentcategory/Documentcategory';

export default function Documentcategories({
  colors,
  documentcategories,
  documenttypes,
  formats,
  variables,
  onSetDocumentcategories,
  onSetDocumenttypes,
}) {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={(
          <DocumentCategoryCards
            documentcategories={documentcategories}
            onSetDocumentcategories={onSetDocumentcategories}
            onClick={(documentcategoryid) => navigate(`/designer/documentcategories/${documentcategoryid}`)}
          />
        )}
      />
      <Route
        path="/:documentcategoryid/*"
        element={(
          <Documentcategory
            colors={colors}
            documentcategories={documentcategories}
            documenttypes={documenttypes}
            formats={formats}
            variables={variables}
            onSetDocumentcategories={onSetDocumentcategories}
            onSetDocumenttypes={onSetDocumenttypes}
            onBack={() => navigate('/designer/documentcategories/')}
          />
        )}
      />
    </Routes>
  );
}

Documentcategories.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.shape({})),
  documentcategories: PropTypes.arrayOf(PropTypes.shape({})),
  documenttypes: PropTypes.arrayOf(PropTypes.shape({})),
  formats: PropTypes.arrayOf(PropTypes.shape({})),
  variables: PropTypes.arrayOf(PropTypes.shape({})),
  onSetDocumentcategories: PropTypes.func.isRequired,
  onSetDocumenttypes: PropTypes.func.isRequired,
};

Documentcategories.defaultProps = {
  colors: undefined,
  documentcategories: undefined,
  documenttypes: undefined,
  formats: undefined,
  variables: undefined,
};
