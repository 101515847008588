export default {
  de: {
    category: 'Kategorie',
    create: 'Dokumenttyp hinzufügen',
    active: 'aktiv',
    inactive: 'inaktiv',
    newName: 'Neuer Dokumenttyp',
    name: 'Name',
    description: 'Beschreibung',
    status: 'Aktiv',
    documenttypes: 'Dokumenttypen',
    back: 'Zurück',
  },
};
