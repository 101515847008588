import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import emit from '../../../../util/emit';
import localization from './UsersChange.local';

export default function UsersChange({
  cities,
  users,
  roles,
  user,
  onUpdate,
  onUpdateRole,
  onCreateRole,
  onDestroyRole,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [firstname, setFirstname] = useState(user.firstname);
  const [lastname, setLastname] = useState(user.lastname);
  const [email, setEmail] = useState(user.email);
  const [editor] = useState(users.find(({ id }) => id === user.editor));
  const [cityRole, setCityRole] = useState(cities[0]);
  const [expanded, setExpanded] = useState(false);
  const [rolesExpanded, setRolesExpanded] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState();
  const [alert, setAlert] = useState();

  const onAddRole = (city) => {
    emit(socket, setAlert, 'users.postRole', { user: user.id, city: city.id }, onCreateRole);
  };

  const onChangeRole = (role, field, value) => {
    const patch = {
      city: role.city,
      user: role.user,
      field,
      value,
    };
    emit(socket, setAlert, 'users.patchRole', patch, onUpdateRole);
  };

  const onDeleteRole = (role) => {
    emit(socket, setAlert, 'users.destroyRole', { user: role.user, city: role.city }, onDestroyRole);
  };

  const onChange = (field, value) => {
    emit(socket, setAlert, 'users.patch', { id: user.id, field, value }, onUpdate);
  };

  const onTextChange = (setter, field, value) => {
    setter(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => onChange(field, value), 1000));
  };

  const postNewPassword = () => {
    emit(socket, setAlert, 'users.postNewPassword', { id: user.id });
  };

  return (
    <Accordion expanded={expanded} onChange={(e, state) => setExpanded(state)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {`${firstname} ${lastname}`}
      </AccordionSummary>
      <AccordionDetails>
        {expanded && (
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                color="secondary"
                margin="dense"
                label={local.firstname}
                value={firstname}
                onChange={(e) => onTextChange(setFirstname, 'firstname', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                variant="outlined"
                color="secondary"
                margin="dense"
                label={local.lastname}
                value={lastname}
                onChange={(e) => onTextChange(setLastname, 'lastname', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                variant="outlined"
                color="secondary"
                margin="dense"
                label={local.email}
                value={email}
                onChange={(e) => onTextChange(setEmail, 'email', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Accordion expanded={rolesExpanded} onChange={(e, state) => setRolesExpanded(state)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {local.roles}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container item xs={12}>
                    <Grid item xs={8}>
                      <Autocomplete
                        disableClearable
                        options={cities}
                        value={cityRole}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newValue) => setCityRole(newValue)}
                        sx={{
                          '.MuiOutlinedInput-root': {
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            fullWidth
                            variant="outlined"
                            color="secondary"
                            margin="dense"
                            label={local.cityRole}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => onAddRole(cityRole)}
                        sx={{
                          mt: 1,
                          height: 55,
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        }}
                      >
                        {local.createRole}
                      </Button>
                    </Grid>
                  </Grid>
                  {rolesExpanded && cities && cities
                    .filter((city) => {
                      const userCityRole = roles.find((role) => role.city === city.id && role.user === user.id);
                      return !!userCityRole;
                    })
                    .map((city) => {
                      const role = roles.find((item) => item.city === city.id && item.user === user.id);
                      return (
                        <Accordion key={city.id}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            {city.name}
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container>
                              <Grid item xs={6}>
                                <Typography>{local.publisher}</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Switch checked={role.publisher} onChange={(e) => onChangeRole(role, 'publisher', e.target.checked)} />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography>{local.author}</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Switch checked={role.author} onChange={(e) => onChangeRole(role, 'author', e.target.checked)} />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography>{local.proofreader}</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Switch checked={role.proofreader} onChange={(e) => onChangeRole(role, 'proofreader', e.target.checked)} />
                              </Grid>
                              <Grid item xs={12}>
                                <Box display="flex" justifyContent="flex-end">
                                  <Button variant="contained" onClick={() => onDeleteRole(role)}>{local.destroyRole}</Button>
                                </Box>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography>{local.admin}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Switch checked={user.admin} onChange={(e) => onUpdate('admin', e.target.checked)} />
              </Grid>
            </Grid>
            {cities && cities
              .filter((city) => city.manager === user.id)
              .map((city) => (
                <Grid key={city.id} container item xs={12}>
                  <Grid item xs={6}>
                    <Typography>{local.manager}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{city.name}</Typography>
                  </Grid>
                </Grid>
              ))}
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography>{local.createdAt}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{moment(user.createdAt).format('DD.MM.YYYY')}</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography>{local.updatedAt}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{moment(user.updatedAt).format('DD.MM.YYYY')}</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography>{local.editor}</Typography>
              </Grid>
              <Grid item xs={6}>
                { editor ? (
                  <Box display="flex" sx={{ mt: 1 }}>
                    <Avatar src={editor.avatar} alt={`${editor.firstname} ${editor.lastname}`} sx={{ width: 30, height: 30, mr: 1 }} />
                    <Typography>{`${editor.firstname} ${editor.lastname}`}</Typography>
                  </Box>
                ) : (
                  <Box display="flex" sx={{ mt: 1 }}>
                    <CircularProgress />
                  </Box>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end" sx={{ width: '100%', p: 1 }}>
                <Button variant="contained" onClick={postNewPassword}>{local.postNewPassword}</Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

UsersChange.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape()),
  users: PropTypes.arrayOf(PropTypes.shape()),
  roles: PropTypes.arrayOf(PropTypes.shape()),
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    admin: PropTypes.bool.isRequired,
    editor: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
  onCreateRole: PropTypes.func.isRequired,
  onUpdateRole: PropTypes.func.isRequired,
  onDestroyRole: PropTypes.func.isRequired,
};

UsersChange.defaultProps = {
  cities: undefined,
  users: undefined,
  roles: undefined,
};
