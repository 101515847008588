export default {
  de: {
    passwordReset: 'Neues Passwort wählen',
    password: 'Neues Passwort',
    confirm: 'Neues Passwort bestätigen',
    send: 'Senden',
    back: 'zurück zur Anmeldung',
    alerts: {
      passwordReset: {
        200: () => 'Ihr Passwort wurde geändert. Sie werden eingeloggt...',
        400: () => 'Die Passwörter stimmen nicht überein!',
        401: () => 'Der Link ist abgelaufen. Bitte fordern Sie einen neuen Passwort-Vergessen-Link an!',
        403: () => 'Der Link ungültig. Bitte fordern Sie einen neuen Passwort-Vergessen-Link an!',
        500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
      },
    },
  },
};
