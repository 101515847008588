export default {
  de: {
    newElement: 'Neues Element',
    overlay: 'Overlay',
    test: 'Testwerkzeug',
    city: 'Gemeinde',
    offset: 'Anschnitt',
    cropmarks: 'Anschnittmarken',
    variables: 'Variablen',
  },
};
