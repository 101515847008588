export default {
  de: {
    name: 'Name',
    description: 'Beschreibung',
    createElement: 'Element Hinzufügen',
    type: 'Typ',
    designer: 'Designer',
    author: 'Autor',
    view: 'Bereich',
    spacing: 'Abstand Elemente in mm',
    flexview: 'Flex-Bereich',
    flexelement: 'Flex-Element',
    text: 'Text',
    image: 'Bild',
    fleximage: 'Flex-Bild',
    variable: 'Variable',
    article: 'Artikel',
    editable: 'Bearbeiter',
    elements: 'Elemente',
    pagenumber: 'Seitennummer',
    overlaytext: 'Overlaytext',
    overlay1: 'Overlay Gerade',
    overlay2: 'Overlay Ungerade',
    impressum: 'Impressum',
    maxChar: 'Maximale Zeichen',
    destroy: 'Löschen',
    updateImage: 'Bild hochladen',
    variables: 'Variable',
    insert: 'einfügen',
    basedesign: 'Grunddesign',
  },
};
