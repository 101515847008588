import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DescriptionIcon from '@mui/icons-material/Description';

import { LocalizationContext } from '../../../../../../AppContext';
import OverlayDesigner from './OverlayDesigner';
import localization from './Documenttype.local';

export default function DocumenttypeEditor({
  cities,
  documenttype,
  formats,
  colors,
  elements,
  variables,
  onUpdate,
  onCreateElement,
  onUpdateElement,
  onDestroyElement,
  onImagesUpload,
  images,
}) {
  const local = localization[useContext(LocalizationContext)];
  const [documenttypeState, setDocumenttypeState] = useState(documenttype);
  const [typingTimeout, setTypingTimeout] = useState();
  const [expanded, setExpanded] = useState();

  useEffect(() => setDocumenttypeState(documenttype), [documenttype]);

  const onTextChange = (field, value) => {
    setDocumenttypeState({ ...documenttypeState, [field]: value });
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => onUpdate(documenttypeState.id, field, value), 1000),
    );
  };

  const onChange = (field, value) => onUpdate(documenttypeState.id, field, value);

  return (
    <Accordion sx={{ mb: 2 }} expanded={expanded} onChange={(e, state) => setExpanded(state)}>
      <AccordionSummary sx={{ backgroundColor: 'primary.main', color: 'white' }} expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
        <DescriptionIcon sx={{ mr: 2 }} />
        <Typography>{documenttypeState.name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {expanded && (
          <Grid container>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                color="secondary"
                margin="dense"
                label={local.name}
                value={documenttypeState.name}
                onChange={(e) => onTextChange('name', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                color="secondary"
                margin="dense"
                label={local.description}
                value={documenttypeState.description}
                onChange={(e) => onTextChange('description', e.target.value)}
              />
            </Grid>
            <Grid container item spacing={2} xs={12}>
              <Grid item xs={12}>
                <Autocomplete
                  value={documenttypeState.format ? formats.find((item) => item.id === documenttypeState.format) : null}
                  options={formats}
                  onChange={(e, newValue) => onChange('format', newValue.id)}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      variant="outlined"
                      color="secondary"
                      margin="dense"
                      label={local.format}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={2} xs={12}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  type="number"
                  label={local.minPages}
                  value={documenttypeState.minPages}
                  onChange={(e) => onTextChange('minPages', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  type="number"
                  label={local.newPages}
                  value={documenttypeState.newPages}
                  onChange={(e) => onTextChange('newPages', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  type="number"
                  label={local.maxPages}
                  value={documenttypeState.maxPages}
                  onChange={(e) => onTextChange('maxPages', e.target.value)}
                />
              </Grid>
            </Grid>
            <Divider sx={{ mt: 2 }} />
            <Grid container item spacing={2} xs={12}>
              <Grid item xs={6}>
                <FormControlLabel
                  label={local.active}
                  sx={{ m: 2 }}
                  control={<Switch checked={documenttypeState.active} onChange={(e) => onChange('active', e.target.checked)} />}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  label={local.orientation}
                  sx={{ m: 2 }}
                  control={(
                    <Switch
                      checked={documenttypeState.orientation === 'landscape'}
                      onChange={(e) => onChange('orientation', e.target.checked ? 'landscape' : 'portrait')}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Divider sx={{ mb: 2 }} />
            <OverlayDesigner
              cities={cities}
              documenttype={documenttype}
              elements={elements}
              colors={colors}
              formats={formats}
              variables={variables}
              onCreate={onCreateElement}
              onUpdate={onUpdateElement}
              onImagesUpload={onImagesUpload}
              onDestroy={onDestroyElement}
              images={images}
            />
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

DocumenttypeEditor.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  documenttype: PropTypes.shape({}).isRequired,
  variables: PropTypes.arrayOf(PropTypes.shape({})),
  elements: PropTypes.arrayOf(PropTypes.shape({})),
  formats: PropTypes.arrayOf(PropTypes.shape({})),
  colors: PropTypes.arrayOf(PropTypes.shape({})),
  images: PropTypes.shape({}),
  onUpdate: PropTypes.func.isRequired,
  onCreateElement: PropTypes.func.isRequired,
  onUpdateElement: PropTypes.func.isRequired,
  onDestroyElement: PropTypes.func.isRequired,
  onImagesUpload: PropTypes.func.isRequired,
};

DocumenttypeEditor.defaultProps = {
  cities: undefined,
  variables: undefined,
  colors: undefined,
  elements: undefined,
  formats: undefined,
  images: undefined,
};
