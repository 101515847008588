import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import emit from '../../../../util/emit';
import localization from './PrintersEdit.local';

export default function PrintersEdit({
  printers,
  onBack,
  onSetPrinters,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const { printerid } = useParams();
  const printer = printers ? printers.find(({ id }) => id === parseInt(printerid, 10)) : undefined;
  const [editor, setEditor] = useState();
  const [typingTimeout, setTypingTimeout] = useState();
  const [name, setName] = useState(printer ? printer.name : '');
  const [email, setEmail] = useState(printer ? printer.email : '');
  const [offset, setOffset] = useState(printer ? printer.offset : '');
  const [template, setTemplate] = useState(printer ? printer.template : '');
  const [cropmarks, setCropmarks] = useState(printer ? printer.cropmarks : '');
  const [postcode, setPostcode] = useState(printer ? printer.postcode : '');
  const [city, setCity] = useState(printer ? printer.city : '');
  const [street, setStreet] = useState(printer ? printer.street : '');
  const [number, setNumber] = useState(printer ? printer.number : '');
  const [active, setActive] = useState(printer ? printer.active : false);
  const [alert, setAlert] = useState(printer ? printer.active : false);

  useEffect(() => {
    if (printer) {
      setName(printer.name);
      setEmail(printer.email);
      setOffset(printer.offset);
      setTemplate(printer.template);
      setCropmarks(printer.cropmarks);
      setPostcode(printer.postcode);
      setCity(printer.city);
      setStreet(printer.street);
      setNumber(printer.number);
      setActive(printer.active);
    }
  }, [printer]);

  useEffect(() => {
    if (socket && printer) {
      emit(socket, setAlert, 'users.getOneById', { id: printer.editor }, (foundEditor) => setEditor(foundEditor));
    }
  }, [socket, printer]);

  const onDestroy = () => {
    emit(socket, setAlert, 'printers.destroy', { id: printer.id }, (destroyedPrinter) => {
      onSetPrinters(printers.filter(({ id }) => id !== destroyedPrinter.id));
      onBack();
    });
  };

  const onChange = (field, value) => {
    emit(socket, setAlert, 'printers.patch', { id: printer.id, field, value }, (patchedPrinter) => {
      onSetPrinters(printers.map((item) => (item.id === patchedPrinter.id ? patchedPrinter : item)));
    });
  };

  const onTextChange = (setter, field, value) => {
    setter(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => onChange(field, value), 1000));
  };

  return (
    <Container sx={{ pt: 5 }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Paper sx={{ p: 5 }}>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              color="secondary"
              label={local.name}
              value={name}
              onChange={(e) => onTextChange(setName, 'name', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              color="secondary"
              label={local.email}
              value={email}
              onChange={(e) => onTextChange(setEmail, 'email', e.target.value)}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              variant="outlined"
              color="secondary"
              label={local.city}
              value={city}
              onChange={(e) => onTextChange(setCity, 'city', e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              variant="outlined"
              color="secondary"
              label={local.postcode}
              value={postcode}
              onChange={(e) => onTextChange(setPostcode, 'postcode', e.target.value)}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              variant="outlined"
              color="secondary"
              label={local.street}
              value={street}
              onChange={(e) => onTextChange(setStreet, 'street', e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              variant="outlined"
              color="secondary"
              label={local.number}
              value={number}
              onChange={(e) => onTextChange(setNumber, 'number', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              color="secondary"
              label={local.offset}
              value={offset}
              onChange={(e) => onTextChange(setOffset, 'offset', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              multiline
              variant="outlined"
              color="secondary"
              label={local.template}
              value={template}
              onChange={(e) => onTextChange(setTemplate, 'template', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>{local.cropmarks}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Switch checked={cropmarks} onChange={(e) => onChange('cropmarks', e.target.checked)} />
          </Grid>
          <Grid item xs={6}>
            <Typography>{local.active}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Switch checked={active} onChange={(e) => onChange('active', e.target.checked)} />
          </Grid>
          <Grid item xs={6}>
            <Typography>{local.editor}</Typography>
          </Grid>
          <Grid item xs={6}>
            {editor && (
              <Box display="flex" sx={{ mt: 1 }}>
                <Avatar src={editor.avatar} alt={`${editor.firstname} ${editor.lastname}`} sx={{ width: 30, height: 30, mr: 1 }} />
                <Typography>{`${editor.firstname} ${editor.lastname}`}</Typography>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={6}>
            <Button fullWidth sx={{ marginTop: 2 }} color="primary" variant="outlined" onClick={onBack}>{local.back}</Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth sx={{ marginTop: 2 }} color="primary" variant="contained" onClick={onDestroy}>{local.destroy}</Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

PrintersEdit.propTypes = {
  printers: PropTypes.arrayOf(PropTypes.shape({})),
  onSetPrinters: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

PrintersEdit.defaultProps = {
  printers: undefined,
};
