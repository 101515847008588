export default function registerFonts(Font) {
  Font.register({
    family: 'Roboto',
    fonts: [
      { src: `${process.env.PUBLIC_URL}/fonts/Roboto/Bold.ttf`, fontWeight: 700 },
      { src: `${process.env.PUBLIC_URL}/fonts/Roboto/BoldItalic.ttf`, fontWeight: 700, fontStyle: 'italic' },
      { src: `${process.env.PUBLIC_URL}/fonts/Roboto/Heavy.ttf`, fontWeight: 900 },
      { src: `${process.env.PUBLIC_URL}/fonts/Roboto/HeavyItalic.ttf`, fontWeight: 900, fontStyle: 'italic' },
      { src: `${process.env.PUBLIC_URL}/fonts/Roboto/Light.ttf`, fontWeight: 300 },
      { src: `${process.env.PUBLIC_URL}/fonts/Roboto/LightItalic.ttf`, fontWeight: 300, fontStyle: 'italic' },
      { src: `${process.env.PUBLIC_URL}/fonts/Roboto/Medium.ttf`, fontWeight: 500 },
      { src: `${process.env.PUBLIC_URL}/fonts/Roboto/MediumItalic.ttf`, fontWeight: 500, fontStyle: 'italic' },
      { src: `${process.env.PUBLIC_URL}/fonts/Roboto/Normal.ttf`, fontWeight: 400 },
      { src: `${process.env.PUBLIC_URL}/fonts/Roboto/NormalItalic.ttf`, fontWeight: 400, fontStyle: 'italic' },
      { src: `${process.env.PUBLIC_URL}/fonts/Roboto/Thin.ttf`, fontWeight: 100 },
      { src: `${process.env.PUBLIC_URL}/fonts/Roboto/ThinItalic.ttf`, fontWeight: 100, fontStyle: 'italic' },
    ],
  });
  Font.register({
    family: 'RobotoCondensed',
    fonts: [
      { src: `${process.env.PUBLIC_URL}/fonts/RobotoCondensed/Bold.ttf`, fontWeight: 700 },
      { src: `${process.env.PUBLIC_URL}/fonts/RobotoCondensed/BoldItalic.ttf`, fontWeight: 700, fontStyle: 'italic' },
      { src: `${process.env.PUBLIC_URL}/fonts/RobotoCondensed/Light.ttf`, fontWeight: 300 },
      { src: `${process.env.PUBLIC_URL}/fonts/RobotoCondensed/LightItalic.ttf`, fontWeight: 300, fontStyle: 'italic' },
      { src: `${process.env.PUBLIC_URL}/fonts/RobotoCondensed/Normal.ttf`, fontWeight: 400 },
      { src: `${process.env.PUBLIC_URL}/fonts/RobotoCondensed/NormalItalic.ttf`, fontWeight: 400, fontStyle: 'italic' },
    ],
  });
  Font.register({
    family: 'RobotoSlab',
    fonts: [
      { src: `${process.env.PUBLIC_URL}/fonts/RobotoSlab/Bold.ttf`, fontWeight: 700 },
      { src: `${process.env.PUBLIC_URL}/fonts/RobotoSlab/Heavy.ttf`, fontWeight: 900 },
      { src: `${process.env.PUBLIC_URL}/fonts/RobotoSlab/Light.ttf`, fontWeight: 300 },
      { src: `${process.env.PUBLIC_URL}/fonts/RobotoSlab/Medium.ttf`, fontWeight: 500 },
      { src: `${process.env.PUBLIC_URL}/fonts/RobotoSlab/Normal.ttf`, fontWeight: 400 },
      { src: `${process.env.PUBLIC_URL}/fonts/RobotoSlab/SemiBold.ttf`, fontWeight: 600 },
      { src: `${process.env.PUBLIC_URL}/fonts/RobotoSlab/Thin.ttf`, fontWeight: 100 },
      { src: `${process.env.PUBLIC_URL}/fonts/RobotoSlab/UltraBold.ttf`, fontWeight: 800 },
      { src: `${process.env.PUBLIC_URL}/fonts/RobotoSlab/UltraLight.ttf`, fontWeight: 200 },
    ],
  });
}
