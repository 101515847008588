export default {
  de: {
    messages: 'Nachrichten',
    noMessages: 'keine Nachrichten vorhanden',
    newMessage: 'Neue Nachricht',
    submit: 'Senden',
    alerts: {
      'messages.post': {
        200: () => 'Nachricht gesendet!',
      },
    },
  },
};
