import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { LocalizationContext } from '../../AppContext';
import ColorPicker from '../ColorPicker';
import localization from './DesignerBorders.local';

export default function DesignerBorders({
  values, colors, onUpdate, onTextUpdate,
}) {
  const local = localization[useContext(LocalizationContext)];
  const styleOptions = [
    { value: 'solid', label: local.style.solid },
    { value: 'dotted', label: local.style.dotted },
    { value: 'dashed', label: local.style.dashed },
  ];

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{local.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="p">{local.border}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="number"
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.width}
                  value={values.borderWidth}
                  onChange={(e) => onTextUpdate('borderWidth', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  options={styleOptions}
                  value={styleOptions.find((option) => option.value === values.borderStyle)}
                  onChange={(event, newValue) => onUpdate('borderStyle', newValue ? newValue.value : undefined)}
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      margin="dense"
                      label={local.borderStyle}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <ColorPicker
                  label={local.color}
                  value={values.borderColor}
                  options={colors}
                  onChange={(selected) => onUpdate('borderColor', selected)}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="p">{local.borderTop}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="number"
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.width}
                  value={values.borderTopWidth}
                  onChange={(e) => onTextUpdate('borderTopWidth', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  options={styleOptions}
                  value={styleOptions.find((option) => option.value === values.borderTopStyle)}
                  onChange={(event, newValue) => onUpdate('borderTopStyle', newValue ? newValue.value : undefined)}
                  renderInput={(params) => (
                    <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      margin="dense"
                      label={local.borderStyle}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <ColorPicker
                  label={local.color}
                  value={values.borderTopColor}
                  options={colors}
                  onChange={(selected) => onUpdate('borderTopColor', selected)}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="p">{local.borderRight}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="number"
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.width}
                  value={values.borderRightWidth}
                  onChange={(e) => onTextUpdate('borderRightWidth', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  options={styleOptions}
                  value={styleOptions.find((option) => option.value === values.borderRightStyle)}
                  onChange={(event, newValue) => onUpdate('borderRightStyle', newValue ? newValue.value : undefined)}
                  renderInput={(params) => (
                    <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      margin="dense"
                      label={local.borderStyle}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <ColorPicker
                  label={local.color}
                  value={values.borderRightColor}
                  options={colors}
                  onChange={(selected) => onUpdate('borderRightColor', selected)}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="p">{local.borderBottom}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="number"
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.width}
                  value={values.borderBottomWidth}
                  onChange={(e) => onTextUpdate('borderBottomWidth', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  options={styleOptions}
                  value={styleOptions.find((option) => option.value === values.borderBottomStyle)}
                  onChange={(event, newValue) => onUpdate('borderBottomStyle', newValue ? newValue.value : undefined)}
                  renderInput={(params) => (
                    <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      margin="dense"
                      label={local.borderStyle}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <ColorPicker
                  label={local.color}
                  value={values.borderBottomColor}
                  options={colors}
                  onChange={(selected) => onUpdate('borderBottomColor', selected)}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="p">{local.borderLeft}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="number"
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.width}
                  value={values.borderLeftWidth}
                  onChange={(e) => onTextUpdate('borderLeftWidth', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  options={styleOptions}
                  value={styleOptions.find((option) => option.value === values.borderLeftStyle)}
                  onChange={(event, newValue) => onUpdate('borderLeftStyle', newValue ? newValue.value : undefined)}
                  renderInput={(params) => (
                    <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      margin="dense"
                      label={local.borderStyle}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <ColorPicker
                  label={local.color}
                  value={values.borderLeftColor}
                  options={colors}
                  onChange={(selected) => onUpdate('borderLeftColor', selected)}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="p">{local.radius}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="number"
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.borderTopLeftRadius}
                  value={values.borderTopLeftRadius}
                  onChange={(e) => onTextUpdate('borderTopLeftRadius', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="number"
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.borderTopRightRadius}
                  value={values.borderTopRightRadius}
                  onChange={(e) => onTextUpdate('borderTopRightRadius', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="number"
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.borderBottomLeftRadius}
                  value={values.borderBottomLeftRadius}
                  onChange={(e) => onTextUpdate('borderBottomLeftRadius', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="number"
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.borderBottomRightRadius}
                  value={values.borderBottomRightRadius}
                  onChange={(e) => onTextUpdate('borderBottomRightRadius', e.target.value)}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </AccordionDetails>
    </Accordion>
  );
}
DesignerBorders.propTypes = {
  values: PropTypes.shape({
    border: PropTypes.string,
    borderColor: PropTypes.number,
    borderStyle: PropTypes.string,
    borderWidth: PropTypes.string,
    borderTop: PropTypes.string,
    borderTopColor: PropTypes.number,
    borderTopStyle: PropTypes.string,
    borderTopWidth: PropTypes.string,
    borderRight: PropTypes.string,
    borderRightColor: PropTypes.number,
    borderRightStyle: PropTypes.string,
    borderRightWidth: PropTypes.string,
    borderBottom: PropTypes.string,
    borderBottomColor: PropTypes.number,
    borderBottomStyle: PropTypes.string,
    borderBottomWidth: PropTypes.string,
    borderLeft: PropTypes.string,
    borderLeftColor: PropTypes.number,
    borderLeftStyle: PropTypes.string,
    borderLeftWidth: PropTypes.string,
    borderTopLeftRadius: PropTypes.string,
    borderTopRightRadius: PropTypes.string,
    borderBottomRightRadius: PropTypes.string,
    borderBottomLeftRadius: PropTypes.string,
  }).isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({})),
  onUpdate: PropTypes.func.isRequired,
  onTextUpdate: PropTypes.func.isRequired,
};

DesignerBorders.defaultProps = {
  colors: undefined,
};
