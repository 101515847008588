import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { View, Text, Image } from '@react-pdf/renderer';

const placeholder = 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr,'
  + ' sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,'
  + ' sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.'
  + ' Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.';

const getPlaceholder = (size) => {
  let placeholderText = placeholder;
  while (placeholderText.length < size) {
    placeholderText += placeholder;
  }
  return placeholderText.slice(0, size);
};

export default function DocumentElement({
  colors,
  elements,
  element,
  flexElementId,
  values,
  page,
  fixed,
  variables,
  designerImages,
  documentImages,
  cityId,
  cityVariables,
  offset,
}) {
  const render = () => {
    let top;
    if (element.layout.top && offset && element.layout.topoffset) {
      top = `${parseFloat(element.layout.top) + parseFloat(offset)}mm`;
    } else if (element.layout.top) {
      top = `${element.layout.top}mm`;
    }
    let right;
    if (element.layout.right && offset && element.layout.rightoffset) {
      right = `${parseFloat(element.layout.right) + parseFloat(offset)}mm`;
    } else if (element.layout.right) {
      right = `${element.layout.right}mm`;
    }
    let bottom;
    if (element.layout.bottom && offset && element.layout.bottomoffset) {
      bottom = `${parseFloat(element.layout.bottom) + parseFloat(offset)}mm`;
    } else if (element.layout.bottom) {
      bottom = `${element.layout.bottom}mm`;
    }
    let left;
    if (element.layout.left && offset && element.layout.leftoffset) {
      left = `${parseFloat(element.layout.left) + parseFloat(offset)}mm`;
    } else if (element.layout.left) {
      left = `${element.layout.left}mm`;
    }
    let paddingTop;
    if (element.position.paddingTop && top && offset && !element.layout.topoffset && ['VIEW', 'FLEXVIEW'].includes(element.type)) {
      paddingTop = `${parseFloat(element.position.paddingTop) + parseFloat(offset)}mm`;
    } else if (top && offset && !element.layout.topoffset && ['VIEW', 'FLEXVIEW'].includes(element.type)) {
      paddingTop = `${parseFloat(offset)}mm`;
    } else if (element.position.paddingTop) {
      paddingTop = `${element.position.paddingTop}mm`;
    }
    let paddingRight;
    if (element.position.paddingRight && right && offset && !element.layout.rightoffset && ['VIEW', 'FLEXVIEW'].includes(element.type)) {
      paddingRight = `${parseFloat(element.position.paddingRight) + parseFloat(offset)}mm`;
    } else if (right && offset && !element.layout.rightoffset && ['VIEW', 'FLEXVIEW'].includes(element.type)) {
      paddingRight = `${parseFloat(offset)}mm`;
    } else if (element.position.paddingRight) {
      paddingRight = `${element.position.paddingRight}mm`;
    }
    let paddingBottom;
    if (element.position.paddingBottom && bottom && offset && !element.layout.bottomoffset && ['VIEW', 'FLEXVIEW'].includes(element.type)) {
      paddingBottom = `${parseFloat(element.position.paddingBottom) + parseFloat(offset)}mm`;
    } else if (bottom && offset && !element.layout.bottomoffset && ['VIEW', 'FLEXVIEW'].includes(element.type)) {
      paddingBottom = `${parseFloat(offset)}mm`;
    } else if (element.position.paddingBottom) {
      paddingBottom = `${element.position.paddingBottom}mm`;
    }
    let paddingLeft;
    if (element.position.paddingLeft && left && offset && !element.layout.leftoffset && ['VIEW', 'FLEXVIEW'].includes(element.type)) {
      paddingLeft = `${parseFloat(element.position.paddingLeft) + parseFloat(offset)}mm`;
    } else if (left && offset && !element.layout.leftoffset && ['VIEW', 'FLEXVIEW'].includes(element.type)) {
      paddingLeft = `${parseFloat(offset)}mm`;
    } else if (element.position.paddingLeft) {
      paddingLeft = `${element.position.paddingLeft}mm`;
    }
    let height;
    const isInNeedOfHeightAdjustment = offset && element.layout.position === 'absolute' && (!element.layout.topoffset && !element.layout.bottomoffset);
    if (element.dimension.fullHeight) {
      height = '100%';
    } else if (element.dimension.height && isInNeedOfHeightAdjustment) {
      height = `${parseFloat(element.dimension.height) + parseFloat(offset)}mm`;
    } else if (element.dimension.height) {
      height = `${element.dimension.height}mm`;
    }
    let width;
    const isInNeedOfWidthAdjustment = offset && element.layout.position === 'absolute' && (!element.layout.leftoffset && !element.layout.rightoffset);
    if (element.dimension.fullWidth) {
      width = '100%';
    } else if (element.dimension.width && isInNeedOfWidthAdjustment) {
      width = `${parseFloat(element.dimension.width) + parseFloat(offset)}mm`;
    } else if (element.dimension.width) {
      width = `${element.dimension.width}mm`;
    }
    // console.log({
    //   name: element.name, top, right, bottom, left, height, width, paddingTop, paddingRight, paddingBottom, paddingLeft, element,
    // });
    const style = {
      alignContent: element.flexbox.alignContent,
      alignItems: element.flexbox.alignItems,
      alignSelf: element.flexbox.alignSelf,
      flex: element.flexbox.flex,
      flexDirection: element.flexbox.flexDirection,
      flexWrap: element.flexbox.flexWrap,
      flexFlow: element.flexbox.flexFlow,
      flexGrow: element.flexbox.flexGrow,
      flexShrink: element.flexbox.flexShrink,
      flexBasis: element.flexbox.flexBasis,
      justifyContent: element.flexbox.justifyContent,
      order: element.flexbox.order,
      display: element.layout.display,
      top,
      right,
      bottom,
      left,
      position: element.layout.position,
      overflow: element.layout.overflow,
      zIndex: element.layout.zIndex,
      height,
      minHeight: element.dimension.minHeight ? `${element.dimension.minHeight}mm` : undefined,
      maxHeight: element.dimension.maxHeight ? `${element.dimension.maxHeight}mm` : undefined,
      width,
      minWidth: element.dimension.minWidth ? `${element.dimension.minWidth}mm` : undefined,
      maxWidth: element.dimension.maxWidth ? `${element.dimension.maxWidth}mm` : undefined,
      marginTop: element.position.marginTop ? `${element.position.marginTop}mm` : undefined,
      marginRight: element.position.marginRight ? `${element.position.marginRight}mm` : undefined,
      marginBottom: element.position.marginBottom ? `${element.position.marginBottom}mm` : undefined,
      marginLeft: element.position.marginLeft ? `${element.position.marginLeft}mm` : undefined,
      paddingTop,
      paddingRight,
      paddingBottom,
      paddingLeft,
      backgroundColor: colors[element.color.backgroundColor],
      color: colors[element.color.color],
      opacity: element.color.opacity ? (element.color.opacity / 100) : undefined,
      fontSize: element.text.fontSize ? `${element.text.fontSize}pt` : undefined,
      fontFamily: element.text.fontFamily,
      fontWeight: element.text.fontWeight,
      fontStyle: element.text.fontStyle,
      letterSpacing: element.text.letterSpacing ? `${element.text.letterSpacing}pt` : undefined,
      lineHeight: element.text.lineHeight && element.text.fontSize
        ? `${parseFloat(element.text.lineHeight) / parseFloat(element.text.fontSize)}pt`
        : undefined,
      maxLines: element.text.maxLines,
      textAlign: element.text.textAlign,
      textDecoration: element.text.textDecoration,
      textDecorationColor: colors[element.text.textDecorationColor],
      textDecorationStyle: element.text.textDecorationStyle,
      textIndent: element.text.textIndent ? `${element.text.textIndent}mm` : undefined,
      textOverflow: element.text.textOverflow,
      textTransform: element.text.textTransform,
      transform: element.transformations.transformRotate ? `rotate(${element.transformations.transformRotate}deg)` : undefined,
      borderColor: colors[element.borders.borderColor],
      borderStyle: element.borders.borderStyle,
      borderWidth: element.borders.borderWidth ? `${element.borders.borderWidth}pt` : undefined,
      borderTopColor: colors[element.borders.borderTopColor],
      borderTopStyle: element.borders.borderTopStyle,
      borderTopWidth: element.borders.borderTopWidth ? `${element.borders.borderTopWidth}pt` : undefined,
      borderRightColor: colors[element.borders.borderRightColor],
      borderRightStyle: element.borders.borderRightStyle,
      borderRightWidth: element.borders.borderRightWidth ? `${element.borders.borderRightWidth}pt` : undefined,
      borderBottomColor: colors[element.borders.borderBottomColor],
      borderBottomStyle: element.borders.borderBottomStyle,
      borderBottomWidth: element.borders.borderBottomWidth ? `${element.borders.borderBottomWidth}pt` : undefined,
      borderLeftColor: colors[element.borders.borderLeftColor],
      borderLeftStyle: element.borders.borderLeftStyle,
      borderLeftWidth: element.borders.borderLeftWidth ? `${element.borders.borderLeftWidth}pt` : undefined,
      borderTopLeftRadius: element.borders.borderTopLeftRadius ? `${element.borders.borderTopLeftRadius}mm` : undefined,
      borderTopRightRadius: element.borders.borderTopRightRadius ? `${element.borders.borderTopRightRadius}mm` : undefined,
      borderBottomRightRadius: element.borders.borderBottomRightRadius ? `${element.borders.borderBottomRightRadius}mm` : undefined,
      borderBottomLeftRadius: element.borders.borderBottomLeftRadius ? `${element.borders.borderBottomLeftRadius}mm` : undefined,
    };
    if (element.type === 'TEXT') {
      let text;
      if (element.editable) {
        if (values && flexElementId && values[`${flexElementId}--${element.id}`]) {
          text = values[`${flexElementId}--${element.id}`];
        } else if (values && values[element.id]) {
          text = values[element.id];
        } else {
          text = getPlaceholder(element.value);
        }
      } else {
        text = element.value;
        if (text && variables) {
          variables.forEach((variable) => {
            text = text.replace(`%${variable.name}%`, cityVariables && cityVariables[variable.name] ? cityVariables[variable.name] : '');
          });
        }
      }
      return <Text fixed={fixed} style={style}>{text === '' ? ' ' : text}</Text>;
    }
    if (element.type === 'IMAGE') {
      return (
        <Image
          fixed={fixed}
          style={style}
          cache={false}
          src={async () => {
            const valuesId = flexElementId ? `${flexElementId}--${element.id}` : element.id;
            const isDocumentImage = values && values[valuesId];
            const imageId = isDocumentImage ? values[valuesId] : element.id;
            const image = isDocumentImage ? documentImages[imageId] : designerImages[imageId];
            if (!isDocumentImage) {
              console.debug({
                imageId,
                image,
                designerImages,
              });
            }
            return image ? Buffer.from(image) : `${process.env.PUBLIC_URL}/placeholder.jpg`;
          }}
        />
      );
    }
    if (element.type === 'FLEXIMAGE') {
      return (
        <Image
          fixed={fixed}
          style={style}
          cache={false}
          src={async () => {
            const image = designerImages[`${element.id}--${cityId}`] || designerImages[element.id];
            return image ? Buffer.from(image) : `${process.env.PUBLIC_URL}/placeholder.jpg`;
          }}
        />
      );
    }
    if (element.type === 'PAGENUMBER') {
      return <Text fixed={fixed} style={style}>{page.toString()}</Text>;
    }
    if (element.type === 'OVERLAYTEXT') {
      return <Text fixed={fixed} style={style}>{getPlaceholder(element.value)}</Text>;
    }
    if (element.type === 'FLEXVIEW') {
      return (
        <View fixed={fixed} style={style}>
          { values && values[element.id] && values[element.id].map((flexElement) => (
            <DocumentElement
              key={flexElement.flexElementId}
              flexElementId={flexElement.flexElementId}
              colors={colors}
              elements={elements}
              element={elements.find((item) => item.id === flexElement.id)}
              page={page}
              values={values}
              fixed={fixed}
              designerImages={designerImages}
              documentImages={documentImages}
              variables={variables}
              cityVariables={cityVariables}
              offset={offset}
            />
          ))}
        </View>
      );
    }
    return (
      <View fixed={fixed} style={style}>
        { elements
          .filter((item) => item.parent === element.id)
          .sort((a, b) => moment(a.createdAt).diff(b.createdAt))
          .map((item) => (
            <DocumentElement
              key={item.id}
              colors={colors}
              elements={elements}
              element={item}
              flexElementId={flexElementId}
              page={page}
              values={values}
              fixed={fixed}
              designerImages={designerImages}
              documentImages={documentImages}
              variables={variables}
              cityVariables={cityVariables}
              offset={offset}
            />
          ))}
      </View>
    );
  };

  return render();
}

DocumentElement.propTypes = {
  colors: PropTypes.shape({}),
  values: PropTypes.shape({}),
  variables: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  designerImages: PropTypes.shape({}),
  documentImages: PropTypes.shape({}),
  flexElementId: PropTypes.string,
  cityId: PropTypes.string,
  cityVariables: PropTypes.shape({}),
  element: PropTypes.shape({
    flexbox: PropTypes.shape({
      alignContent: PropTypes.string,
      alignItems: PropTypes.string,
      alignSelf: PropTypes.string,
      flex: PropTypes.string,
      flexDirection: PropTypes.string,
      flexWrap: PropTypes.string,
      flexFlow: PropTypes.string,
      flexGrow: PropTypes.string,
      flexShrink: PropTypes.string,
      flexBasis: PropTypes.string,
      justifyContent: PropTypes.string,
      order: PropTypes.string,
    }).isRequired,
    layout: PropTypes.shape({
      bottom: PropTypes.string,
      display: PropTypes.string,
      left: PropTypes.string,
      position: PropTypes.string,
      right: PropTypes.string,
      top: PropTypes.string,
      overflow: PropTypes.string,
      zIndex: PropTypes.string,
    }).isRequired,
    dimension: PropTypes.shape({
      height: PropTypes.string,
      maxHeight: PropTypes.string,
      maxWidth: PropTypes.string,
      minHeight: PropTypes.string,
      minWidth: PropTypes.string,
      width: PropTypes.string,
    }).isRequired,
    color: PropTypes.shape({
      backgroundColor: PropTypes.number,
      color: PropTypes.number,
      opacity: PropTypes.string,
    }).isRequired,
    text: PropTypes.shape({
      fontSize: PropTypes.string,
      fontFamily: PropTypes.string,
      fontStyle: PropTypes.string,
      fontWeight: PropTypes.string,
      letterSpacing: PropTypes.string,
      lineHeight: PropTypes.string,
      maxLines: PropTypes.string,
      textAlign: PropTypes.string,
      textDecoration: PropTypes.string,
      textDecorationColor: PropTypes.string,
      textDecorationStyle: PropTypes.string,
      textIndent: PropTypes.string,
      textOverflow: PropTypes.string,
      textTransform: PropTypes.string,
    }).isRequired,
    position: PropTypes.shape({
      marginTop: PropTypes.string,
      marginRight: PropTypes.string,
      marginBottom: PropTypes.string,
      marginLeft: PropTypes.string,
      paddingTop: PropTypes.string,
      paddingRight: PropTypes.string,
      paddingBottom: PropTypes.string,
      paddingLeft: PropTypes.string,
    }).isRequired,
    transformations: PropTypes.shape({
      transformRotate: PropTypes.string,
    }).isRequired,
    borders: PropTypes.shape({
      borderColor: PropTypes.number,
      borderStyle: PropTypes.string,
      borderWidth: PropTypes.string,
      borderTopColor: PropTypes.number,
      borderTopStyle: PropTypes.string,
      borderTopWidth: PropTypes.string,
      borderRightColor: PropTypes.number,
      borderRightStyle: PropTypes.string,
      borderRightWidth: PropTypes.string,
      borderBottomColor: PropTypes.number,
      borderBottomStyle: PropTypes.string,
      borderBottomWidth: PropTypes.string,
      borderLeftColor: PropTypes.number,
      borderLeftStyle: PropTypes.string,
      borderLeftWidth: PropTypes.string,
      borderTopLeftRadius: PropTypes.string,
      borderTopRightRadius: PropTypes.string,
      borderBottomRightRadius: PropTypes.string,
      borderBottomLeftRadius: PropTypes.string,
    }).isRequired,
  }).isRequired,
  page: PropTypes.number.isRequired,
  offset: PropTypes.string,
  fixed: PropTypes.bool,
};

DocumentElement.defaultProps = {
  cityId: undefined,
  colors: {},
  designerImages: {},
  documentImages: {},
  offset: undefined,
  fixed: undefined,
  values: undefined,
};
