import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';

export default function DocumentCropmark({
  top, right, bottom, left, offset,
}) {
  return (
    <View
      fixed
      style={{
        position: 'absolute',
        height: `${offset}mm`,
        width: `${offset}mm`,
        top: top ? 0 : undefined,
        right: right ? 0 : undefined,
        bottom: bottom ? 0 : undefined,
        left: left ? 0 : undefined,
      }}
    >
      <View
        fixed
        style={{
          position: 'absolute',
          top: bottom ? '1mm' : 0,
          right: 0,
          bottom: top ? '1mm' : 0,
          left: 0,
          borderRight: left ? '0.5pt solid black' : undefined,
          borderLeft: right ? '0.5pt solid black' : undefined,
        }}
      />
      <View
        fixed
        style={{
          position: 'absolute',
          top: 0,
          right: left ? '1mm' : 0,
          bottom: 0,
          left: right ? '1mm' : 0,
          borderTop: bottom ? '0.5pt solid black' : undefined,
          borderBottom: top ? '0.5pt solid black' : undefined,
        }}
      />
    </View>
  );
}

DocumentCropmark.propTypes = {
  bottom: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  top: PropTypes.bool,
  offset: PropTypes.number,
};

DocumentCropmark.defaultProps = {
  bottom: undefined,
  left: undefined,
  right: undefined,
  top: undefined,
  offset: undefined,
};
