import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import emit from '../../../../util/emit';
import localization from './CitiesCreate.local';

export default function CitiesCreate({ onCreate }) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [alert, setAlert] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    emit(socket, setAlert, 'cities.post', { name, description }, (newcity) => {
      onCreate(newcity);
      setName('');
      setDescription('');
      setExpanded(false);
    });
  };

  return (
    <Accordion sx={{ mb: 2 }} expanded={expanded} onChange={(e, state) => setExpanded(state)}>
      <AccordionSummary sx={{ backgroundColor: 'primary.main', color: 'white' }} expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
        {local.create}
      </AccordionSummary>
      <AccordionDetails>
        {expanded && (
          <Box component="form" onSubmit={onSubmit}>
            <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.name}
                  value={name}
                  onChange={(e) => setName(e.target.value.toUpperCase())}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={0} md={6} lg={8} />
              <Grid item xs={12} md={6} lg={4}>
                <Button fullWidth sx={{ marginTop: 2 }} color="primary" variant="contained" type="submit">{local.create}</Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

CitiesCreate.propTypes = {
  onCreate: PropTypes.func.isRequired,
};
