import React from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import ColorsParlette from './ColorsParlette';
import ColorsEdit from './ColorsEdit';

export default function Colors({
  colors,
  onSetColors,
}) {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        exact
        path=":colorid"
        element={(
          <ColorsEdit
            colors={colors}
            onSetColors={onSetColors}
            onBack={() => navigate('/designer/colors')}
          />
        )}
      />
      <Route
        path="/"
        element={(
          <ColorsParlette
            colors={colors}
            onClickColor={(colorid) => navigate(`/designer/colors/${colorid}`)}
            onSetColors={onSetColors}
          />
        )}
      />
    </Routes>
  );
}

Colors.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.shape({})),
  onSetColors: PropTypes.func.isRequired,
};

Colors.defaultProps = {
  colors: undefined,
};
