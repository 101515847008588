export default {
  de: {
    title: 'Neues Dokument',
    name: 'Name',
    back: 'Zurück',
    create: 'Dokument erstellen',
    createPage: 'Seite',
    documentcategory: 'Dokument Kategorie',
    documenttype: 'Dokumentvorlage',
    documentcategoriesLoading: 'Dokument Kategorien werden geladen...',
    documenttypesLoading: 'Dokumenttypen werden geladen...',
  },
};
