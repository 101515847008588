import React, { useState, useEffect } from 'react';
import {
  Routes, Route, useNavigate, useParams,
} from 'react-router-dom';
import PropTypes from 'prop-types';

import Documenttypes from './documenttypes/Documenttypes';
import Documenttype from './documenttype/Documenttype';

export default function Documentcategory({
  colors,
  documentcategories,
  documenttypes,
  formats,
  variables,
  onSetDocumentcategories,
  onSetDocumenttypes,
  onBack,
}) {
  const navigate = useNavigate();
  const { documentcategoryid } = useParams();
  const [documentcategory, setDocumentcategory] = useState();

  useEffect(() => {
    if (documentcategories && documentcategoryid) {
      const foundDocumentcategory = documentcategories.find((item) => item.id === parseInt(documentcategoryid, 10));
      if (foundDocumentcategory) {
        setDocumentcategory(foundDocumentcategory);
      } else {
        onBack();
      }
    }
  }, [documentcategories, documentcategoryid]);

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={(
          <Documenttypes
            documentcategory={documentcategory}
            documentcategories={documentcategories}
            documenttypes={documenttypes}
            onBack={() => navigate('/designer/documentcategories/')}
            onClick={(documenttypeid) => navigate(`/designer/documentcategories/${documentcategoryid}/${documenttypeid}`)}
            onSetDocumentcategories={onSetDocumentcategories}
            onSetDocumenttypes={onSetDocumenttypes}
          />
        )}
      />
      <Route
        path=":documenttypeid/*"
        element={(
          <Documenttype
            colors={colors}
            documentcategory={documentcategory}
            documenttypes={documenttypes}
            formats={formats}
            variables={variables}
            onBack={() => navigate(`/designer/documentcategories/${documentcategoryid}`)}
            onSetDocumenttypes={onSetDocumenttypes}
          />
        )}
      />
    </Routes>
  );
}

Documentcategory.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.shape({})),
  documentcategories: PropTypes.arrayOf(PropTypes.shape({})),
  documenttypes: PropTypes.arrayOf(PropTypes.shape({})),
  formats: PropTypes.arrayOf(PropTypes.shape({})),
  variables: PropTypes.arrayOf(PropTypes.shape({})),
  onSetDocumentcategories: PropTypes.func.isRequired,
  onSetDocumenttypes: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

Documentcategory.defaultProps = {
  colors: undefined,
  documentcategories: undefined,
  documenttypes: undefined,
  formats: undefined,
  variables: undefined,
};
