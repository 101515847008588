import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { usePDF, PDFViewer } from '@react-pdf/renderer';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

import PDFDocument from '../../../../../components/Document/Document';

import DocumentPrinterHeader from './DocumentPrinterHeader';
import DocumentPrinterEditor from './DocumentPrinterEditor';

export default function DocumentPrinter({
  colors,
  cityVariables,
  designerImages,
  document,
  documentImages,
  documenttype,
  elements,
  formats,
  pagedefinitions,
  printers,
  onBack,
  onSend,
  variables,
}) {
  const [pdfDocument, setPdfDocument] = useState();
  const [pdf, updatePdf] = usePDF({ document: pdfDocument });
  const [printer, setPrinter] = useState(printers ? printers[0] : undefined);
  const [printouts, setPrintouts] = useState(1);

  useEffect(() => {
    if (!printer && printers) {
      setPrinter(printers[0]);
    }
  }, [printers]);

  useEffect(() => {
    if (colors && cityVariables && document && documenttype && elements && formats && variables) {
      setPdfDocument(
        <PDFDocument
          cityVariables={cityVariables}
          colors={colors}
          cropmarks={printer ? printer.cropmarks : undefined}
          designerImages={designerImages}
          documentImages={documentImages}
          elements={elements}
          format={formats.find((item) => item.id === documenttype.format)}
          offset={printer ? printer.offset : undefined}
          orientation={documenttype.orientation}
          overlay={documenttype.id}
          pagedefinitions={pagedefinitions}
          pages={document.pages}
          title={documenttype.name}
          variables={variables}
        />,
      );
    }
  }, [
    colors,
    cityVariables,
    designerImages,
    document,
    documentImages,
    documenttype,
    elements,
    formats,
    printer,
    variables,
  ]);

  useEffect(() => updatePdf(), [pdfDocument]);

  return (
    <div>
      <Grid container spacing={1} sx={{ p: 1 }}>
        <Grid item xs={12}>
          { document ? (
            <DocumentPrinterHeader
              isReady={printer && !(pdf.loading || pdf.error)}
              name={document.name}
              onBack={onBack}
              onSend={() => onSend(pdf.blob, printer, printouts)}
            />
          ) : <Skeleton variant="rect" /> }
        </Grid>
        <Grid item xs={8}>
          { document && documenttype && elements ? (
            <PDFViewer width="100%" height="850px" style={{ border: 'none' }}>
              {pdfDocument}
            </PDFViewer>
          ) : <Skeleton variant="rect" /> }
        </Grid>
        <Grid item xs={4}>
          <DocumentPrinterEditor
            printers={printers}
            printer={printer}
            printouts={printouts}
            onSetPrinter={setPrinter}
            onSetPrintouts={setPrintouts}
          />
        </Grid>
      </Grid>
    </div>
  );
}

DocumentPrinter.propTypes = {
  cityVariables: PropTypes.shape({}),
  colors: PropTypes.arrayOf(PropTypes.shape({})),
  designerImages: PropTypes.shape({}),
  document: PropTypes.shape({
    name: PropTypes.string,
    pages: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  documentImages: PropTypes.shape({}),
  documenttype: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    orientation: PropTypes.string,
    format: PropTypes.number,
    maxPages: PropTypes.number,
    minPages: PropTypes.number,
    newPages: PropTypes.number,
  }),
  elements: PropTypes.arrayOf(PropTypes.shape({})),
  formats: PropTypes.arrayOf(PropTypes.shape({})),
  pagedefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  printers: PropTypes.arrayOf(PropTypes.shape({})),
  onBack: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  variables: PropTypes.arrayOf(PropTypes.shape({})),
};

DocumentPrinter.defaultProps = {
  cityVariables: undefined,
  colors: undefined,
  designerImages: undefined,
  document: undefined,
  documentImages: undefined,
  documenttype: undefined,
  elements: undefined,
  formats: undefined,
  pagedefinitions: undefined,
  printers: undefined,
  variables: undefined,
};
