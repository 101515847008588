import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { LocalizationContext, SocketContext } from '../../../../../../AppContext';
import Snackbar from '../../../../../../components/Snackbar';
import emit from '../../../../../../util/emit';
import localization from './Documenttypes.local';

export default function DocumentcategoryCards({
  documentcategory,
  documentcategories,
  documenttypes,
  onBack,
  onClick,
  onSetDocumentcategories,
  onSetDocumenttypes,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [typingTimeout, setTypingTimeout] = useState();
  const [alert, setAlert] = useState();

  useEffect(() => {
    if (documentcategory) {
      setName(documentcategory.name);
      setDescription(documentcategory.description);
    }
  }, [documentcategory]);

  const onCreateSuccess = (newDocumenttype) => {
    onSetDocumenttypes([...documenttypes, newDocumenttype]);
  };
  const onCreate = () => {
    emit(socket, setAlert, 'documenttypes.post', { name: local.newName, documentcategory: documentcategory.id }, onCreateSuccess);
  };

  const onUpdateSuccess = (updatedDocumentcategory) => {
    onSetDocumentcategories(documentcategories.map((item) => (item.id === updatedDocumentcategory.id ? updatedDocumentcategory : item)));
  };
  const onUpdate = (field, value) => {
    emit(socket, setAlert, 'documentcategories.patch', { id: documentcategory.id, field, value }, onUpdateSuccess);
  };

  const onTextChange = (setter, field, value) => {
    setter(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => onUpdate(field, value), 1000));
  };

  return documentcategory ? (
    <Box>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Box
        sx={{
          backgroundColor: 'background.paper',
          boxShadow: '0 1px 3px rgb(0 0 0 / 10%)',
          borderRight: '1px solid rgba(0, 0, 0, 0.12)',
          position: 'fixed',
          zIndex: 100,
          top: 43,
          left: 200,
          bottom: 0,
          width: 250,
          padding: 2,
        }}
      >
        <Grid container spacing={2} sx={{ pt: 2 }}>
          <Grid item xs={12}>
            <Typography variant="h1">{local.category}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.name}
              value={name}
              onChange={(e) => onTextChange(setName, 'name', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.description}
              value={description}
              onChange={(e) => onTextChange(setDescription, 'description', e.target.value)}
            />
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <Typography sx={{ m: 1 }}>{local.status}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Switch
                checked={documentcategory.active}
                onChange={(e) => onUpdate('active', e.target.checked)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              onClick={onBack}
            >
              {local.back}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          left: 250,
          top: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <Container maxWidth={false}>
          <Typography variant="h1" sx={{ mt: 4, mb: 2 }}>{local.documenttypes}</Typography>
          <Grid container spacing={2}>
            {documenttypes && documenttypes
              .filter((item) => item.documentcategory === documentcategory.id)
              .map((item) => (
                <Grid key={item.id} item xs={6} md={4} lg={2}>
                  <Card sx={{ width: 1, minHeight: 100 }} onClick={() => onClick(item.id)}>
                    <CardActionArea sx={{ width: 1, minHeight: 100 }}>
                      <CardContent>
                        <Chip
                          size="small"
                          sx={{ position: 'absolute', top: 5, right: 5 }}
                          label={item.active ? local.active : local.inactive}
                          color={item.active ? 'success' : 'secondary'}
                        />
                        <Typography variant="h6" sx={{ pt: 2 }}>{item.name}</Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            <Grid item xs={6} md={4} lg={2}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={onCreate}
                sx={{ height: 100 }}
              >
                {local.create}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  ) : (
    <div /> // TODO LOADING
  );
}

DocumentcategoryCards.propTypes = {
  documentcategory: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    active: PropTypes.bool,
  }),
  documentcategories: PropTypes.arrayOf(PropTypes.shape({})),
  documenttypes: PropTypes.arrayOf(PropTypes.shape({})),
  onBack: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onSetDocumentcategories: PropTypes.func.isRequired,
  onSetDocumenttypes: PropTypes.func.isRequired,
};

DocumentcategoryCards.defaultProps = {
  documentcategory: undefined,
  documentcategories: undefined,
  documenttypes: undefined,
};
