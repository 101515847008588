import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';

import { LocalizationContext } from '../../../../../AppContext';

import localization from './DocumentEditorHeader.local';

export default function DocumentEditorHeader({
  name,
  onBack,
  onPrint,
  onPatch,
}) {
  const local = localization[useContext(LocalizationContext)];

  return (
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <Typography variant="h4">{name}</Typography>
      </Grid>
      <Grid container item spacing={1} xs={4}>
        <Grid item xs={4}>
          <Button fullWidth variant="contained" color="secondary" onClick={() => onBack()}>
            <TurnLeftIcon sx={{ mr: 1 }} />
            <Typography>{local.back}</Typography>
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button fullWidth variant="contained" onClick={onPrint}>
            <PrintIcon sx={{ mr: 1 }} />
            <Typography>{local.print}</Typography>
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button fullWidth variant="contained" onClick={() => { onPatch('status', 0); onBack(); }}>
            <DeleteIcon sx={{ mr: 1 }} />
            <Typography>{local.delete}</Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

DocumentEditorHeader.propTypes = {
  name: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  onPrint: PropTypes.func.isRequired,
  onPatch: PropTypes.func.isRequired,
};
