import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

export default function Examples() {
  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h1">Mustervorlagen</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ m: 1, width: '100%' }}>
            <Button
              fullWidth
              component="a"
              target="_blank"
              rel="noreferrer"
              variant="text"
              color="primary"
              href={`${process.env.PUBLIC_URL}/Musterfolder_GRW_2022_210x210mm.pdf`}
              sx={{ height: '150px', fontWeight: 900 }}
            >
              Musterfolder GRW 2022
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
