import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { LocalizationContext } from '../../AppContext';
import localization from './DesignerDimension.local';

export default function DesignerDimension({
  values, onTextUpdate, onUpdate, disabledHeight,
}) {
  const local = localization[useContext(LocalizationContext)];

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{local.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="p">{local.description}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.height}
              value={values.height}
              onChange={(e) => onTextUpdate('height', e.target.value)}
              disabled={disabledHeight}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.width}
              value={values.width}
              onChange={(e) => onTextUpdate('width', e.target.value)}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            {local.fullHeight}
          </Grid>
          <Grid item xs={6} md={3}>
            <Switch
              checked={values.fillHeight}
              onChange={(e) => onUpdate('fillHeight', e.target.checked)}
              disabled={disabledHeight}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            {local.fullWidth}
          </Grid>
          <Grid item xs={6} md={3}>
            <Switch
              checked={values.fullWidth}
              onChange={(e) => onUpdate('fullWidth', e.target.checked)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.minHeight}
              value={values.minHeight}
              onChange={(e) => onTextUpdate('minHeight', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.maxHeight}
              value={values.maxHeight}
              onChange={(e) => onTextUpdate('maxHeight', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.minWidth}
              value={values.minWidth}
              onChange={(e) => onTextUpdate('minWidth', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.maxWidth}
              value={values.maxWidth}
              onChange={(e) => onTextUpdate('maxWidth', e.target.value)}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
DesignerDimension.propTypes = {
  values: PropTypes.shape({
    height: PropTypes.string,
    maxHeight: PropTypes.string,
    maxWidth: PropTypes.string,
    minHeight: PropTypes.string,
    minWidth: PropTypes.string,
    width: PropTypes.string,
    fullWidth: PropTypes.bool,
    fillHeight: PropTypes.bool,
  }).isRequired,
  onTextUpdate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  disabledHeight: PropTypes.bool.isRequired,
};
