import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import DataObjectIcon from '@mui/icons-material/DataObject';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import emit from '../../../../util/emit';
import CitiesContext from '../CitiesContext';
import localization from './Settings.local';

export default function Settings({
  variables,
  userCities,
  onSetUserCities,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const city = useContext(CitiesContext);
  const [variablesState, setVariablesState] = useState(city ? city.variables : {});
  const [typingTimeout, setTypingTimeout] = useState();
  const [alert, setAlert] = useState();

  useEffect(() => setVariablesState(city ? city.variables : {}), [city]);

  const onSuccess = (updatedCity) => {
    onSetUserCities(userCities.map((item) => (item.id === updatedCity.id ? updatedCity : item)));
  };
  const onChange = (field, value) => {
    emit(socket, setAlert, 'cities.patch', { id: city.id, field, value }, onSuccess);
  };

  const onTextChange = (setter, field, value) => {
    setter(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => onChange(field, value), 1000));
  };

  return (
    <Container sx={{ pt: 3 }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Typography variant="h1" sx={{ mb: 2 }}>{local.title}</Typography>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <DataObjectIcon />
          <Typography sx={{ ml: 2 }}>{local.variables}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {variables && variables.map((variable) => (
              <TextField
                fullWidth
                variant="outlined"
                color="secondary"
                margin="dense"
                key={variable.id}
                label={variable.name}
                value={variablesState[variable.name] || ''}
                onChange={(e) => onTextChange(setVariablesState, 'variables', { ...variablesState, [variable.name]: e.target.value })}
              />
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}

Settings.propTypes = {
  variables: PropTypes.arrayOf(PropTypes.shape({})),
  userCities: PropTypes.arrayOf(PropTypes.shape({})),
  onSetUserCities: PropTypes.func.isRequired,
};

Settings.defaultProps = {
  variables: undefined,
  userCities: undefined,
};
