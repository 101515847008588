import React, { useContext, useState, useEffect } from 'react';
import {
  useNavigate, Routes, Route, Navigate,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import { LocalizationContext, SocketContext, UserContext } from '../../AppContext';
import get from '../../util/get';
import syn from '../../util/syn';
import join from '../../util/join';
import Snackbar from '../../components/Snackbar';

import Dashboard from './dashboard/Dashboard';
import Profile from './profile/Profile';
import Designer from './designer/Designer';
import Examples from './examples/Examples';
import Organisation from './organisation/Organisation';
import Cities from './cities/Cities';
import AuthenticatedMenu from './AuthenticatedMenu';
import AuthenticatedBar from './AuthenticatedBar';
import localization from './Authenticated.local';

export default function Authenticated({ onUpdateUser, onLogout }) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [announcements, setAnnouncements] = useState();
  const [colors, setColors] = useState();
  const [documentcategories, setDocumentcategories] = useState();
  const [documents, setDocuments] = useState();
  const [documenttypes, setDocumenttypes] = useState();
  const [formats, setFormats] = useState();
  const [messages, setMessages] = useState();
  const [postings, setPostings] = useState();
  const [postingtypes, setPostingtypes] = useState();
  const [printers, setPrinters] = useState();
  const [supports, setSupports] = useState();
  const [userCities, setUserCities] = useState();
  const [variables, setVariables] = useState();
  const [alert, setAlert] = useState();

  useEffect(() => {
    get(socket, setAlert, 'announcements', announcements, setAnnouncements);
    get(socket, setAlert, 'colors', colors, setColors);
    get(socket, setAlert, 'documentcategories', documentcategories, setDocumentcategories);
    get(socket, setAlert, 'documenttypes', documenttypes, setDocumenttypes);
    get(socket, setAlert, 'formats', formats, setFormats);
    get(socket, setAlert, 'postingtypes', postingtypes, setPostingtypes);
    get(socket, setAlert, 'printers', printers, setPrinters);
    get(socket, setAlert, 'supports', supports, setSupports, user.admin ? 'getAll' : 'getAllByUser');
    get(socket, setAlert, 'cities', userCities, setUserCities, 'getUserCities');
    get(socket, setAlert, 'variables', variables, setVariables);
  }, [socket]);

  useEffect(() => {
    if (userCities) {
      const cities = userCities.map((item) => item.id);
      const documentsCleanup = join(socket, setAlert, 'documents', setDocuments, { cities });
      const messagesCleanup = join(socket, setAlert, 'messages', setMessages, { cities });
      const postingsCleanup = join(socket, setAlert, 'postings', setPostings, { cities });
      return () => {
        documentsCleanup();
        messagesCleanup();
        postingsCleanup();
      };
    }
    return () => {};
  }, [socket, userCities]);

  useEffect(() => syn(socket, setAlert, 'announcements', announcements, setAnnouncements), [socket, announcements]);
  useEffect(() => syn(socket, setAlert, 'colors', colors, setColors), [socket, colors]);
  useEffect(() => syn(socket, setAlert, 'documentcategories', documentcategories, setDocumentcategories), [socket, documentcategories]);
  useEffect(() => syn(socket, setAlert, 'documents', documents, setDocuments), [socket, messages]);
  useEffect(() => syn(socket, setAlert, 'documenttypes', documenttypes, setDocumenttypes), [socket, documenttypes]);
  useEffect(() => syn(socket, setAlert, 'formats', formats, setFormats), [socket, formats]);
  useEffect(() => syn(socket, setAlert, 'messages', messages, setMessages), [socket, messages]);
  useEffect(() => syn(socket, setAlert, 'postings', postings, setPostings), [socket, postings]);
  useEffect(() => syn(socket, setAlert, 'postingtypes', postingtypes, setPostingtypes), [socket, postingtypes]);
  useEffect(() => syn(socket, setAlert, 'printers', printers, setPrinters), [socket, printers]);
  useEffect(() => syn(socket, setAlert, 'supports', supports, setSupports), [socket, supports]);
  useEffect(() => syn(socket, setAlert, 'variables', variables, setVariables), [socket, variables]);

  return (
    <Box>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <AuthenticatedMenu userCities={userCities} />
      <AuthenticatedBar onLogout={onLogout} />
      <Box
        sx={{
          position: 'absolute', left: 200, top: 60, right: 0,
        }}
      >
        <Routes>
          <Route exact path="/profile" element={<Profile onUpdateUser={onUpdateUser} />} />
          <Route exact path="/examples" element={<Examples />} />
          <Route
            path="designer/*"
            element={(
              <Designer
                colors={colors}
                documentcategories={documentcategories}
                documenttypes={documenttypes}
                formats={formats}
                postingtypes={postingtypes}
                variables={variables}
                onSetColors={setColors}
                onSetDocumentcategories={setDocumentcategories}
                onSetDocumenttypes={setDocumenttypes}
                onSetFormats={setFormats}
                onSetPostingtypes={setPostingtypes}
              />
            )}
          />
          <Route
            path="organisation/*"
            element={(
              <Organisation
                printers={printers}
                variables={variables}
                onSetPrinters={setPrinters}
                onSetVariables={setVariables}
              />
            )}
          />
          <Route
            path="cities/:cityid/*"
            element={(
              <Cities
                colors={colors}
                documentcategories={documentcategories}
                documents={documents}
                documenttypes={documenttypes}
                formats={formats}
                messages={messages}
                postings={postings}
                postingtypes={postingtypes}
                printers={printers}
                userCities={userCities}
                variables={variables}
                onCityNotFound={() => navigate('/')}
                onSetDocuments={setDocuments}
                onSetFormats={setFormats}
                onSetMessages={setMessages}
                onSetPostings={setPostings}
                onSetUserCities={setUserCities}
              />
            )}
          />
          <Route
            path="/"
            element={(
              <Dashboard
                announcements={announcements}
                documents={documents}
                postings={postings}
                supports={supports}
                onSetAnnouncements={setAnnouncements}
                onSetSupports={setSupports}
              />
            )}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Box>
    </Box>
  );
}

Authenticated.propTypes = {
  onUpdateUser: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};
