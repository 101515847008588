/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import DesignerBorders from './DesignerBorders';
import DesignerColor from './DesignerColor';
import DesignerDimension from './DesignerDimension';
// import DesignerFlexbox from './DesignerFlexbox';
import DesignerLayout from './DesignerLayout';
import DesignerPosition from './DesignerPosition';
import DesignerText from './DesignerText';
import DesignerTransformations from './DesignerTransformations';

import { LocalizationContext } from '../../AppContext';
import ImageUploader from '../ImageUploader';
import localization from './Designer.local';

export default function Designer({
  city,
  colors,
  element,
  elements,
  onCreate,
  onUpdate,
  onImagesUpload,
  onDestroy,
  isOverlay,
  variables,
}) {
  const local = localization[useContext(LocalizationContext)];
  const [values, setValues] = useState(element);
  const [variable, setVariable] = useState(variables ? variables[0] : undefined);
  const [expanded, setExpanded] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(values);

  useEffect(() => setValues(element), [element]);
  useEffect(() => setVariable(variables ? variables[0] : undefined), [variables]);

  const editableOptions = [{ value: false, label: local.designer }, { value: true, label: local.author }];
  const typeOptions = [
    { value: 'VIEW', label: local.view },
    { value: 'TEXT', label: local.text },
    { value: 'IMAGE', label: local.image },
    { value: 'ARTICLE', label: local.article },
    { value: 'FLEXVIEW', label: local.flexview },
    { value: 'FLEXELEMENT', label: local.flexelement },
    { value: 'FLEXIMAGE', label: local.fleximage },
  ];
  const overlayOptions = [
    { value: 'PAGENUMBER', label: local.pagenumber },
    { value: 'OVERLAYTEXT', label: local.overlaytext },
    { value: 'OVERLAY1', label: local.overlay1 },
    { value: 'OVERLAY2', label: local.overlay2 },
    { value: 'IMPRESSUM', label: local.impressum },
  ];

  const onTextUpdate = (field, value) => {
    setValues({ ...values, [field]: value });
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => onUpdate(element.id, field, value), 1000));
  };

  const renderChildren = () => {
    const children = elements.filter((item) => item.parent === values.id);
    return (
      <Accordion sx={{ mt: 2, mb: 2, backgroundColor: 'background.default' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{local.elements}</Typography>
          <Badge badgeContent={children.length} color="primary" sx={{ ml: 2, mt: 1.5 }} />
        </AccordionSummary>
        <AccordionDetails>
          { children
            .sort((a, b) => moment(a.createdAt).diff(b.createdAt))
            .map((item) => (
              <Designer
                key={item.id}
                colors={colors}
                element={item}
                elements={elements}
                onCreate={onCreate}
                onUpdate={onUpdate}
                onDestroy={onDestroy}
                onImagesUpload={onImagesUpload}
                isOverlay={isOverlay}
                variables={variables}
              />
            ))}
          <Button fullWidth variant="outlined" sx={{ mt: 2, mb: 2 }} onClick={() => onCreate(values.id)}>
            <Typography>{local.createElement}</Typography>
          </Button>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <Accordion expanded={expanded} onChange={(e, state) => setExpanded(state)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{values.name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {expanded && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                color="secondary"
                margin="dense"
                label={local.name}
                value={values.name}
                onChange={(e) => onTextUpdate('name', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                color="secondary"
                margin="dense"
                label={local.description}
                value={values.description}
                onChange={(e) => onTextUpdate('description', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disableClearable
                options={isOverlay ? [...typeOptions, ...overlayOptions] : typeOptions}
                value={(isOverlay ? [...typeOptions, ...overlayOptions] : typeOptions).find((option) => option.value === values.type)}
                onChange={(event, newValue) => onUpdate(values.id, 'type', newValue ? newValue.value : undefined)}
                renderInput={(params) => (
                  <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    margin="dense"
                    label={local.type}
                  />
                )}
              />
            </Grid>
            { (values.type === 'TEXT' || values.type === 'IMAGE') && (
              <Grid item xs={12}>
                <Autocomplete
                  disableClearable
                  options={editableOptions}
                  value={values.editable ? editableOptions[1] : editableOptions[0]}
                  onChange={(event, newValue) => onUpdate(values.id, 'editable', newValue.value)}
                  renderInput={(params) => (
                    <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      margin="dense"
                      label={local.editable}
                    />
                  )}
                />
              </Grid>
            )}
            { values.type === 'IMAGE' && !values.editable && (
              <Grid item xs={12}>
                <ImageUploader
                  id={element.id}
                  file="image/png"
                  label="Bild hochladen" // TODO
                  // onError={(error) => setAlert(error)}
                  onReturn={(image) => onImagesUpload(values.id, image)}
                  aspect={
                    element.dimension && element.dimension.height && element.dimension.width
                      ? (parseFloat(element.dimension.width) / parseFloat(element.dimension.height))
                      : undefined
                  }
                />
              </Grid>
            )}
            { values.type === 'FLEXIMAGE' && (
              <Grid item xs={12}>
                <ImageUploader
                  id={element.id}
                  file="image/png"
                  label={city ? `Bild für ${city.name} hochladen` : 'Default-Bild hochladen'} // TODO
                  // onError={(error) => setAlert(error)}
                  onReturn={(image) => onImagesUpload(city ? `${values.id}--${city.id}` : values.id, image)}
                  aspect={
                    element.dimension && element.dimension.height && element.dimension.width
                      ? (parseFloat(element.dimension.width) / parseFloat(element.dimension.height))
                      : undefined
                  }
                />
              </Grid>
            )}
            { variable && variables && values.type === 'TEXT' && !values.editable && (
              <Grid container item xs={12}>
                <Grid item xs={8}>
                  <Autocomplete
                    disableClearable
                    options={variables}
                    value={variable}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => setVariable(newValue)}
                    sx={{
                      '.MuiOutlinedInput-root': {
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        margin="dense"
                        label={local.variables}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => onUpdate(values.id, 'value', `${values.value ? values.value : ''}%${variable.name}%`)}
                    sx={{
                      mt: 1,
                      height: 55,
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                  >
                    {local.insert}
                  </Button>
                </Grid>
              </Grid>
            )}
            { values.type === 'TEXT' && !values.editable && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.text}
                  value={values.value}
                  onChange={(e) => onTextUpdate('value', e.target.value)}
                />
              </Grid>
            )}
            { ((values.type === 'TEXT' && values.editable) || (values.type === 'OVERLAYTEXT')) && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="number"
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.maxChar}
                  value={values.value}
                  onChange={(e) => onTextUpdate('value', e.target.value)}
                />
              </Grid>
            )}
            { (values.type === 'FLEXVIEW') && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="number"
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.spacing}
                  value={values.value}
                  onChange={(e) => onTextUpdate('value', e.target.value)}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Accordion sx={{ mt: 5, mb: 2, backgroundColor: 'background.default' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{local.basedesign}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <DesignerLayout
                    values={values.layout}
                    onUpdate={(field, value) => onUpdate(values.id, 'layout', { ...values.layout, [field]: value })}
                    onTextUpdate={(field, value) => onTextUpdate('layout', { ...values.layout, [field]: value })}
                  />
                  <DesignerDimension
                    values={values.dimension}
                    onUpdate={(field, value) => onUpdate(values.id, 'dimension', { ...values.dimension, [field]: value })}
                    onTextUpdate={(field, value) => onTextUpdate('dimension', { ...values.dimension, [field]: value })}
                    disabledHeight={(values.type === 'TEXT' || values.type === 'VARIABLE')}
                  />
                  <DesignerPosition
                    values={values.position}
                    onUpdate={(field, value) => onUpdate(values.id, 'position', { ...values.position, [field]: value })}
                    onTextUpdate={(field, value) => onTextUpdate('position', { ...values.position, [field]: value })}
                  />
                  <DesignerColor
                    values={values.color}
                    colors={colors}
                    onUpdate={(field, value) => onUpdate(values.id, 'color', { ...values.color, [field]: value })}
                    onTextUpdate={(field, value) => onTextUpdate('color', { ...values.color, [field]: value })}
                  />
                  <DesignerBorders
                    values={values.borders}
                    colors={colors}
                    onUpdate={(field, value) => onUpdate(values.id, 'borders', { ...values.borders, [field]: value })}
                    onTextUpdate={(field, value) => onTextUpdate('borders', { ...values.borders, [field]: value })}
                  />
                  <DesignerTransformations
                    values={values.transformations}
                    onTextChange={(field, value) => onTextUpdate('transformations', { ...values.transformations, [field]: value })}
                  />
                  {/* <DesignerFlexbox
                    values={values.flexbox}
                    onUpdate={(field, value) => onUpdate(values.id, 'flexbox', { ...values.flexbox, [field]: value })}
                    onTextUpdate={(field, value) => onTextUpdate('flexbox', { ...values.flexbox, [field]: value })}
                  /> */}
                  <DesignerText
                    values={values.text}
                    onUpdate={(field, value) => onUpdate(values.id, 'text', { ...values.text, [field]: value })}
                    onTextUpdate={(field, value) => onTextUpdate('text', { ...values.text, [field]: value })}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              { ['VIEW', 'FLEXVIEW', 'FLEXELEMENT', 'ARTICLE', 'OVERLAY1', 'OVERLAY2', 'IMPRESSUM'].includes(values.type) && renderChildren() }
              <Box display="flex" justifyContent="flex-end" sx={{ p: 1 }}>
                <Button variant="contained" onClick={() => onDestroy(values.id)}>
                  <Typography>{local.destroy}</Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

Designer.propTypes = {
  city: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    type: PropTypes.string.isRequired,
    value: PropTypes.string,
    editable: PropTypes.bool.isRequired,
    flexbox: PropTypes.shape({}).isRequired,
    layout: PropTypes.shape({}).isRequired,
    dimension: PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
    }).isRequired,
    color: PropTypes.shape({}).isRequired,
    text: PropTypes.shape({}).isRequired,
    position: PropTypes.shape({}).isRequired,
    transformations: PropTypes.shape({}).isRequired,
    borders: PropTypes.shape({}).isRequired,
    children: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  elements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  variables: PropTypes.arrayOf(PropTypes.shape({})),
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onImagesUpload: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
  isOverlay: PropTypes.bool,
};

Designer.defaultProps = {
  city: undefined,
  variables: undefined,
  isOverlay: false,
};
