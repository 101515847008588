import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { LocalizationContext } from '../../../../AppContext';
import VariablesChange from './VariablesChange';
import VariablesCreate from './VariablesCreate';
import localization from './Variables.local';

export default function Variables({
  variables,
  onSetVariables,
}) {
  const local = localization[useContext(LocalizationContext)];

  const onCreate = (variable) => onSetVariables([...variables, variable]);
  const onChange = (variable) => onSetVariables(variables.map((item) => (item.id === variable.id ? variable : item)));
  const onDestroy = (variable) => onSetVariables(variables.filter((item) => (item.id !== variable.id)));

  return (
    <Container sx={{ pt: 5 }}>
      <Typography variant="h1" sx={{ mb: 2 }}>{local.title}</Typography>
      <VariablesCreate onCreate={onCreate} />
      {variables && variables.map((variable) => (
        <VariablesChange
          key={variable.id}
          variable={variable}
          onChange={onChange}
          onDestroy={onDestroy}
        />
      ))}
    </Container>
  );
}

Variables.propTypes = {
  variables: PropTypes.arrayOf(PropTypes.shape({})),
  onSetVariables: PropTypes.func.isRequired,
};

Variables.defaultProps = {
  variables: undefined,
};
