import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { LocalizationContext } from '../../AppContext';
import ColorPicker from '../ColorPicker';
import localization from './DesignerText.local';

export default function DesignerText({ values, onUpdate, onTextUpdate }) {
  const local = localization[useContext(LocalizationContext)];

  const textAlignOptions = [
    { value: 'left', label: local.left },
    { value: 'right', label: local.right },
    { value: 'center', label: local.center },
    { value: 'justify', label: local.justify },
  ];
  const textDecorationOptions = [
    { value: 'underline', label: local.underline },
    { value: 'line-through', label: local.lineThrough },
  ];
  const textTransformOptions = [
    { value: 'uppercase', label: local.uppercase },
    { value: 'lowercase', label: local.lowercase },
  ];
  const fontFamilyOptions = [
    { value: 'Roboto', label: local.roboto },
    { value: 'RobotoCondensed', label: local.robotocondensed },
    { value: 'RobotoSlab', label: local.robotoslab },
  ];
  const fontWeightOptions = {
    Roboto: [
      { value: 'thin', label: local.thin },
      { value: 'light', label: local.light },
      { value: 'normal', label: local.normal },
      { value: 'medium', label: local.medium },
      { value: 'bold', label: local.bold },
      { value: 'heavy', label: local.heavy },
    ],
    RobotoCondensed: [
      { value: 'light', label: local.light },
      { value: 'normal', label: local.normal },
      { value: 'bold', label: local.bold },
    ],
    RobotoSlab: [
      { value: 'thin', label: local.thin },
      { value: 'ultralight', label: local.ultralight },
      { value: 'light', label: local.light },
      { value: 'normal', label: local.normal },
      { value: 'medium', label: local.medium },
      { value: 'semibold', label: local.semibold },
      { value: 'bold', label: local.bold },
      { value: 'ultrabold', label: local.ultrabold },
      { value: 'heavy', label: local.heavy },
    ],
  };
  const isFontItalic = {
    Roboto: true,
    RobotoCondensed: true,
    RobotoSlab: false,
  };
  const fontStyleOptions = [
    { value: 'normal', label: local.normal },
    { value: 'italic', label: local.italic },
  ];

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{local.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          fullWidth
          type="number"
          variant="outlined"
          color="secondary"
          margin="dense"
          label={local.fontSize}
          value={values.fontSize}
          onChange={(e) => onTextUpdate('fontSize', e.target.value)}
        />
        <Autocomplete
          options={fontFamilyOptions}
          value={fontFamilyOptions.find((option) => option.value === values.fontFamily)}
          onChange={(event, newValue) => onUpdate('fontFamily', newValue ? newValue.value : undefined)}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              fullWidth
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.fontFamily}
            />
          )}
        />
        <Autocomplete
          options={fontWeightOptions[values.fontFamily || 'Roboto']}
          value={fontWeightOptions[values.fontFamily || 'Roboto'].find((option) => option.value === values.fontWeight)}
          onChange={(event, newValue) => onUpdate('fontWeight', newValue ? newValue.value : undefined)}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              fullWidth
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.fontWeight}
            />
          )}
        />
        { isFontItalic[values.fontFamily || 'Roboto'] && (
          <Autocomplete
            options={fontStyleOptions}
            value={fontStyleOptions.find((option) => option.value === values.fontStyle)}
            onChange={(event, newValue) => onUpdate('fontStyle', newValue ? newValue.value : undefined)}
            renderInput={(params) => (
              <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                fullWidth
                variant="outlined"
                color="secondary"
                margin="dense"
                label={local.fontStyle}
              />
            )}
          />
        )}
        <TextField
          fullWidth
          type="number"
          variant="outlined"
          color="secondary"
          margin="dense"
          label={local.letterSpacing}
          value={values.letterSpacing}
          onChange={(e) => onTextUpdate('letterSpacing', e.target.value)}
        />
        <TextField
          fullWidth
          type="number"
          variant="outlined"
          color="secondary"
          margin="dense"
          label={local.lineHeight}
          value={values.lineHeight}
          onChange={(e) => onTextUpdate('lineHeight', e.target.value)}
        />
        <TextField
          fullWidth
          type="number"
          variant="outlined"
          color="secondary"
          margin="dense"
          label={local.maxLines}
          value={values.maxLines}
          onChange={(e) => onTextUpdate('maxLines', e.target.value)}
        />
        <Autocomplete
          options={textAlignOptions}
          value={textAlignOptions.find((option) => option.value === values.textAlign)}
          onChange={(event, newValue) => onUpdate('textAlign', newValue ? newValue.value : undefined)}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              fullWidth
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.textAlign}
            />
          )}
        />
        <Autocomplete
          options={textDecorationOptions}
          value={textDecorationOptions.find((option) => option.value === values.textDecoration)}
          onChange={(event, newValue) => onUpdate('textDecoration', newValue ? newValue.value : undefined)}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              fullWidth
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.textDecoration}
            />
          )}
        />
        <ColorPicker
          label={local.textDecorationColor}
          value={values.textDecorationColor}
          onChange={(selected) => onUpdate('textDecorationColor', selected)}
        />
        <TextField
          fullWidth
          variant="outlined"
          color="secondary"
          margin="dense"
          label={local.textDecorationStyle}
          value={values.textDecorationStyle}
          onChange={(e) => onTextUpdate('textDecorationStyle', e.target.value)}
        />
        <TextField
          fullWidth
          type="number"
          variant="outlined"
          color="secondary"
          margin="dense"
          label={local.textIndent}
          value={values.textIndent}
          onChange={(e) => onTextUpdate('textIndent', e.target.value)}
        />
        <TextField
          fullWidth
          variant="outlined"
          color="secondary"
          margin="dense"
          label={local.textOverflow}
          value={values.textOverflow}
          onChange={(e) => onTextUpdate('textOverflow', e.target.value)}
        />
        <Autocomplete
          options={textTransformOptions}
          value={textTransformOptions.find((option) => option.value === values.textTransform)}
          onChange={(event, newValue) => onTextUpdate('textTransform', newValue ? newValue.value : undefined)}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              fullWidth
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.textTransform}
            />
          )}
        />
      </AccordionDetails>
    </Accordion>
  );
}
DesignerText.propTypes = {
  values: PropTypes.shape({
    fontSize: PropTypes.string,
    fontFamily: PropTypes.string,
    fontStyle: PropTypes.string,
    fontWeight: PropTypes.string,
    letterSpacing: PropTypes.string,
    lineHeight: PropTypes.string,
    maxLines: PropTypes.string,
    textAlign: PropTypes.string,
    textDecoration: PropTypes.string,
    textDecorationColor: PropTypes.string,
    textDecorationStyle: PropTypes.string,
    textIndent: PropTypes.string,
    textOverflow: PropTypes.string,
    textTransform: PropTypes.string,
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onTextUpdate: PropTypes.func.isRequired,
};
