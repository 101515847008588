export default {
  de: {
    signIn: 'Anmelden',
    email: 'Email',
    password: 'Password',
    remember: 'Benutzer merken?',
    passwordForgotten: 'Passwort vergessen?',
    alerts: {
      login: {
        401: () => 'Sie haben ein falsches Passwort eingegeben. Bitte prüfen Sie Ihre Eingabe!',
        404: (payload) => `Der Benutzer '${payload.email}' ist nicht registriert. Bitte prüfen Sie Ihre Eingabe!`,
        500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
      },
    },
  },
};
