import React from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import FormatsParlette from './FormatsParlette';
import FormatsEdit from './FormatsEdit';

export default function Formats({
  formats,
  onSetFormats,
}) {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        exact
        path=":formatid"
        element={(
          <FormatsEdit
            formats={formats}
            onSetFormats={onSetFormats}
            onBack={() => navigate('/designer/formats')}
          />
        )}
      />
      <Route
        path="/"
        element={(
          <FormatsParlette
            formats={formats}
            onSetFormats={onSetFormats}
            onClick={(formatid) => navigate(`/designer/formats/${formatid}`)}
          />
        )}
      />
    </Routes>
  );
}

Formats.propTypes = {
  formats: PropTypes.arrayOf(PropTypes.shape({})),
  onSetFormats: PropTypes.func.isRequired,
};

Formats.defaultProps = {
  formats: undefined,
};
