export default {
  de: {
    name: 'Dokument Name',
    status: 'Dokument Status',
    statusDeleted: 'gelöscht',
    statusOpen: 'in bearbeitung',
    statusTesting: 'in korrektur',
    statusTested: 'korrigiert',
    statusDone: 'veröffentlicht',
    statusArchive: 'archiviert',
  },
};
