export default {
  de: {
    customprinter: 'Eigendruck',
    printer: 'Druckanbieter',
    printouts: 'Stückzahl',
    offset: 'Anschnitt in mm',
    cropmarks: 'Anschnittsmarken',
    cmyk: 'CMYK-Konvertierung',
  },
};
