import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import emit from '../../../../util/emit';
import localization from './FormatsParlette.local';

export default function FormatsParlette({
  formats,
  onClick,
  onSetFormats,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [alert, setAlert] = useState();

  const onCreate = () => {
    emit(socket, setAlert, 'formats.post', { name: local.newName }, (newFormat) => onSetFormats([...formats, newFormat]));
  };

  return (
    <Container sx={{ pt: 5 }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Typography variant="h1" sx={{ mb: 2 }}>{local.title}</Typography>
      <Grid container spacing={2}>
        {formats && formats.map((item) => (
          <Grid key={item.id} item xs={6} md={4} lg={3}>
            <Card sx={{ width: 1, minHeight: 100 }} onClick={() => onClick(item.id)}>
              <CardActionArea sx={{ width: 1, minHeight: 100 }}>
                <CardContent>
                  <Chip
                    size="small"
                    sx={{ position: 'absolute', top: 5, right: 5 }}
                    label={item.active ? local.active : local.inactive}
                    color={item.active ? 'success' : 'secondary'}
                  />
                  <Typography variant="h6" sx={{ pt: 2 }}>{item.name}</Typography>
                  <Typography>{`${item.height}${item.unit} x ${item.height}${item.unit}`}</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
        <Grid item xs={6} md={4} lg={3}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={onCreate}
            sx={{ height: 100 }}
          >
            {local.create}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

FormatsParlette.propTypes = {
  formats: PropTypes.arrayOf(PropTypes.shape({})),
  onClick: PropTypes.func.isRequired,
  onSetFormats: PropTypes.func.isRequired,
};

FormatsParlette.defaultProps = {
  formats: undefined,
};
