export default {
  de: {
    title: 'Rahmen',
    description: '',
    border: 'Gesamt',
    color: 'Farbe',
    borderStyle: 'Stil',
    width: 'Konturen in pt',
    borderTop: 'Oben',
    borderRight: 'Rechts',
    borderBottom: 'Unten',
    borderLeft: 'Links',
    radius: 'Abgerundete Ecken',
    borderTopLeftRadius: 'Radius Links-Oben in mm',
    borderTopRightRadius: 'Radius Rechts-Oben in mm',
    borderBottomRightRadius: 'Radius Rechts-Unten in mm',
    borderBottomLeftRadius: 'Radius Links-Unten in mm',
    style: {
      dotted: 'Punkte',
      dashed: 'Strichliert',
      solid: 'Linie',
    },
  },
};
