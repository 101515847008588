import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { LocalizationContext } from '../../AppContext';
import localization from './DesignerTransformations.local';

export default function DesignerTransformations({ values, onTextChange }) {
  const local = localization[useContext(LocalizationContext)];

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{local.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          fullWidth
          type="number"
          variant="outlined"
          color="secondary"
          margin="dense"
          label={local.transformRotate}
          value={values.transformRotate}
          onChange={(e) => onTextChange('transformRotate', e.target.value)}
        />
      </AccordionDetails>
    </Accordion>
  );
}
DesignerTransformations.propTypes = {
  values: PropTypes.shape({
    transformRotate: PropTypes.string,
  }).isRequired,
  onTextChange: PropTypes.func.isRequired,
};
