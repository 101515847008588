export default {
  de: {
    firstname: 'Vorname',
    lastname: 'Nachname',
    email: 'Email',
    admin: 'Administrator',
    publisher: 'Redakteur',
    author: 'Autor',
    proofreader: ' Korrekturleser',
    createdAt: 'erstellt am',
    updatedAt: 'zuletzt bearbeitet am',
    editor: ' zuletzt bearbeitet von',
    destroy: ' Benutzer löschen',
    roles: 'Rollen',
    cityRole: 'Gemeinde auswählen',
    createRole: 'Rolle hinzufügen',
    destroyRole: 'Rollen Löschen',
    manager: 'Beauftragter',
    postNewPassword: 'Neues Passwort senden',
    alerts: {
      'users.postNewPassword': {
        200: () => 'Neues Password wurde gesendet!',
      },
    },
  },
};
