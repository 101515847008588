import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { LocalizationContext, SocketContext } from '../../AppContext';
import Copyright from '../../components/Copyright';
import Snackbar from '../../components/Snackbar';
import emit from '../../util/emit';
import localization from './Login.local';

export default function Login({ onLogin }) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [remember, setRemember] = useState(localStorage.getItem('remember') === 'true');
  const [email, setEmail] = useState(localStorage.getItem('remember') === 'true' ? localStorage.getItem('email') : '');
  const [password, setPassword] = useState('');
  const [alert, setAlert] = useState();
  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem('remember', remember);
    if (remember) {
      localStorage.setItem('email', email);
    }
    emit(socket, setAlert, 'login', { email, password }, (data) => onLogin(data.token, data.user));
  };

  return (
    <Container component="main" maxWidth="sm">
      <Snackbar alert={alert} alertProps={{ email }} local={local.alerts} onClose={() => setAlert()} />
      <Paper sx={{ mt: 16, p: 4 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Avatar sx={{ m: 1, backgroundColor: 'primary.main' }}><LockOpenIcon /></Avatar>
          <Typography component="h1" variant="h5" sx={{ mb: 2 }}>{local.signIn}</Typography>
          <Box component="form" onSubmit={onSubmit}>
            <TextField
              fullWidth
              autoFocus
              required
              color="secondary"
              margin="dense"
              id="email"
              name="email"
              type="email"
              label={local.email}
              value={email}
              error={alert && alert.code === 404}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              required
              fullWidth
              color="secondary"
              margin="dense"
              id="password"
              name="password"
              type="password"
              label={local.password}
              value={password}
              error={alert && alert.code === 401}
              onChange={(e) => setPassword(e.target.value)}

            />
            <FormControlLabel control={<Checkbox checked={remember} onChange={(e) => setRemember(e.target.checked)} />} label={local.remember} />
            <Button fullWidth sx={{ mt: 2 }} color="primary" variant="contained" type="submit">{local.signIn}</Button>
            <Button fullWidth sx={{ mt: 1 }} color="primary" onClick={() => navigate('/password/forgotten')}>{local.passwordForgotten}</Button>
          </Box>
        </Box>
      </Paper>
      <Copyright />
    </Container>
  );
}

Login.propTypes = {
  onLogin: PropTypes.func.isRequired,
};
