import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { LocalizationContext } from '../../../../../AppContext';
import localization from './DocumentEditorDetails.local';

export default function DocumentEditorDetails({
  document,
  onPatch,
}) {
  const local = localization[useContext(LocalizationContext)];
  const [name, setName] = useState('');
  const [typingTimeout, setTypingTimeout] = useState();
  const statusOptions = [
    { value: 0, label: local.statusDeleted },
    { value: 1, label: local.statusOpen },
    { value: 2, label: local.statusTesting },
    { value: 3, label: local.statusTested },
    { value: 4, label: local.statusDone },
    { value: 5, label: local.statusArchive },
  ];

  const onNameChange = (value) => {
    setName(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => onPatch('name', value), 2000));
  };

  useEffect(() => setName(document.name), [document]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <TextField
          fullWidth
          variant="outlined"
          color="secondary"
          margin="dense"
          label={local.name}
          value={name}
          onChange={(e) => onNameChange(e.target.value)}
        />
      </Grid>
      <Grid item xs={4}>
        <Autocomplete
          disableClearable
          options={statusOptions}
          value={statusOptions.find((option) => option.value === document.status)}
          onChange={(event, newValue) => onPatch('status', newValue.value)}
          renderInput={(params) => (
            <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              fullWidth
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.status}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

DocumentEditorDetails.propTypes = {
  document: PropTypes.shape({
    name: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
  }).isRequired,
  onPatch: PropTypes.func.isRequired,
};
